import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const GenieGptTinyIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg {...baseIconProps} xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none" {...props}>
            <path d="M15.9522 5.15203C16.3659 4.81809 16.8478 4.48961 17.2726 4.21708C17.3752 4.73894 17.4813 5.37278 17.5346 5.96115C17.5728 6.38199 17.5799 6.7383 17.5531 6.99758C17.5506 7.02192 17.5479 7.0443 17.5451 7.06481C17.5094 7.06263 17.4665 7.05839 17.4154 7.05094C17.1288 7.00909 16.7535 6.88554 16.3671 6.69234C15.9824 6.50005 15.643 6.26693 15.4131 6.04751C15.3236 5.96206 15.2677 5.8955 15.2332 5.84872C15.2467 5.8303 15.2629 5.80927 15.2821 5.78554C15.4252 5.60944 15.6537 5.39295 15.9522 5.15203ZM17.5056 7.24337C17.5055 7.24331 17.5061 7.24178 17.5075 7.23899C17.5063 7.24204 17.5056 7.24344 17.5056 7.24337Z" fill="#E9EAEC" stroke="white" stroke-width="2" />
            <path d="M2.65572 4.26206C3.11298 4.53661 3.62906 4.851 4.08612 5.1812C4.40506 5.41161 4.64743 5.61665 4.79853 5.78379C4.80666 5.79278 4.81424 5.80134 4.82129 5.80948C4.78454 5.85738 4.72874 5.92195 4.64461 6.00258C4.40606 6.2312 4.0556 6.47477 3.6605 6.677C3.26335 6.88028 2.87773 7.01282 2.58307 7.06023C2.52807 7.06908 2.48234 7.07416 2.44483 7.07686C2.44112 7.05434 2.43742 7.0291 2.43387 7.00093C2.40158 6.7443 2.40044 6.39205 2.42914 5.9769C2.4692 5.39754 2.56146 4.77365 2.65572 4.26206ZM4.91044 5.92974C4.91039 5.92973 4.90986 5.92872 4.90897 5.92673L4.91044 5.92974Z" fill="#E9EAEC" stroke="white" stroke-width="2" />
            <path d="M14.5923 17.2913C12.727 17.4901 10.7715 17.4016 8.84363 17.3144C7.94574 17.2738 7.05385 17.2335 6.17987 17.2225C4.46264 17.2008 2.66579 16.477 1.71036 14.9896C0.725979 13.4569 0.823694 10.1455 1.35388 8.44818C2.0216 6.3238 4.48074 5.44618 6.51645 5.42809C7.17054 5.422 7.82463 5.40894 8.47879 5.39588C9.76826 5.37013 11.058 5.34438 12.3485 5.37199C12.4942 5.37515 12.6407 5.37752 12.7876 5.37988C14.3227 5.40462 15.906 5.43014 17.1908 6.37809C18.4774 7.32809 18.908 8.80827 18.9913 10.3699C19.1107 12.6246 18.9406 15.4908 16.7348 16.6869C16.0997 17.0307 15.3668 17.2081 14.5923 17.2913Z" fill="url(#paint0_linear_26240_8686)" />
            <path d="M14.5923 17.2913C12.727 17.4901 10.7715 17.4016 8.84363 17.3144C7.94574 17.2738 7.05385 17.2335 6.17987 17.2225C4.46264 17.2008 2.66579 16.477 1.71036 14.9896C0.725979 13.4569 0.823694 10.1455 1.35388 8.44818C2.0216 6.3238 4.48074 5.44618 6.51645 5.42809C7.17054 5.422 7.82463 5.40894 8.47879 5.39588C9.76826 5.37013 11.058 5.34438 12.3485 5.37199C12.4942 5.37515 12.6407 5.37752 12.7876 5.37988C14.3227 5.40462 15.906 5.43014 17.1908 6.37809C18.4774 7.32809 18.908 8.80827 18.9913 10.3699C19.1107 12.6246 18.9406 15.4908 16.7348 16.6869C16.0997 17.0307 15.3668 17.2081 14.5923 17.2913Z" fill="white" />
            <path d="M14.5923 17.2913C12.727 17.4901 10.7715 17.4016 8.84363 17.3144C7.94574 17.2738 7.05385 17.2335 6.17987 17.2225C4.46264 17.2008 2.66579 16.477 1.71036 14.9896C0.725979 13.4569 0.823694 10.1455 1.35388 8.44818C2.0216 6.3238 4.48074 5.44618 6.51645 5.42809C7.17054 5.422 7.82463 5.40894 8.47879 5.39588C9.76826 5.37013 11.058 5.34438 12.3485 5.37199C12.4942 5.37515 12.6407 5.37752 12.7876 5.37988C14.3227 5.40462 15.906 5.43014 17.1908 6.37809C18.4774 7.32809 18.908 8.80827 18.9913 10.3699C19.1107 12.6246 18.9406 15.4908 16.7348 16.6869C16.0997 17.0307 15.3668 17.2081 14.5923 17.2913Z" stroke="#162802" />
            <path d="M9.81435 5.24923C9.81435 5.24923 8.98418 2.92168 9.25575 2.73573C9.52733 2.54978 10.4257 2.45104 10.4257 2.45104C10.4257 2.45104 10.7925 0.871119 10.9457 0.949345C11.1002 1.02757 12.0101 3.41925 11.9033 3.57057C11.7965 3.72061 10.3948 3.90271 10.3948 3.90271C10.3948 3.90271 9.96623 5.35567 9.81435 5.24923Z" fill="url(#paint1_linear_26240_8686)" />
            <path d="M2.83951 12.5088C2.70379 11.7524 2.74722 10.9255 2.84674 10.2469C3.10912 8.45549 4.43369 7.53082 6.17264 7.45663C8.90683 7.34082 11.67 7.23225 14.3788 7.72082C16.8452 8.16596 17.0895 10.2958 16.6425 12.4419C16.5652 12.815 16.4407 13.1752 16.2664 13.4986C16.0889 13.8275 16.8607 15.487 16.8607 15.487C16.8607 15.487 15.0006 14.7136 14.6394 14.7942C14.5741 14.8088 14.5071 14.8209 14.4385 14.8304C11.5759 15.2249 8.4653 14.9698 5.58636 14.7942C4.08446 14.7038 3.11455 14.0469 2.83951 12.5088Z" fill="url(#paint2_linear_26240_8686)" />
            <path d="M13.0732 12.4941C13.6514 12.4941 14.1201 11.8226 14.1201 10.9941C14.1201 10.1657 13.6514 9.49414 13.0732 9.49414C12.4951 9.49414 12.0264 10.1657 12.0264 10.9941C12.0264 11.8226 12.4951 12.4941 13.0732 12.4941Z" fill="#D0FC81" />
            <path d="M6.95413 12.4941C7.53229 12.4941 8.00098 11.8226 8.00098 10.9941C8.00098 10.1657 7.53229 9.49414 6.95413 9.49414C6.37598 9.49414 5.90729 10.1657 5.90729 10.9941C5.90729 11.8226 6.37598 12.4941 6.95413 12.4941Z" fill="#D0FC81" />
            <defs>
                <linearGradient id="paint0_linear_26240_8686" x1="18.8685" y1="12.8561" x2="-0.464814" y2="11.2218" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#CAE3DE" />
                    <stop offset="0.2901" stopColor="#DEEDEA" />
                    <stop offset="0.6878" stopColor="white" />
                    <stop offset="0.7666" stopColor="#F9FCFB" />
                    <stop offset="0.8765" stopColor="#E6F4F2" />
                    <stop offset="1" stopColor="#CAE8E2" />
                </linearGradient>
                <linearGradient id="paint1_linear_26240_8686" x1="9.20117" y1="3.1026" x2="11.6929" y2="3.1026" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#AAF7C1" />
                    <stop offset="1" stopColor="#D0FC81" />
                </linearGradient>
                <linearGradient id="paint2_linear_26240_8686" x1="11.0594" y1="16.026" x2="7.98153" y2="4.42104" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#162802" />
                    <stop offset="1" stopColor="#484946" />
                </linearGradient>
            </defs>
        </svg>)
};