import { FC, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@root/shared/ui/button/button';
import { Text } from '@root/shared/ui/typography';
import { useFormikContext } from 'formik';
import { TextField } from '@root/shared/form';
import { EnterCodeWithBackupForm, TwoFAAuthType } from '../../types/two-fa';
import { useResendCode } from '../../hooks/use-resend-code';

const SUPPORT_EMAIL = 'support@sagemaster.io';

type Props = {
    email?: string;
    isSignIn?: boolean;
    hideEmail?: boolean;
    hasAnotherMethod?: boolean;
    type: TwoFAAuthType;
    formName: string;
}

export const EnterCodeContent: FC<Props> = ({ formName, email, hideEmail = false, type, isSignIn = false, hasAnotherMethod = false }) => {
    const { t } = useTranslation('two-fa');
    const { restTime, resendLoading, ableToResend, sendEmailCode, resendCode } = useResendCode(formName, '', isSignIn);
    const { values, setFieldValue } = useFormikContext<EnterCodeWithBackupForm>();

    const hiddenEmail = useMemo(() => {
        if (!email) {
            return '';
        }

        const [name, domain] = email.split('@');
        const hiddenName = name.slice(0, 1) + '*'.repeat(name.length - 1);
        const [secondLevel, topLevel] = domain.split('.');
        const hiddenDomain = '*'.repeat(secondLevel.length) + topLevel;

        return `${hiddenName}@${hiddenDomain}`;
    }, [email]);

    const handleSwitchMethodClick = useCallback(() => {
        const secondMethodType = values.type === TwoFAAuthType.Authenticator ? TwoFAAuthType.Email : TwoFAAuthType.Authenticator;

        if (secondMethodType === TwoFAAuthType.Email) {
            sendEmailCode();
        }

        setFieldValue('type', secondMethodType);
    }, [values.type, setFieldValue, sendEmailCode]);

    return <div className='w-full'>
        {type === TwoFAAuthType.Authenticator ? <Text size='base'>
            {t('enterCodeContent.appText')}
        </Text> : email ? <Text size='base'>
            {t('enterCodeContent.emailText', { email: hideEmail ? hiddenEmail : email })}
        </Text> : null}
        <div className="my-4">
            <TextField
                name='code'
                placeholder={t('enterCodeContent.enterCode')}
                suffix={
                    type === TwoFAAuthType.Email ? ableToResend ? <Button loading={resendLoading} className='!border-none !py-0 !px-0' outlined onClick={resendCode}>
                        <Text className='text-primary-400 font-medium'>
                            {t('enterCodeContent.resendCode')}
                        </Text>
                    </Button> : <Text className='font-bold'>
                        {restTime}
                    </Text> : null
                }
                suffixClassName='!z-10'
            />
        </div>
        <div className='border-b border-gray-700 pb-1 mb-1'>
            <Text size='base'>
                {type === TwoFAAuthType.Email ? <Trans
                    i18nKey='two-fa:useBackupEmailText'
                    components={{
                        span: <span
                            className='text-primary-400 font-medium cursor-pointer'
                            onClick={() => setFieldValue('useBackupCode', true)}
                        />
                    }}
                /> : <Trans
                    i18nKey='two-fa:useBackupText'
                    components={{
                        span: <span
                            className='text-primary-400 font-medium cursor-pointer'
                            onClick={() => setFieldValue('useBackupCode', true)}
                        />
                    }}
                />}
            </Text>
            {
                hasAnotherMethod && <Text size='base' className='text-primary-400 font-medium cursor-pointer' onClick={handleSwitchMethodClick}>
                    {t('enterCodeContent.useAnotherMethod')}
                </Text>
            }
        </div>
        <Text size='sm' className='text-grayscale-500'>
            <Trans
                i18nKey='two-fa:verificationIssues'
                components={{ a: <a className='underline font-medium' href={`mailto:${SUPPORT_EMAIL}`} /> }}
                values={{ email: SUPPORT_EMAIL }}
            />
        </Text>
    </div>;
}

