import { AxiosError } from 'axios';
import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { IAuthUser } from '../types';
import { AuthUserMapper } from '../mappers';

export type VerifyEmailChangeServiceResponse = IHttpResponse<200, IAuthUser> | IHttpResponse<400, string>;

type VerifyEmailChangeDto = {
  token: string;
};

export const verifyEmailChange = async (data: VerifyEmailChangeDto): Promise<VerifyEmailChangeServiceResponse> => {
  try {
    const response = await fetcher.post('/auth/profile/user-email-change-requests/verify', data);

    return {
      status: 200,
      payload: AuthUserMapper.toDomain(response.data),
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
