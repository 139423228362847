import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const WriteMessageIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.3424 0.171662C17.1137 -0.0572205 16.7426 -0.0572205 16.5139 0.171662L11.998 4.68751H1.75782C0.788575 4.68751 0 5.47608 0 6.44532V14.6483C0 15.6177 0.788575 16.4061 1.75782 16.4061H2.38282V19.4139C2.38282 19.6521 2.52701 19.8667 2.7475 19.9565C2.9712 20.0478 3.22342 19.9915 3.38761 19.8238L6.73081 16.4061H13.5547C14.5239 16.4061 15.3125 15.6177 15.3125 14.6483V8.00203L19.8284 3.48618C20.0571 3.2573 20.0571 2.88636 19.8284 2.65747L17.3424 0.171662ZM11.9562 9.70094L10.2989 8.04368L15.2709 3.0719L16.9281 4.72916L11.9562 9.70094ZM8.78328 11.2166L10.9232 10.3252L9.6747 9.0767L8.78328 11.2166ZM14.1406 14.6483C14.1406 14.9715 13.8777 15.2342 13.5547 15.2342H6.48439C6.32676 15.2342 6.1757 15.2979 6.06553 15.4105L3.55469 17.9772V15.8202C3.55469 15.4967 3.29239 15.2342 2.96875 15.2342H1.75782C1.43479 15.2342 1.17188 14.9715 1.17188 14.6483V6.44532C1.17188 6.12214 1.43479 5.85938 1.75782 5.85938H10.826L9.05595 7.62941C9.00712 7.67823 8.95875 7.74553 8.92656 7.82518L7.3047 11.7188H4.14063C3.81699 11.7188 3.55469 11.9811 3.55469 12.3047C3.55469 12.6282 3.81699 12.8902 4.14063 12.8902H7.69502H7.69548C7.77086 12.8902 7.85173 12.8748 7.92573 12.843L12.1747 11.0733C12.2455 11.0457 12.3169 10.9976 12.3706 10.9439L14.1406 9.1739V14.6483ZM16.0994 2.2432L17.7567 3.90046L18.5854 3.0719L16.9281 1.41464L16.0994 2.2432Z'
      />
    </svg>
  );
};
