import { setCookie } from '@root/shared/utils/cookies';
import jwtDecode from 'jwt-decode';

export const updateTokens = (accessToken: string, refreshToken?: string) => {
  const { exp: accessTokenExp } = jwtDecode<{ exp: number }>(accessToken);

  setCookie('accessToken', accessToken, accessTokenExp * 1000);
  if (refreshToken) {
    setCookie('refreshToken', refreshToken, Date.now() + 24 * 60 * 60 * 1000 * 200);
  }
};
