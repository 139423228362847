import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { TwoFAAuthType } from '../../types/two-fa';
import { TwoFaErrorResponse } from '../../types/two-fa-field-error';

export type DisableMethodResponse =
  | IHttpResponse<200, { success: boolean; errorCode: string }>
  | IHttpResponse<400, string>
  | IHttpResponse<418, TwoFaErrorResponse>;

export const deleteMethod = async (
  methodId,
  data: { type: TwoFAAuthType; code: string },
): Promise<DisableMethodResponse> => {
  try {
    const response = await fetcher.delete(`/auth/2fa/methods/${methodId}`, {
      data: {
        twoFactorCode: data,
      },
    });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      if (typeof error.response.data.message === 'object') {
        return {
          status: 418,
          payload: error.response.data.message,
        };
      }

      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
