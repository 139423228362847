import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { authSelector, authSlice } from '../store';
import { notify } from '@root/shared/utils/notification';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '@root/shared/hooks/useLocalStorage';
import { GHOST_LOGIN_SESSION_START, GHOST_LOGIN_TOKEN, GHOST_SESSION_PAGE_LEAVE_TIME } from '../../shared/constants/local-storage-keys';
import { deleteCookie } from '@root/shared/utils/cookies';


export const useStartGhostLogin = () => {
  const savedGhostToken = useSelector(authSelector.ghostToken);
  const [queryParams] = useSearchParams();
  const ghostToken = queryParams?.get('ghostToken');
  const { t } = useTranslation('common');
  const [, setGhostLoginToken] = useLocalStorage<string | null>(GHOST_LOGIN_TOKEN, null);
  const [, setGhostLoginSessionStart] = useLocalStorage<string | null>(GHOST_LOGIN_SESSION_START, null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (ghostToken) {
      deleteCookie('twoFaAccessToken');
      dispatch(authSlice.actions.startGhostLogin(ghostToken));
      localStorage.removeItem(GHOST_SESSION_PAGE_LEAVE_TIME);
      setGhostLoginToken(ghostToken);
      setGhostLoginSessionStart(new Date().toISOString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ghostToken, dispatch]);

  useEffect(() => {
    if (ghostToken && savedGhostToken) {
      console.log(ghostToken, savedGhostToken);
      notify({
        type: 'success',
        title: t('loggedInAsGhost'),
      });
      dispatch(authSlice.actions.finalizeGhostLogin(ghostToken));
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ghostToken, savedGhostToken, dispatch]);
};
