import infoImage from '@root/assets/images/cases/info.webp';
import { useTranslation } from 'react-i18next';
import { Button } from '@root/shared/ui/button';
import { Image } from '@root/shared/ui/image';
import { Modal, ModalProps } from '@root/shared/ui/modal';
import { Text } from '@root/shared/ui/typography';
import { FC } from 'react';
import { Trans } from 'react-i18next';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';

type Props = ModalProps & {
  loading: boolean;
};

export const SubscriptionConfirmationModal: FC<Props> = ({ isOpen, onCancel, onOk, loading }) => {
  const { t } = useTranslation('subscriptions');

  const { hideActions } = useGhostLogin();

  return (
    <Modal onCancel={onCancel} footer={null} isOpen={isOpen}>
      <div className='flex flex-col items-center'>
        <Image src={infoImage} height={80} width={80} placeholder='blur' alt='Error' objectFit='contain' unoptimized={true} />
        <Text className='mt-8 mb-5 text-center'>
          <Trans i18nKey='subscriptions:confirmationModal.text' components={{ b: <span className='font-bold' /> }} />
        </Text>
        <div className='w-full flex items-center justify-between'>
          <Button onClick={onCancel} className='mt-5' outlined>
            {t('confirmationModal.cancel')}
          </Button>
          <Button onClick={onOk} className='mt-5' loading={loading} disabled={hideActions}>
            {t('confirmationModal.confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
