import styles from './home.module.scss';

import { IconButton } from './button';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import { ReactComponent as DragIcon } from '../icons/drag.svg';

import { useChatStore } from '../store';

import { Path } from '../constant';

import { useNavigate } from 'react-router-dom';

import { showConfirm } from './ui-lib';
import { useDragSideBar } from '@root/modules/ai-chat/hooks/use-drag-sidebar';
import { useHotKey } from '@root/modules/ai-chat/hooks/use-hot-key';
import { GenieGptIcon } from '@root/shared/icons/genie-gpt-icon';
import { useLogEvent } from '@root/shared/utils/hooks/use-log-event';
import { useTranslation } from 'react-i18next';
import { ChatList } from './chat-list';
import { Button } from '@root/shared/ui/button';
import { AddIcon } from '@root/shared/icons/add-icon';

export function SideBar(props: { className?: string }) {
  const chatStore = useChatStore();
  const { onDragStart, shouldNarrow } = useDragSideBar();
  const navigate = useNavigate();
  useHotKey();

  const { logEvent } = useLogEvent();

  const { t, i18n } = useTranslation('master-chat');

  return (
    <div className={`${styles.sidebar} ${props.className} ${shouldNarrow && styles['narrow-sidebar']}`}>
      <div className={styles['sidebar-header']}>
        <div className={styles['sidebar-title']}>{t('Title')} 2.0</div>
        <div className={styles['sidebar-sub-title']}>{t('Description')}</div>
        <div className={styles['sidebar-logo'] + ' no-dark'}>
          <GenieGptIcon width={44} height={44} />
        </div>
      </div>

      <div
        className={styles['sidebar-body']}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            navigate(Path.Home);
          }
        }}
      >
        <ChatList narrow={shouldNarrow} />
      </div>

      <div className={styles['sidebar-tail']}>
        <Button className='flex gap-x-2 items-center !border-none !px-0' outlined onClick={() => {
          const session = chatStore.newSession(i18n.language);
          if (session.id) {
            logEvent('new_chat', { chat_id: session.id });
          }
        }}>
          <AddIcon />
          {shouldNarrow ? undefined : t('Home.NewChat')}
        </Button>
        <div className={styles['sidebar-actions']}>
          <div className={styles['sidebar-action'] + ' ' + styles.mobile}>
            <IconButton
              icon={<CloseIcon />}
              onClick={async () => {
                if (await showConfirm(t('Home.DeleteChat'), t)) {
                  chatStore.deleteSession(chatStore.currentSessionIndex, t, i18n.language);
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles['sidebar-drag']} onPointerDown={onDragStart}>
        <DragIcon />
      </div>
    </div>
  );
}
