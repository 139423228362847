import { AuthLayout } from '@root/shared-files/modules/auth/components';
import { ResetPassword } from '@root/shared-files/modules/auth/containers';

const ResetPasswordPage = () => {
  return (
    <AuthLayout>
      <ResetPassword />
    </AuthLayout>
  );
};

export default ResetPasswordPage;
