import { SagaReturnType, call, fork, put, take, takeLatest } from 'redux-saga/effects';

import { AuthActions, authSlice } from '@root/shared-files/modules/auth/store/auth.slice';
import { getCookie } from '@root/shared/utils/cookies';

import { clearLocalStorage, getTokens, removeTokens, updateTokens } from '../helpers';
import { getProfileService, refreshTokenService } from '../services';
import { queryClient } from '@root/infra/query';
import { getQuizState } from '../services/get-quiz-state.service';
import {
  SHOULD_REFETCH_PROVIDERS,
  SHOULD_REFRESH_TOKEN,
} from '@root/shared-files/modules/shared/constants/local-storage-keys';

const {
  signedIn,
  signedOut,
  signedInWithGoogle,
  tokensRefreshed,
  fetchProfilePending,
  fetchProfileFulfilled,
  fetchProfileRejected,
  tokensMissed,
  refreshSession,
  startGhostLogin,
  finalizeGhostLogin,
  refreshSessionAfterTradeIdeaSubscription,
} = authSlice.actions;

function handleTokensRefreshed(action: AuthActions.TokensRefreshed) {
  const accessToken = action.payload?.tokens?.accessToken;
  const refreshToken = action.payload?.tokens?.refreshToken;
  const isUpdatedAlready = action.payload?.tokensUpdatedAlready;

  if (!isUpdatedAlready) {
    updateTokens(accessToken, refreshToken);
  }
}

function* handleAuthFulfilled(action: AuthActions.SignedInWithGoogle | AuthActions.SignedIn) {
  const accessToken = action.payload.tokens.accessToken;
  const refreshToken = action.payload.tokens.refreshToken;

  updateTokens(accessToken, refreshToken);
  yield put(fetchProfileFulfilled(action.payload));
}

function* checkQuizState() {
  const result: SagaReturnType<typeof getQuizState> = yield call(getQuizState);

  if (result.status === 200) {
    yield put(authSlice.actions.setQuizState(result.payload));
  }
}

function* handleFetchProfile() {
  const { accessToken, refreshToken } = getTokens();

  if (!!accessToken || !!refreshToken) {
    if (localStorage.getItem(SHOULD_REFRESH_TOKEN)) {
      yield take([tokensRefreshed]);
    }

    const result: SagaReturnType<typeof getProfileService> = yield call(getProfileService);

    if (result.status === 200) {
      yield put(fetchProfileFulfilled(result.payload));
    } else {
      yield put(fetchProfileRejected(result.payload));
    }
  } else {
    console.log('tokensMissed');
    yield put(tokensMissed());
  }
}

function refetchTIProviders() {
  queryClient?.refetchQueries(['marketplace-subscribed-providers'], { exact: false });
}

function* handleRefreshSession() {
  const oldRefreshToken = getCookie('refreshToken');
  const response = yield call(refreshTokenService, oldRefreshToken as string);
  if (response.status === 200) {
    yield put(authSlice.actions.tokensRefreshed(response.payload));

    if (localStorage?.getItem(SHOULD_REFETCH_PROVIDERS)) {
      refetchTIProviders();
      localStorage?.removeItem(SHOULD_REFETCH_PROVIDERS);
    }
  }
}

function* handelRefreshSessionAfterTISubscription() {
  const oldRefreshToken = getCookie('refreshToken');
  const response = yield call(refreshTokenService, oldRefreshToken as string);
  if (response.status === 200) {
    yield put(authSlice.actions.tokensRefreshed(response.payload));
    yield put(authSlice.actions.refetchTIsAfterRefresh());
  }
}


function* handleGhostLogin(action: AuthActions.StartGhostLogin) {
  removeTokens();
  clearLocalStorage();
  queryClient.clear();

  const accessToken = action.payload;

  if (accessToken) {
    updateTokens(accessToken as string);
    const response = yield call(getProfileService);
    if (response.status === 200) {
      yield put(fetchProfileFulfilled(response.payload));
      yield put(finalizeGhostLogin(accessToken));
    } else {
      yield put(fetchProfileRejected(response.payload));
    }
  }
}

function handleLogout() {
  removeTokens();
  clearLocalStorage();
}

export function* authSaga() {
  yield fork(handleFetchProfile);
  yield takeLatest([signedIn, signedInWithGoogle], handleAuthFulfilled);
  yield takeLatest([signedOut], handleLogout);
  yield takeLatest([startGhostLogin], handleGhostLogin);
  yield takeLatest([tokensRefreshed], handleTokensRefreshed);
  yield takeLatest([fetchProfilePending], handleFetchProfile);
  yield takeLatest([refreshSession], handleRefreshSession);
  yield takeLatest([fetchProfileFulfilled], checkQuizState);
  yield takeLatest([refreshSessionAfterTradeIdeaSubscription], handelRefreshSessionAfterTISubscription);
}
