import { useTranslation } from 'react-i18next';
import { Title } from '@root/shared/ui/typography';
import { FC } from 'react';
import { Language } from '../components/language';
import { TwoFactorAuthSettings } from '@root/shared-files/modules/auth/components/two-factor-auth-settings';
import { ProfileSettings } from '@root/shared-files/modules/auth/components/profile-settings';

export const Settings: FC = () => {
  const { t } = useTranslation('settings');

  return (
    <div className='p-4 sm:p-5'>
      <div className='flex justify-between items-center gap-x-4 mb-4'>
        <Title level={5} className='font-bold'>
          {t('title')}
        </Title>
      </div>
      <div className="mb-4">
        <ProfileSettings />
      </div>
      <div className='grid md:grid-cols-2 gap-4 mb-4'>
        <Language />
      </div>
      <div className='mb-4'>
        <TwoFactorAuthSettings />
      </div>
    </div>
  );
};
