import styled from 'styled-components';

import clsx from 'clsx';
import { FC, useCallback, useState } from 'react';
import { ArrowContainer, Popover, PopoverPosition } from 'react-tiny-popover';

const Container = styled.div`
  display: block;
  box-shadow: 0 10px 24px 0 rgba(12, 12, 12, 0.6);
  background-color: ${({ theme }) => theme.gray[800]};
  border-radius: 4px;
  padding: 4px 8px;
  position: relative;
  border: ${({ theme }) => `1px solid ${theme.gray[800]}`};
  min-width: 100px;
  max-width: 280px;
`;

type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
  positions?: PopoverPosition[];
  wrapperClassName?: string;
  keepOnMouseLeave?: boolean;
  arrowSize?: number;
  padding?: number;
  inline?: boolean;
};

export const InfoTooltip: FC<Props> = ({ children, content, positions, wrapperClassName = '', keepOnMouseLeave = false, arrowSize = 10, padding = 15, inline = false }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen((prev) => !prev);
  }, []);

  if (!content) {
    return <span>{children}</span>;
  }

  return (
    <Popover
      positions={positions || ['bottom']}
      align='center'
      padding={padding}
      onClickOutside={toggleDropdown}
      isOpen={isDropdownOpen}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position as PopoverPosition}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={'#202020'}
          arrowSize={arrowSize}
          arrowStyle={{ zIndex: 101 }}
        >
          <Container className='text-sm'>{content}</Container>
        </ArrowContainer>
      )}
      containerStyle={{ zIndex: '100' }}
    >
      {inline ? (
        <span
          className={clsx('cursor-pointer', wrapperClassName)}
          onMouseEnter={() => setIsDropdownOpen(true)}
          onMouseLeave={() => (keepOnMouseLeave ? null : setIsDropdownOpen(false))}
        >
          {children}
        </span>
      ) : (
        <div
          className={clsx('cursor-pointer', wrapperClassName)}
          onMouseEnter={() => setIsDropdownOpen(true)}
          onMouseLeave={() => (keepOnMouseLeave ? null : setIsDropdownOpen(false))}
        >
          {children}
        </div>
      )}
    </Popover>
  );
};
