import { FC, ReactNode } from 'react';
import { Toggler } from '../../toggler/toggler';
import { FieldHint } from '../field/field-hint';
import { CheckboxProps, CheckboxStyled } from './checkbox-field.styled';

export interface CheckboxFieldProps extends CheckboxProps {
  label: ReactNode;
  error?: string;
  hideError?: boolean;
  toggleView?: boolean;
  id: string;
}

const { Container, Input, Icon, Label } = CheckboxStyled;

export const CheckboxField: FC<CheckboxFieldProps> = ({
  label,
  error,
  id,
  hideError,
  toggleView = false,
  checked,
  disabled,
  onChange,
  type = 'checkbox',
  className,
  ...inputProps
}) => {
  return (
    <div className={className}>
      <Container htmlFor={id}>
        <Input disabled={disabled} checked={checked} onChange={onChange} {...inputProps} id={id} />
        {toggleView ? <Toggler checked={checked} disabled={disabled} onChange={onChange} /> : <Icon type={type} checked={checked} />}

        <Label>{label}</Label>
      </Container>
      {!hideError && <FieldHint variant='danger'>{error}</FieldHint>}
    </div>
  );
};
