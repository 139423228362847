import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { AxiosError } from 'axios';
import { ForgotPasswordDto } from '../dtos';
import { ForgotPasswordDtoMapper } from '../mappers/forgot-password-dto.mapper';

export type ForgotPasswordServiceResponse = IHttpResponse<200, string> | IHttpResponse<400, string>;

export const forgotPasswordService = async (payload: ForgotPasswordDto): Promise<ForgotPasswordServiceResponse> => {
  try {
    const response = await fetcher.post('/auth/send-password-reset-email', ForgotPasswordDtoMapper.toPersistence(payload));

    return {
      status: 200,
      payload: response.data.message,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
