import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { ICountry } from '../types';

export type GetCountriesResponses = IHttpResponse<200, ICountry[]> | IHttpResponse<400, string>;

export const getCountriesService = async (): Promise<GetCountriesResponses> => {
  try {
    const response = await fetcher('/auth/countries');

    if (response.status === 200) {
      return {
        status: 200,
        payload: response.data.map((item) => ({ code: item, name: item })),
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  } catch (e) {
    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
