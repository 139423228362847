import { AuthLayout } from '@root/shared-files/modules/auth/components';
import { ForgotPassword } from '@root/shared-files/modules/auth/containers';

const ForgotPasswordPage = () => {
  return (
    <AuthLayout>
      <ForgotPassword />
    </AuthLayout>
  );
};

export default ForgotPasswordPage;
