import ForgotPasswordPage from '@root/shared-files/modules/auth/pages/forgot-password';
import ResetPasswordPage from '@root/shared-files/modules/auth/pages/reset-password';
import SignInPage from '@root/shared-files/modules/auth/pages/sign-in';
import SignUpPage from '@root/shared-files/modules/auth/pages/sign-up';
import VerifyEmailPage from '@root/shared-files/modules/auth/pages/verify-email';
import ProtectedAuthRoute from '@root/infra/router/auth-pages-protector';
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { supportedLanguages } from '@root/i18n';
import { ErrorPage } from '@root/infra/layout/containers/error';

export const AuthRouter = () => {
  const { pathname } = useLocation();
  const lang = pathname.split('/')[1];

  if (supportedLanguages.includes(lang as string)) {
    const routeWithoutLang = pathname.replace(`/${lang}`, '');
    return <Navigate to={routeWithoutLang} replace />;
  }

  return (
    <Routes>
      <Route
        path='sign-in'
        element={
          <ProtectedAuthRoute>
            <SignInPage />
          </ProtectedAuthRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path='sign-up'
        element={
          <ProtectedAuthRoute>
            <SignUpPage />
          </ProtectedAuthRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path='reset-password'
        element={
          <ProtectedAuthRoute>
            <ResetPasswordPage />
          </ProtectedAuthRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route
        path='forgot-password'
        element={
          <ProtectedAuthRoute>
            <ForgotPasswordPage />
          </ProtectedAuthRoute>
        }
        errorElement={<ErrorPage />}
      />
      <Route path='verify-email' element={<VerifyEmailPage />} errorElement={<ErrorPage />} />
      <Route path='*' element={<Navigate to='/sign-in' replace />} />
    </Routes>
  );
};
