import { queryClient } from '@root/infra/query';
import { settingsSelector } from '@root/modules/app-settings/store/settings.selector';
import { settingsSlice } from '@root/modules/app-settings/store/settings.slice';
import { authSlice } from '@root/shared-files/modules/auth/store';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useDropdown = () => {
  const isBalanceVisible = useSelector(settingsSelector.isBalanceVisible);
  const dispatch = useDispatch();

  const toggleVisibility = useCallback(() => {
    dispatch(settingsSlice.actions.setBalanceVisibility(!isBalanceVisible));
  }, [dispatch, isBalanceVisible]);

  const logout = useCallback(() => {
    dispatch(authSlice.actions.signedOut());
    queryClient.clear();
  }, [dispatch]);

  const state = { isBalanceVisible };
  const handlers = { hideBalance: toggleVisibility, logout };

  return [state, handlers] as [typeof state, typeof handlers];
};
