import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { AxiosError } from 'axios';
import { AuthModuleConfig } from '../module.config';

export type SendVerificationEmailServiceResponse = IHttpResponse<200> | IHttpResponse<400, string>;

export const sendVerificationEmailService = async (): Promise<SendVerificationEmailServiceResponse> => {
  try {
    await fetcher.post('/auth/send-verification-email', { redirectPath: '/verify-email', sourceApp: AuthModuleConfig.sourceApp });

    return {
      status: 200,
      payload: null,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
