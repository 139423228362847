import { Text } from '@root/shared/ui/typography';
import clsx from 'clsx';
import styled from 'styled-components';
import { ButtonHTMLAttributes, DetailedHTMLProps, FC, forwardRef, HTMLAttributes, lazy, PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { userSettingsSelector } from '@root/modules/user-settings/store/user-settings.selector';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { SvgSpriteIcon } from '@root/shared/ui/svg-sprite-icon';
import { Popover } from 'react-tiny-popover';

export interface SidebarItemProps extends Omit<DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'title'> {
  title: ReactNode | string;
  icon: ReactNode;
  nestedClassName?: string;
  isActive?: boolean;
  disabled?: boolean;
  menuOpened: boolean;
}

const StyledNav = styled.nav<{ expanded: boolean }>`
  width: ${({ expanded }) => (expanded ? '200px' : '64px')};
  overflow-x: visible;

  scrollbar-color: ${({ theme }) => theme.gray[800]} ${({ theme }) => theme.gray[800]};

  &::-webkit-scrollbar, 
  &::-webkit-scrollbar-track, 
  &::-webkit-scrollbar-thumb, 
  &::-webkit-scrollbar-corner, 
  &::-webkit-scrollbar-track-piece,  
  &::-webkit-scrollbar-thumb  {
    background-color: ${({ theme }) => theme.gray[800]};
  }

  padding-right: 6px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &:hover {
    scrollbar-color: #1f2329 #2a2e34;

    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background-color: #1f2329;
    }

    &::-webkit-scrollbar-corner {
      background-color: #1f2329;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: #1f2329;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #2a2e34;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #2a2e34;
    }
  }
`;

const Item = forwardRef<HTMLAnchorElement, SidebarItemProps>(function Item({ title, menuOpened, icon, isActive, disabled, className, nestedClassName, ...props }, ref) {
  const Wrapper = menuOpened ? ({ children }) => children : PopoveredItem;

  return (
    <Wrapper title={title as string}>
      <span
        className={clsx(
          'flex flex-col items-start justify-center text-center w-full p-2 h-12 relative transition text-gray-400',
          {
            'text-primary-300 bg-primary-500 bg-opacity-5': isActive && !disabled,
          },
          {
            'hover:text-primary-300 hover:bg-primary-500 hover:bg-opacity-5': /*props.menuOpened &&*/ !disabled,
          },
          {
            '!opacity-40 !cursor-default !hover:bg-transparent !text-gray-600': disabled,
          },
          className,
        )}
        {...props}
        ref={ref}
      >
        <div className={clsx('max-w-full flex items-center justify-start gap-x-3 text-left', nestedClassName)}>
          <div className='text-xl mb-1'>{icon}</div>
          {menuOpened ? <Text className='overflow-hidden text-ellipsis'>{title}</Text> : null}
        </div>
        <div
          className={clsx('absolute top-0 right-0 bottom-0 w-0.5 bg-primary-300 rounded-l transition-opacity', {
            'opacity-0': !isActive,
            'opacity-100': !disabled && isActive,
          })}
        />
      </span>
    </Wrapper>
  );
});

const PopoveredItem = (props: { title: string; children: React.ReactNode }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const toggleDropdown = useCallback(() => {
    setIsDropdownOpen((prev) => !prev);
  }, []);

  return (
    <Popover
      positions={['right', 'bottom']}
      align='center'
      padding={0}
      onClickOutside={toggleDropdown}
      isOpen={isDropdownOpen}
      content={() => <div className='px-1 text-xs leading-4 bg-grayscale-600 bg-opacity-95 text-grayscale-100 rounded z-1 !text-[12px]'>{props.title || ''}</div>}
      containerStyle={{ zIndex: '1000', left: '-10px' }}
    >
      <span className={clsx('cursor-pointer')} onMouseEnter={() => setIsDropdownOpen(true)} onMouseLeave={() => setIsDropdownOpen(false)}>
        {props.children}
      </span>
    </Popover>
  );
};

const SecondaryItem = forwardRef<HTMLAnchorElement, SidebarItemProps>(function Item({ title, icon, isActive, disabled, className, ...props }, ref) {
  return (
    <span
      className={clsx(
        'flex flex-col items-center justify-center text-center p-2 w-full h-15 relative transition rounded',
        'hover:text-primary-300 hover:bg-primary-500 hover:bg-opacity-5',
        {
          'text-primary-300 bg-primary-500 bg-opacity-5': isActive && !disabled,
        },
        {
          '!opacity-40 !cursor-default !hover:bg-transparent !text-gray-600': disabled,
        },
        className,
      )}
      {...props}
      ref={ref}
    >
      <div className={clsx('w-full flex items-center gap-x-3', { 'justify-center': !title, 'justify-start': !!title })}>
        <div className='text-xl mb-1'>{icon}</div>
        {title ? <Text>{title}</Text> : null}
      </div>
    </span>
  );
});

const StyledNavContent = styled.div`
  margin-right: -6px;
`;

const Wrapper: FC<PropsWithChildren<{ className?: string }>> = ({ children, className = '' }) => {
  const menuOpened = useSelector(userSettingsSelector.menuOpened);
  const { isGhostLogin } = useGhostLogin();

  return (
    <StyledNav expanded={menuOpened} className={clsx('fixed left-0 top-0 bottom-0 bg-gray-800 pb-4 pt-[90px] overflow-y-auto z-[15]', { 'left-[3px] bottom-[4px] !top-[60px] pt-40': isGhostLogin }, className)}>
      <StyledNavContent>
        {children}
      </StyledNavContent>
    </StyledNav>
  );
};

const Group: FC<HTMLAttributes<HTMLDivElement>> = ({ children, className }) => {
  return <div className={`flex ${className}`}>{children}</div>;
};

const Help: FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => {
  return <div className={clsx('py-1 inline-block bg-gray-900 rounded', className)}>{children}</div>;
};

const HelpItem = forwardRef<HTMLAnchorElement, PropsWithChildren<{ className?: string }>>(function Item({ children, className }, ref) {
  return (
    <span className={clsx('block px-2 py-3 text-lg hover:text-primary-300 transition-colors', className)} ref={ref}>
      {children}
    </span>
  );
});

const PopoverItem: FC<PropsWithChildren<{ icon: string } & ButtonHTMLAttributes<HTMLButtonElement>>> = ({ icon, children, ...rest }) => (
  <button className='text-left text-[14px] px-4 py-2 flex items-center hover:text-primary-400 disabled:text-gray-600' {...rest}>
    <span className='mr-2'>
      <SvgSpriteIcon id={icon} />
    </span>
    <span>{children}</span>
  </button>
);

export const SidebarElements = { Wrapper, Group, Item, SecondaryItem, Help, HelpItem, PopoverItem };
