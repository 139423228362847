import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

import { UserSubscriptionInfo } from '@root/shared-files/modules/subscriptions/types/user-subscription-info';

import { IAuth, IAuthUser } from '../types';
import { QuizState } from '../types/quiz-state';

export type IAuthState = {
  initialized: boolean;
  loading: boolean;
  error: string | null;
  user: IAuthUser | null;
  subscriptionInfo: UserSubscriptionInfo | null;
  isTokensRefreshing: boolean;
  isTokenRefreshed: boolean;
  ghostToken: string | null;
  quizState: QuizState | null;
  resetTwoFaModalOpen: boolean;
  changePasswordModalOpen: boolean;
  changeEmailModalOpen: boolean;
};

export namespace AuthActions {
  export type SignedIn = PayloadAction<IAuth>;
  export type SignedOut = PayloadAction;
  export type UpdateUserInfo = PayloadAction<IAuthUser>;
  export type SignedInWithGoogle = PayloadAction<IAuth>;
  export type FetchProfilePending = PayloadAction;
  export type FetchProfileFulfilled = PayloadAction<Omit<IAuth, 'tokens'>>;
  export type FetchProfileRejected = PayloadAction<string>;
  export type TokensMissed = PayloadAction;
  export type TokensRefreshed = PayloadAction<IAuth & { tokensUpdatedAlready?: boolean }>;
  export type RefreshSession = PayloadAction;
  export type RefreshSessionAfterTradeIdeaSubscription = PayloadAction;
  export type RefetchTIsAfterRefresh = PayloadAction;
  export type SetQuizState = PayloadAction<QuizState>;
  export type SetIsTokensRefreshing = PayloadAction<boolean>;
  export type StartGhostLogin = PayloadAction<string>;
  export type FinalizeGhostLogin = PayloadAction<string | null>;
  export type SetResetTwoFaModalOpen = PayloadAction<boolean>;
  export type SetChangePasswordModalOpen = PayloadAction<boolean>;
  export type SetChangeEmailModalOpen = PayloadAction<boolean>;
}

export type AuthSliceReducer = {
  signedIn: CaseReducer<IAuthState, AuthActions.SignedIn>;
  signedOut: CaseReducer<IAuthState, AuthActions.SignedOut>;
  updateUserInfo: CaseReducer<IAuthState, AuthActions.UpdateUserInfo>;
  fetchProfilePending: CaseReducer<IAuthState, AuthActions.FetchProfilePending>;
  fetchProfileFulfilled: CaseReducer<IAuthState, AuthActions.FetchProfileFulfilled>;
  fetchProfileRejected: CaseReducer<IAuthState, AuthActions.FetchProfileRejected>;
  signedInWithGoogle: CaseReducer<IAuthState, AuthActions.SignedInWithGoogle>;
  tokensMissed: CaseReducer<IAuthState, AuthActions.TokensMissed>;
  tokensRefreshed: CaseReducer<IAuthState, AuthActions.TokensRefreshed>;
  refreshSession: CaseReducer<IAuthState, AuthActions.RefreshSession>;
  refreshSessionAfterTradeIdeaSubscription: CaseReducer<
    IAuthState,
    AuthActions.RefreshSessionAfterTradeIdeaSubscription
  >;
  refetchTIsAfterRefresh: CaseReducer<IAuthState, AuthActions.RefetchTIsAfterRefresh>;
  setIsTokensRefreshing: CaseReducer<IAuthState, AuthActions.SetIsTokensRefreshing>;
  startGhostLogin: CaseReducer<IAuthState, AuthActions.StartGhostLogin>;
  finalizeGhostLogin: CaseReducer<IAuthState, AuthActions.FinalizeGhostLogin>;
  setQuizState: CaseReducer<IAuthState, AuthActions.SetQuizState>;
  setResetTwoFaModalOpen: CaseReducer<IAuthState, AuthActions.SetResetTwoFaModalOpen>;
  setChangePasswordModalOpen: CaseReducer<IAuthState, AuthActions.SetChangePasswordModalOpen>;
  setChangeEmailModalOpen: CaseReducer<IAuthState, AuthActions.SetChangeEmailModalOpen>;
};

export const authState: IAuthState = {
  initialized: false,
  loading: false,
  error: null,
  user: null,
  subscriptionInfo: null,
  ghostToken: null,
  isTokensRefreshing: false,
  isTokenRefreshed: false,
  quizState: null,
  resetTwoFaModalOpen: false,
  changePasswordModalOpen: false,
  changeEmailModalOpen: false,
};

export const authSlice = createSlice<IAuthState, AuthSliceReducer, 'auth'>({
  name: 'auth',
  initialState: authState,
  reducers: {
    signedIn: (state, action) => {
      state.user = action.payload.user;
    },
    signedOut: (state) => {
      state.user = null;
    },
    updateUserInfo: (state, action) => {
      state.user = action.payload;
    },
    fetchProfilePending: (state) => {
      state.loading = true;
    },
    fetchProfileFulfilled: (state, action) => {
      state.loading = false;
      state.initialized = true;
      state.user = action.payload.user;
      state.subscriptionInfo = action.payload.userSubscriptionInfo;
    },
    fetchProfileRejected: (state, action) => {
      state.loading = false;
      state.initialized = true;
      state.error = action.payload;
    },
    signedInWithGoogle: (state, action) => {
      state.user = action.payload.user;
    },
    tokensMissed: (state) => {
      state.initialized = true;
      state.loading = false;
      state.user = null;
    },
    setIsTokensRefreshing: (state, action) => {
      state.isTokensRefreshing = action.payload;
    },
    tokensRefreshed: (state, action) => {
      state.user = action.payload.user;
      state.subscriptionInfo = action.payload.userSubscriptionInfo;
      state.isTokenRefreshed = true;
    },
    refreshSession: () => {},
    refreshSessionAfterTradeIdeaSubscription: () => {},
    refetchTIsAfterRefresh: () => {},
    startGhostLogin: () => {},
    finalizeGhostLogin: (state, action) => {
      state.ghostToken = action.payload;
    },
    setQuizState: (state, action) => {
      state.quizState = action.payload;
    },
    setResetTwoFaModalOpen: (state, action) => {
      state.resetTwoFaModalOpen = action.payload;
    },
    setChangePasswordModalOpen: (state, action) => {
      state.changePasswordModalOpen = action.payload;
    },
    setChangeEmailModalOpen: (state, action) => {
      state.changeEmailModalOpen = action.payload;
    },
  },
});
