import { TextField } from '@root/shared/form';
import { Button } from '@root/shared/ui/button';
import { Text } from '@root/shared/ui/typography';
import { FC, useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store';
import { useFormikContext } from 'formik';
import { ConnectTwoFaForm } from '../../hooks/use-connect-turn-two-fa-modal';
import { connectEmail } from '../../services/two-fa/connect-email.service';
import { notify } from '@root/shared/utils/notification';
import { useGetTwoFaMethods } from '../../hooks/use-get-two-fa-methods';
import { TwoFAAuthType } from '../../types/two-fa';
import { useResendCode } from '../../hooks/use-resend-code';

type Props = {
    closeModal: () => void;
}

const SUPPORT_EMAIL = 'support@sagemaster.io';

export const EnableByEmail: FC<Props> = ({ closeModal }) => {
    const { t } = useTranslation('two-fa');
    const { data: methods } = useGetTwoFaMethods();
    const [loading, setLoading] = useState<boolean>(false);
    const { values, errors, isSubmitting } = useFormikContext<ConnectTwoFaForm>();
    const user = useSelector(authSelector.user);
    const [step, setStep] = useState<number>(2);

    const defaulEmail = useMemo(() => {
        return methods?.find((m) => m.type === TwoFAAuthType.Email)?.email || user?.email || '';
    }, [methods, user?.email]);

    const { restTime, resendLoading, ableToResend, resendCode } = useResendCode('EnableByEmailForm', values.email || defaulEmail);

    const handleSendVerificationCode = useCallback(async () => {
        setLoading(true);
        const email = values.email || defaulEmail;
        const response = await connectEmail(email);
        if (response.status === 200) {
            setStep(2);
        } else {
            notify({
                type: 'danger',
                title: response.payload,
            });
        }
        setLoading(false);
    }, [defaulEmail, values.email]);

    return <div className='w-full flex flex-col gap-y-4'>
        {step === 1 &&
            <div>
                <Text size='sm' className='mb-6'>{t('connectTwoFaModal.email.emailHint')}</Text>
                <TextField
                    name='email'
                    label={t('connectTwoFaModal.email.email.label')}
                    placeholder={t('connectTwoFaModal.email.email.placeholder')}
                />
                <div className="w-full flex justify-between items-center mt-4">
                    <Button onClick={closeModal} outlined>{t('connectTwoFaModal.email.cancel')}</Button>
                    <Button loading={loading} onClick={handleSendVerificationCode} disabled={!!errors?.email}>{t('connectTwoFaModal.email.sendVerificationCode')}</Button>
                </div>
            </div>
        }
        {step === 2 &&
            <div>
                <Text size='base'>
                    <Trans
                        i18nKey='two-fa:connectTwoFaModal.email.enterCodeHint'
                        components={{ span: <span className='font-bold' /> }}
                        values={{ email: values.email && !errors?.email ? values.email : user?.email }}
                    />
                </Text>
                <div className='flex'>
                    <Text size='base' className='text-primary-400 cursor-pointer mb-4' onClick={() => setStep(1)}>
                        {t('connectTwoFaModal.email.useAnotherEmail')}
                    </Text>
                </div>
                <TextField
                    name='code'
                    placeholder={t('connectTwoFaModal.email.sendCode.placeholder')}
                    suffix={
                        ableToResend ? <Button loading={resendLoading} className='!border-none !py-0 !px-0' outlined onClick={resendCode}>
                            <Text className='text-primary-400 font-medium'>
                                {t('connectTwoFaModal.email.sendCode.resendCode')}
                            </Text>
                        </Button> : <Text className='font-bold'>
                            {restTime}
                        </Text>
                    }
                    suffixClassName='!z-10'
                />
                <Text size='sm' className='text-grayscale-500 mt-4'>
                    <Trans
                        i18nKey='two-fa:verificationIssues'
                        components={{ a: <a className='underline font-medium' href={`mailto:${SUPPORT_EMAIL}`} /> }}
                        values={{ email: SUPPORT_EMAIL }}
                    />
                </Text>
                <div className="w-full flex justify-between items-center mt-4">
                    <Button onClick={closeModal} outlined>{t('connectTwoFaModal.email.cancel')}</Button>
                    <Button type='submit' loading={isSubmitting}>{t('connectTwoFaModal.activeTwoFa')}</Button>
                </div>
            </div>}
    </div>;
}

