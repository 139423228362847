
import { FC, SVGProps } from 'react';
import { baseIconProps } from './base-icon-props';

export const LightBulbIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg {...baseIconProps} viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M2.87494 8.75H6.12494M3.41661 10.375H5.58327M2.87494 7.125C2.87548 6.04167 2.60465 5.77083 2.06244 5.22917C1.52023 4.6875 1.26348 4.13879 1.24994 3.33333C1.22448 1.68125 2.33327 0.625 4.49994 0.625C6.66715 0.625 7.77648 1.68125 7.74994 3.33333C7.73748 4.13879 7.47911 4.6875 6.93744 5.22917C6.39632 5.77083 6.12548 6.04167 6.12494 7.125" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

