import styled from 'styled-components';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import RelativePortal from 'react-relative-portal';

import { userSettingsSlice } from '@root/modules/user-settings/store/user-settings.slice';
import { helpLinks } from '@root/shared/constants/social-links';
import { SvgSpriteIcon } from '@root/shared/ui/svg-sprite-icon';
import { Text } from '@root/shared/ui/typography';

const Wrapper = styled.div`
  position: absolute;
  z-index: 20;
  left: calc(100% + 80px);
  top: -200px;
  min-width: 280px;
  box-shadow: 0 10px 24px 0 rgba(12, 12, 12, 0.6);
`;

export const FeedbackContent: FC = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  const handleOpenSendFeedBack = (e) => {
    e.preventDefault();
    dispatch(userSettingsSlice.actions.setFeedbackModal(true));
  };

  return (
    <RelativePortal>
      <Wrapper className='p-4 rounded bg-gray-800 !text-gray-100 !hover:text-grayscale-100'>
        <Text className='font-bold !text-[14px] mb-3'>{t('sideBar.help.title')}</Text>

        <div className='w-full'>
          <div className='mb-3'>
            <a href={helpLinks.submitFeature} onClick={handleOpenSendFeedBack} className='w-full flex items-center gap-x-2 hover:text-primary-400' target='_blank' rel='noreferrer'>
              <SvgSpriteIcon id='cursor' height={16} width={16} />
              <Text size='sm'>{t('sideBar.help.report')}</Text>
            </a>
          </div>
          {/*<div className='mb-3'>*/}
          {/*  <a href={helpLinks.roadmap} className='w-full flex items-center gap-x-2 hover:text-primary-400' target='_blank' rel='noreferrer'>*/}
          {/*    <ArrowGoUpIcon height={16} width={16} /> <Text size='sm'>{t('sideBar.help.roadmap')}</Text>*/}
          {/*  </a>*/}
          {/*</div>*/}
          {/*<div className='mb-3'>*/}
          {/*  <a href={helpLinks.knowbase} className='w-full flex items-center gap-x-2 hover:text-primary-400' target='_blank' rel='noreferrer'>*/}
          {/*    <SvgSpriteIcon id='bookmark' height={16} width={16} />*/}
          {/*    <Text size='sm'>{t('sideBar.help.knowledgeBase')}</Text>*/}
          {/*  </a>*/}
          {/*</div>*/}
          {/*<div className='mb-3'>*/}
          {/*  <a href={helpLinks.telegram} className='w-full flex items-center gap-x-2 hover:text-primary-400' target='_blank' rel='noreferrer'>*/}
          {/*    <SvgSpriteIcon id='telegram' height={16} width={16} />*/}
          {/*    <Text size='sm'>{t('sideBar.help.telegram')}</Text>*/}
          {/*  </a>*/}
          {/*</div>*/}
          {/*<div className='mb-3'>*/}
          {/*  <a href={helpLinks.youtube} className='w-full flex items-center gap-x-2 hover:text-primary-400' target='_blank' rel='noreferrer'>*/}
          {/*    <SvgSpriteIcon id='youtube' height={16} width={16} />*/}
          {/*    <Text size='sm'>{t('sideBar.help.youtube')}</Text>*/}
          {/*  </a>*/}
          {/*</div>*/}
          {/*<div className='w-full flex items-center gap-x-2 cursor-pointer' onClick={() => (window as any)?.LC_API?.open_chat_window()}>*/}
          {/*  <SvgSpriteIcon id='discussion' height={16} width={16} />*/}
          {/*  <Text size='sm'>{t('sideBar.help.chat')}</Text>*/}
          {/*</div>*/}
        </div>
      </Wrapper>
    </RelativePortal>
  );
};
