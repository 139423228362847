import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';

import { TwoFaErrorResponse } from '../types/two-fa-field-error';

type FieldErrorResponse = {
  property: string;
  errors: string[];
}[];

export type CheckPasswordUpdateServiceResponse = IHttpResponse<200, string> | IHttpResponse<400, string> | IHttpResponse<418, FieldErrorResponse>;

export const checkPasswordUpdate = async (payload: { password: string; oldPassword: string }): Promise<CheckPasswordUpdateServiceResponse> => {
  try {
    const response = await fetcher.post('/auth/profile/check-password-update', payload);

    return {
      status: 200,
      payload: response.data.message,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      if (typeof error.response.data.message === 'object') {
        return {
          status: 418,
          payload: error.response.data.message,
        };
      }

      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
