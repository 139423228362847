import clsx from 'clsx';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { AbsolutePortal } from '@root/infra/portals';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { userSettingsSlice } from '@root/modules/user-settings/store/user-settings.slice';
import { PageLinks } from '@root/shared/constants/pageLinks';
import { WriteMessageIcon } from '@root/shared/icons/write-message-icon';
import { SvgSpriteIcon } from '@root/shared/ui/svg-sprite-icon';
import { Text } from '@root/shared/ui/typography';
import { externalRedirect } from '@root/shared/utils/helpers/external-redirect';

import { LayoutElements } from '../components';
import { useDropdown } from '../hooks/use-dropdown';
import { useSubscriptionTitles } from '../hooks/use-subscription-titles';
import { MenuItem } from './header.elements';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { FXIcon } from '@root/shared/icons/fx-icon';
import { CryptoIcon } from '@root/shared/icons/crypto-icon';
import { SettingsIcon } from '@root/shared/icons/settings-icon';
import { CheckTwoFaBadge } from '@root/shared-files/modules/auth/components/two-fa/check-two-fa-enabled';

const { MobileContainer, MobileLinksContainer } = LayoutElements.Header;
const { HelpItem } = LayoutElements.Sidebar;

export const MobileHeaderMenu: FC<{ toggleMenu: () => void }> = ({ toggleMenu }) => {
    const { t } = useTranslation('common');
    const { title, subTitle, icon, className } = useSubscriptionTitles();
    const Icon = icon;

    const user = useSelector(authSelector.user);

    const [, { logout }] = useDropdown();

    const { isGhostLogin } = useGhostLogin();

    const dispatch = useDispatch();

    const handleOpenSendFeedBack = () => {
        toggleMenu();
        dispatch(userSettingsSlice.actions.setFeedbackModal(true));
    };

    const redirectToCryptoSite = useCallback(() => {
        externalRedirect(import.meta.env.VITE_CRYPTO_SITE);
    }, []);

    const redirectToForexSite = useCallback(() => {
        externalRedirect(import.meta.env.VITE_FX_SITE);
    }, []);

    return (
        <AbsolutePortal>
            <MobileContainer isGhostLogin={isGhostLogin}>
                <div>
                    <MobileLinksContainer className='px-2 sm:px-4 pt-4'>
                        <div className='mb-4'>
                            <div className='flex items-center pl-3'>
                                <span className='flex flex-col'>
                                    <Text className='font-medium'>{title}</Text>
                                    <Text size='sm'>{user?.email as string}</Text>
                                    {subTitle && (
                                        <div className={clsx('flex items-center gap-x-2', className)}>
                                            {Icon ? <Icon /> : null}
                                            <div className='mt-1'>{subTitle}</div>
                                        </div>
                                    )}
                                </span>
                            </div>
                            <div className="flex justify-between items-center">
                                <MenuItem link={'/settings'}>
                                    <SettingsIcon />{' '}
                                    <Text size='sm' className='font-semibold'>
                                        {t('header.dropdown.settings')}
                                    </Text>
                                </MenuItem>
                                <div onClick={toggleMenu}>
                                    <CheckTwoFaBadge />
                                </div>
                            </div>
                            <div className='pl-4'>
                                <MenuItem onClick={logout}>
                                    <SvgSpriteIcon id='logout' />
                                    <Text size='sm' className='font-semibold'>
                                        {t('header.dropdown.logout')}
                                    </Text>
                                </MenuItem>
                            </div>
                        </div>

                        <MenuItem link={PageLinks.home}>
                            <SvgSpriteIcon id='dashboard' />
                            <Text size='sm' className='font-semibold'>
                                {t('sideBar.dashboard')}
                            </Text>
                        </MenuItem>

                        <div className='mb-2'>
                            <MenuItem link={'/subscriptions'}>
                                <SvgSpriteIcon id='subscriptions' />
                                <Text size='sm' className='font-semibold'>
                                    {t('sideBar.subscriptions')}
                                </Text>
                            </MenuItem>
                        </div>

                        <div className='mb-2' onClick={redirectToCryptoSite}>
                            <MenuItem>
                                <CryptoIcon />
                                <Text size='sm' className='font-semibold'>
                                    {t('sideBar.crypto')}
                                </Text>
                            </MenuItem>
                        </div>

                        <div className='mb-2' onClick={redirectToForexSite}>
                            <MenuItem>
                                <FXIcon />
                                <Text size='sm' className='font-semibold'>
                                    {t('sideBar.fx')}
                                </Text>
                            </MenuItem>
                        </div>

                    </MobileLinksContainer>
                </div>

                <div className='w-full'>
                    <div className='flex items-center px-4 pb-4 justify-center'>
                        <HelpItem>
                            <div className='cursor-pointer' onClick={handleOpenSendFeedBack}>
                                <WriteMessageIcon />
                            </div>
                        </HelpItem>
                    </div>
                </div>
            </MobileContainer>
        </AbsolutePortal>
    );
};
