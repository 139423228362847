import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { TwoFAMethodData } from '../../types/two-fa';
import { TwoFaErrorResponse } from '../../types/two-fa-field-error';

export type VerifyByEmailResponse =
  | IHttpResponse<200, { method: TwoFAMethodData; backupCodes: string[] }>
  | IHttpResponse<400, string>
  | IHttpResponse<418, TwoFaErrorResponse>;

export const verifyByEmail = async (code: string): Promise<VerifyByEmailResponse> => {
  try {
    const response = await fetcher.post('/auth/2fa/methods/email/verify', { code });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      if (typeof error.response.data.message === 'object') {
        return {
          status: 418,
          payload: error.response.data.message,
        };
      }

      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
