import { logEvent as firebaseLogEvent, getAnalytics, setUserId } from 'firebase/analytics';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { authSelector } from '@root/shared-files/modules/auth/store';

export const useLogEvent = () => {
  const user = useSelector(authSelector.user);

  const logEvent = useCallback(
    (eventName: string, params: Record<string, string | number | boolean>) => {
      const analytics = getAnalytics();
      setUserId(analytics, (user?.id as string) || null);
      firebaseLogEvent(analytics, eventName, params);
    },
    [user?.id],
  );


  
  return {
    logEvent,
  };
};
