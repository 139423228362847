import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const ChevronIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} fill='none' viewBox='-12 -7 12 8' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path transform='rotate(180)' d='M10.667 1.5L6.02488 6.14212L1.38276 1.5' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const ChevronLeftIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} {...props} viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M5.97559 1.3335L1.33347 5.97561L5.97559 10.6177' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export const ChevronRightIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} {...props} viewBox='0 0 8 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1.66699 1.3335L6.30911 5.97561L1.66699 10.6177' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};
