import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';

import { Conversation } from '../types/chat';

export type ChangeLikeStatusServiceResponse = IHttpResponse<200, Conversation> | IHttpResponse<400, string>;
export type ChangeLikeStatusServiceParams = {
  likeStatus: number;
};

const VITE_CHAT_API_URL = import.meta.env.VITE_CHAT_API_URL;

export const changeLikeStatus = async (conversationId: string, messageId: string, payload: ChangeLikeStatusServiceParams): Promise<ChangeLikeStatusServiceResponse> => {
  try {
    const response = await fetcher.patch(`${VITE_CHAT_API_URL}/master-gpt/conversations/${conversationId}/messages/${messageId}/like-status`, payload);

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    return handleAxiosError(error);
  }
};
