export function escapeDollarMathNumber(text: string) {
  return text.replaceAll('$', '\\$');
}


export function replaceStandardError(text: string) {
  if(text === 'Something Went Wrong!'){
    return 'We apologize, but we\'re currently experiencing technical difficulties. Our team is working hard to resolve this as soon as possible. Please try again later. Thank you for your patience!\n'
  }
  return text
}
