import { baseIconProps } from '@root/shared/icons/base-icon-props';
import { FC, SVGProps } from 'react';
const imgUrl = new URL('@root/assets/images/sprite.svg', import.meta.url).href;

export const SvgSpriteIcon: FC<SVGProps<SVGSVGElement & { id: string }>> = (props) => {
  return (
    <svg {...baseIconProps} {...props}>
      <use href={`${imgUrl}#${props.id}`} />
    </svg>
  );
};
