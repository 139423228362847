import { Label, Select } from '@root/shared/ui/form';
import { Title } from '@root/shared/ui/typography';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const Language: FC = () => {
  const { t, i18n } = useTranslation('settings');

  const options = useMemo(
    () => [
      {
        label: t('language.options.en'),
        value: 'en',
      },
      {
        label: t('language.options.es'),
        value: 'es',
      },
      {
        label: t('language.options.fr'),
        value: 'fr',
      },
      {
        label: t('language.options.it'),
        value: 'it',
      },
      {
        label: t('language.options.ja'),
        value: 'ja',
      },
      {
        label: t('language.options.de'),
        value: 'de',
      },
      {
        label: t('language.options.no'),
        value: 'no',
      },
      {
        label: t('language.options.pt'),
        value: 'pt',
      },
      {
        label: t('language.options.zh'),
        value: 'zh',
      },
      {
        label: t('language.options.th'),
        value: 'th',
      },
      {
        label: t('language.options.sk'),
        value: 'sk',
      },
    ],
    [],
  );

  const selectedOption = useMemo(() => options.find((item) => item.value === i18n.language), [i18n.language, options]);

  const handleChange = useCallback(
    async (option) => {
      i18n.changeLanguage(option.value as string).then(() => console.log('Language changed'));
    },
    [i18n.changeLanguage],
  );

  return (
    <div className='w-full bg-gray-800 rounded py-4 md:py-6 px-4 md:px-10'>
      <Title level={7} className='mb-4'>
        {t('language.title')}
      </Title>
      <div>
        <Label>{t('language.label')}</Label>
        <Select value={selectedOption} onChange={handleChange} options={options} bordered />
      </div>
    </div>
  );
};
