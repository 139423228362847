import { Modal, ModalProps } from '@root/shared/ui/modal';
import { FC, useCallback } from 'react';
import { Text } from '@root/shared/ui/typography';
import { useTranslation } from 'react-i18next';
import { Button } from '@root/shared/ui/button';
import { copyToClipboard } from '@root/shared/utils/helpers/copy-to-clipboard';
import { InfoIcon } from '@root/shared/icons/info-icon';
import { notify } from '@root/shared/utils/notification';

type Props = ModalProps & {
    backupCodes: string[];
}

export const BackupCodesModal: FC<Props> = ({ backupCodes, onCancel }) => {
    const { t } = useTranslation('two-fa');

    const handleCopy = useCallback(() => {
        copyToClipboard(backupCodes.join(' '));
        notify({
            type: 'success',
            title: t('backupCodes.copySuccess'),
        })
    }, [backupCodes, t]);

    const downloadTxt = useCallback(() => {
        try {
            const element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(backupCodes.join('\n')));
            element.setAttribute('download', 'backup-codes.txt');
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
        } catch (error) {
            notify({
                type: 'danger',
                title: t('backupCodes.downloadError'),
            });
        }
    }, [backupCodes, t]);

    return <Modal isOpen title={t('backupCodes.title')} footer={null} onCancel={onCancel} disabledOutSideClick>
        <div className='w-full'>
            <Text size='base'>
                {t('backupCodes.description')}
            </Text>
            <div className='w-full flex justify-start items-center gap-x-4 text-secondary-200 mt-4'>
                <InfoIcon width={24} height={24} />
                <Text size='base'>{t('backupCodes.warning')}</Text>
            </div>
            <div className='flex flex-wrap justify-start items-start gap-x-4 gap-y-2 my-4'>
                {backupCodes.map((code, index) => (
                    <div className='bg-gray-900 rounded py-1 px-4' key={index}>
                        <Text size='base' className='text-gray-100'>
                            {code}
                        </Text>
                    </div>
                ))}
            </div>
            <div className='mb-4 flex justify-start items-center gap-4'>
                <Button onClick={handleCopy}>{t('backupCodes.copy')}</Button>
                <Button onClick={downloadTxt}>{t('backupCodes.downloadTxt')}</Button>
            </div>
            <div className='w-full flex justify-between items-center gap-2'>
                <Button onClick={onCancel} outlined>{t('backupCodes.cancel')}</Button>
                <Button onClick={onCancel}>{t('backupCodes.next')}</Button>
            </div>
        </div>
    </Modal>;
}

