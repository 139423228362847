const appType = import.meta.env.VITE_APP_TYPE;

const config = {
  forex: {
    sourceApp: appType,
  },
  crypto: {
    sourceApp: appType,
  },
};

export const AuthModuleConfig = config[appType];
