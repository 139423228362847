import 'katex/dist/katex.min.css';
import { RefObject, useRef } from 'react';
import React from 'react';

import ReactMarkdown from 'react-markdown';
import RehypeHighlight from 'rehype-highlight';
import RehypeKatex from 'rehype-katex';
import RemarkBreaks from 'remark-breaks';
import RemarkGfm from 'remark-gfm';
import RemarkMath from 'remark-math';
import { ReactComponent as LoadingIcon } from '../icons/three-dots.svg';
import { escapeDollarMathNumber, replaceStandardError } from '../utils/escaped-text';

function _MarkDownContent(props: { content: string }) {
  const content = replaceStandardError( escapeDollarMathNumber(props.content));

  return (
    <ReactMarkdown
      remarkPlugins={[RemarkMath, RemarkGfm, RemarkBreaks]}
      rehypePlugins={[
        RehypeKatex,
        [
          RehypeHighlight,
          {
            detect: false,
            ignoreMissing: true,
          },
        ],
      ]}
      components={{
        pre: (preProps) => <pre {...preProps} />,
        p: (pProps) => <p {...pProps} dir='auto' />,
        a: (aProps) => {
          const href = aProps.href || '';
          const isInternal = /^\/#/i.test(href);
          const target = isInternal ? '_self' : aProps.target ?? '_blank';
          return <a {...aProps} target={target} />;
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );
}

export const MarkdownContent = React.memo(_MarkDownContent);

export function Markdown(
  props: {
    content: string;
    loading?: boolean;
    fontSize?: number;
    parentRef?: RefObject<HTMLDivElement>;
    defaultShow?: boolean;
  } & React.DOMAttributes<HTMLDivElement>,
) {
  const mdRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className='markdown-body'
      style={{
        fontSize: `${props.fontSize ?? 14}px`,
      }}
      ref={mdRef}
      onContextMenu={props.onContextMenu}
      onDoubleClickCapture={props.onDoubleClickCapture}
      dir='auto'
    >
      {props.loading ? (
        <LoadingIcon />
      ) : (
        // <div>{props.content}</div>
        <MarkdownContent content={props.content} />
      )}
    </div>
  );
}
