import { ToastCmp } from '@root/shared/ui/toast';
import { ReactNode } from 'react';
import { toast, ToastOptions } from 'react-toastify';

export type ToastProps = {
  title?: ReactNode;
  text?: string | { content: string } | ReactNode;
  type: 'info' | 'success' | 'danger';
};

export const notify = (toastInfo: ToastProps, options?: ToastOptions) => {
  toast(<ToastCmp toast={toastInfo} />, options);
};
