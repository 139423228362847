import { useQuery } from 'react-query';

import { GET_SUBSCRIPTION_PRODUCTS, GetSubscriptionProductsData, GetSubscriptionProductsQueryKey } from '../queries/get-subscription-products.query';

export const useGetSubscriptionProducts = (enabled = true) => {
  return useQuery<GetSubscriptionProductsData, Error, GetSubscriptionProductsData, GetSubscriptionProductsQueryKey>(['subscription-products'], GET_SUBSCRIPTION_PRODUCTS, {
    keepPreviousData: false,
    staleTime: 1000 * 60 * 10,
    enabled,
  });
};
