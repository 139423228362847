import { FC, useCallback, useMemo } from 'react';
import styles from './quick-prompts.module.scss';
import { Text } from '@root/shared/ui/typography';
import { useTranslation } from 'react-i18next';
import { useChatStore } from '../store';
import { useLogEvent } from '@root/shared/utils/hooks/use-log-event';

export const QuickPrompts: FC = () => {
    const { t, i18n } = useTranslation('master-chat');
    const chatStore = useChatStore();

    const prompts = useMemo(() => {
        return [
            t('Store.QuickPrompts.0'),
            t('Store.QuickPrompts.1'),
            t('Store.QuickPrompts.2'),
            t('Store.QuickPrompts.3'),
        ]
    }, [t]);

    const { logEvent } = useLogEvent();

    const handlePromptClick = useCallback((index: number) => {
        chatStore.onUserInput(prompts[index], t, i18n.language, logEvent);
    }, [chatStore, i18n.language, logEvent, prompts, t]);

    return <div className='mx-auto grid sm:grid-cols-2 gap-4'>
        {
            prompts.map((prompt, index) => <div
                key={index}
                onClick={() => handlePromptClick(index)}
                className={styles['quick-prompt-item']}
            >
                <Text size='sm'>{prompt}</Text>
            </div>
            )
        }
    </div>
}




