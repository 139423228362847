import * as yup from 'yup';

export const changeEmailValidation = (t) => {
  return yup.object().shape({
    email: yup
      .string()
      .email(t('changeEmailModal.email.email'))
      .required(t('changeEmailModal.email.required'))
      .max(50, t('changeEmailModal.email.max', { maxValue: 50 }))
      .label(t('changeEmailModal.email.label')),
    type: yup.string(),
    code: yup.string().when('useBackupCode', {
      is: false,
      then: yup.string().required(t('two-fa:validation.code.required')).label('two-fa:validation.code.label'),
    }),
    useBackupCode: yup.boolean(),
    backupCode: yup.string().when('useBackupCode', {
      is: true,
      then: yup
        .string()
        .required(t('two-fa:validation.backupCode.required'))
        .label('two-fa:validation.backupCode.label'),
    }),
  });
};
