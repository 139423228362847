import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const GoogleIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 15 17' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.729 4.86529C10.903 4.07749 9.8007 3.64831 8.66015 3.66595C6.57307 3.66595 4.80053 5.07399 4.16852 6.96999V6.97003C3.83341 7.9636 3.83341 9.03947 4.16851 10.033H4.17145C4.80639 11.9261 6.576 13.3342 8.66309 13.3342C9.74044 13.3342 10.6653 13.0586 11.3822 12.5719V12.5699C12.2258 12.0114 12.802 11.1325 12.9813 10.1389H8.66016V7.05823H16.206C16.3001 7.59323 16.3442 8.13998 16.3442 8.6838C16.3442 11.117 15.4746 13.1742 13.9615 14.5676L13.9631 14.5688C12.6374 15.7916 10.8178 16.5001 8.66015 16.5001C5.63535 16.5001 2.86922 14.7951 1.51115 12.0937V12.0937C0.376489 9.83315 0.376492 7.16992 1.51116 4.9094H1.51117L1.51115 4.90938C2.86922 2.20499 5.63535 0.500051 8.66015 0.500051C10.6473 0.476534 12.5668 1.22318 14.0131 2.58126L11.729 4.86529Z'
      />
    </svg>
  );
};
