import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector, authSlice } from '../store';
import { useTranslation } from 'react-i18next';
import { Title, Text } from '@root/shared/ui/typography';
import { Button } from '@root/shared/ui/button';
import { ChangePasswordModal } from './change-password-modal';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { ChangeEmailModal } from './change-email-modal';
import { useGetTwoFaMethods } from '../hooks/use-get-two-fa-methods';
import { useVerifyEmailChange } from '../hooks/use-verify-email-change';
import { Modal } from '@root/shared/ui/modal';
import { Image } from '@root/shared/ui/image';

import emailSentImage from '@root/assets/images/email-sent.webp';
import { useGhostLogin } from '../hooks/use-ghost-login';

export const ProfileSettings: FC = () => {
    const { t } = useTranslation('settings');
    const emailOpen = useSelector(authSelector.changeEmailModalOpen);
    const passwordOpen = useSelector(authSelector.changePasswordModalOpen);
    const user = useSelector(authSelector.user);
    const { data: methods } = useGetTwoFaMethods();
    const { isGhostLogin } = useGhostLogin();

    const dispatch = useDispatch();

    const hasTwoFaActiveMethod = useMemo(() => {
        return !!methods?.some((method) => method.id && method.isEnabled && method.isActivated);
    }, [methods]);

    const { successOpen, setSuccessOpen } = useVerifyEmailChange();

    return <div className='w-full bg-gray-800 py-4 md:py-6 px-4 md:px-10 grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-4'>
        <div className='md:col-span-2'>
            <Title level={6}>{t('profile.title')}</Title>
        </div>
        <div className='flex justify-start items-center gap-4'>
            <Text size='sm' className='text-grayscale-500'>{t('profile.id')}</Text>
            <Text size='sm'>{user?.id}</Text>
        </div>
        <div className='flex justify-start items-center gap-4'>
            <Text size='sm' className='text-grayscale-500'>{t('profile.email')}</Text>
            <div className="flex justify-start items-center gap-x-2">
                <Text size='sm'>{user?.email}</Text>
            </div>
        </div>
        <div className='flex justify-start items-center gap-4'>
            <Text size='sm' className='text-grayscale-500'>{t('profile.name')}</Text>
            <Text size='sm'>{user?.fullName}</Text>
        </div>
        <div className='flex justify-start items-center gap-4'>
            <Text size='sm' className='text-grayscale-500'>{t('profile.country')}</Text>
            <Text size='sm'>{user?.country}</Text>
        </div>
        <div>
            <Button outlined onClick={() => dispatch(authSlice.actions.setChangePasswordModalOpen(true))} disabled={isGhostLogin}>{t('profile.changePassword')}</Button>
        </div>
        <div className='flex'>
            {hasTwoFaActiveMethod ?
                <Button
                    outlined onClick={() => dispatch(authSlice.actions.setChangeEmailModalOpen(true))}
                    disabled={isGhostLogin}
                >
                    {t('profile.changeEmail')}
                </Button> :
                <InfoTooltip content={<Text size='sm'>{t('profile.enabledTwoFaWarning')}</Text>} positions={['bottom', 'right', 'top', 'left']}>
                    <Button
                        disabled={!hasTwoFaActiveMethod || isGhostLogin}
                        outlined
                    >
                        {t('profile.changeEmail')}
                    </Button>
                </InfoTooltip>
            }
        </div>
        {passwordOpen && <ChangePasswordModal />}
        {emailOpen && <ChangeEmailModal />}
        {successOpen && <Modal isOpen footer={null} onCancel={() => setSuccessOpen(false)}>
            <div className='w-full flex flex-col items-center'>
                <Image quality={100} height={80} width={80} src={emailSentImage} wrapperClassName='mb-6' alt='E-Email sent illustration' />
                <Title className='text-center mb-4' level={5} bold>
                    {t('changeEmailModal.verifySuccess.title')}
                </Title>
                <Text size='base' className='text-center text-gray-400 mb-10'>
                    {t('changeEmailModal.verifySuccess.description')}
                </Text>
                <Button outlined onClick={() => setSuccessOpen(false)}>
                    {t('changeEmailModal.ok')}
                </Button>
            </div>
        </Modal>}
    </div>;
}

