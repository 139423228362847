import { useCallback, useMemo } from 'react';
import { EnterCodeWithBackupForm, TwoFAAuthType } from '../types/two-fa';
import { useGetTwoFaMethods } from './use-get-two-fa-methods';
import { changePasswordValidation } from '../validations/change-password.validation';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { updatePassword } from '../services/update-password.service';
import { notify } from '@root/shared/utils/notification';
import { getActiveMethod } from '../helpers/get-active-method';
import { handleTwoFaErrors } from '../helpers/handle-two-fa-errors';

export type ChangePasswordForm = EnterCodeWithBackupForm & {
  step: 1 | 2;
  oldPassword: string;
  password: string;
  confirmPassword: string;
  type: TwoFAAuthType | null;
};

export const useChangePasswordForm = () => {
  const { data: methods } = useGetTwoFaMethods();
  const { t } = useTranslation('settings');

  const initialValues = useMemo<ChangePasswordForm>(() => {
    const { method } = getActiveMethod(methods || []);

    return {
      step: 1,
      oldPassword: '',
      password: '',
      confirmPassword: '',
      type: method?.type || null,
      code: '',
      useBackupCode: false,
      backupCode: '',
    };
  }, [methods]);

  const schema = useMemo(() => changePasswordValidation(t), [t]);

  const onSubmit = useCallback(
    async (values: ChangePasswordForm, helpers: FormikHelpers<ChangePasswordForm>) => {
      const response = await updatePassword({
        oldPassword: values.oldPassword,
        password: values.password,
        twoFactorCode: values.type
          ? values.useBackupCode
            ? {
                type: TwoFAAuthType.Backup,
                code: values.backupCode,
              }
            : {
                type: values.type,
                code: values.code,
              }
          : undefined,
      });

      if (response.status === 200) {
        helpers.setStatus({ type: 'success', message: t('changePasswordModal.success') });
      } else {
        if (response.status === 418) {
          const passwordFields = ['oldPassword', 'password'];
          const passwordErrors = response.payload.filter((e) => passwordFields.includes(e.property));
          if (passwordErrors?.length) {
            const status = {
              apiErrors: {
                [passwordErrors[0]?.property]: passwordErrors[0]?.errors?.[0],
                [passwordErrors[1]?.property]: passwordErrors[1]?.errors?.[0],
              },
            };
            helpers.setStatus(status);
            helpers.setFieldValue('step', 1);
            return;
          }

          handleTwoFaErrors(response, values, helpers as any);
        } else {
          notify({
            type: 'danger',
            title: response.payload,
          });
        }
      }
    },
    [t],
  );

  return {
    initialValues,
    schema,
    onSubmit,
  };
};
