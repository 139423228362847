import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useCheckSubscription } from '@root/shared-files/modules/subscriptions/hooks/use-check-subscription';
import { LoadingScreen } from '@root/shared/ui/common';

import { verifyEmailService } from '../services';
import { authSlice } from '../store';

export const VerifyEmail = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tokenParam = searchParams.get('token');
  const { checkSubscription } = useCheckSubscription();

  const dispatch = useDispatch();

  const token = typeof tokenParam === 'string' ? tokenParam : undefined;

  useEffect(() => {
    (async () => {
      if (token) {
        const response = await verifyEmailService({ token });
        if (response.status === 200) {
          dispatch(authSlice.actions.signedIn(response.payload));
          checkSubscription(response.payload.userSubscriptionInfo.roles, '/');
          return;
        }
      }
      await navigate('/sign-in');
    })();
  }, [navigate, token, dispatch, checkSubscription]);

  return <LoadingScreen />;
};
