import { takeLatest, call, put, SagaReturnType } from 'redux-saga/effects';

import { countriesSlice } from './countries.slice';
import { getCountriesService } from '../services';

function* handleFetch() {
  const result: SagaReturnType<typeof getCountriesService> = yield call(getCountriesService);

  if (result.status === 200) {
    yield put(countriesSlice.actions.fetchFulfilled(result.payload));
  } else {
    yield put(countriesSlice.actions.fetchRejected(result.payload));
  }
}

export function* countriesSaga() {
  yield takeLatest(countriesSlice.actions.fetchPending, handleFetch);
  yield put(countriesSlice.actions.fetchPending());
}
