import clsx from 'clsx';
import { DetailedHTMLProps, forwardRef, HTMLAttributes, InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

export interface InputProps extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'prefix' | 'size'> {
  prefix?: ReactNode;
  suffix?: ReactNode;
  prefixClassName?: string;
  suffixClassName?: string;
  variant?: 'primary' | 'danger';
  size?: 'base' | 'small';
  inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
}

const StyledInputWrapper = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { className, variant, prefix, size = 'small', suffix, prefixClassName, suffixClassName, ...props },
  ref,
) {
  return (
    <StyledInputWrapper className='relative z-0'>
      {prefix && (
        <span
          className={clsx(
            'absolute left-5 top-1/2 transform -translate-y-1/2 -z-1',
            {
              'text-danger-500': variant === 'danger',
              'text-primary-500': variant === 'primary',
            },
            prefixClassName,
          )}
        >
          {prefix}
        </span>
      )}
      <input
        className={clsx(
          'w-full bg-white bg-opacity-10 px-4 sm:px-5 rounded text-base transition outline-none border disabled:bg-grayscale-700 disabled:bg-opacity-80 disabled:text-grayscale-600 z-1',
          {
            'pl-12': !!prefix,
            '!pr-14 sm:!pr-12': !!suffix,
            'border-transparent active:border-white focus:border-white': !variant,
            'border-danger-500': variant === 'danger',
            'border-primary-500': variant === 'primary',
            'py-[7px]': size === 'small',
            'py-3': size === 'base',
          },
          className,
        )}
        {...props}
        type={props.type || 'text'}
        ref={ref}
      />
      {suffix && (
        <span
          className={clsx(
            'absolute right-5 top-1/2 transform -translate-y-1/2 -z-1',
            {
              'text-danger-500': variant === 'danger',
              'text-primary-500': variant === 'primary',
            },
            suffixClassName,
          )}
        >
          {suffix}
        </span>
      )}
    </StyledInputWrapper>
  );
});
