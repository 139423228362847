import * as yup from 'yup';

export const createSignUpValidation = (t) =>
  yup.object().shape({
    fullName: yup
      .string()
      .trim()
      .required(t('fullName.required'))
      .matches(/^[A-Za-z ]*$/, t('fullName.invalidName'))
      .matches(/^(?!\s*$).+/g, t('fullName.onlyLetters'))
      .max(70, t('fullName.max', { maxValue: 70 }))
      .label(t('fullName.label')),
    email: yup
      .string()
      .required(t('email.required'))
      .email(t('email.invalidEmail'))
      .max(50, t('email.maxLength', { maxValue: 50 }))
      .label('email.label'),
    password: yup
      .string()
      .trim()
      .required(t('password.required'))
      .min(8, t('password.min', { minValue: 8 }))
      .max(20, t('password.max', { maxValue: 20 }))
      .matches(/[~!@#$%^&*_\-+=`|\\(){}\[\]:;"'<>,.?\/]+/g, t('password.containsSpecialSymbols'))
      .matches(/[A-Z]+/g, t('password.containsUppercase'))
      .matches(/[a-z]+/g, t('password.containsLowercase'))
      .matches(/[0-9]+/g, t('password.containsNumber'))
      .label(t('resetPassword.password.label')),
    confirmPassword: yup
      .string()
      .trim()
      .test('confirmation-match', t('confirmPassword'), function (value) {
        return this.parent.password === value;
      }),
    countryCode: yup.string().trim().required(t('countryCode')),
    accessCode: yup.string().required(t('accessCode.required')).label('accessCode.label'),
    captchaToken: yup.string().required(t('captcha')),
  });
