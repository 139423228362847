import * as yup from 'yup';

export const createForgotPasswordValidation = (t) =>
  yup.object().shape({
    email: yup
      .string()
      .required(t('email.required'))
      .email(t('email.invalidEmail'))
      .max(50, t('email.maxLength', { maxValue: 50 }))
      .label('email.label'),
    captchaToken: yup.string().required(t('captcha')),
  });
