import { AuthLayout } from '@root/shared-files/modules/auth/components';
import { SignUp } from '@root/shared-files/modules/auth/containers';
import { withNoAuthentication } from '@root/shared-files/modules/auth/hocs';

const SignUpPage = () => {
  return (
    <AuthLayout>
      <SignUp />
    </AuthLayout>
  );
};

// export default withNoAuthentication(SignUpPage);

export default SignUpPage;
