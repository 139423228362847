import { IHttpResponse } from '@root/shared/utils/http-service';
import { AxiosError } from 'axios';

export const handleAxiosError = (error: AxiosError): IHttpResponse<400, string> => {
  console.error(error);

  if (error.isAxiosError && error.response?.data.message) {
    const message: string | string[] = error.response?.data.message;
    const parsedMessage: string = Array.isArray(message) ? message.join(',') : message;

    return {
      status: 400,
      payload: parsedMessage,
    };
  }

  return {
    status: 400,
    payload: error.message,
  };
};
