import emailSentImage from '@root/assets/images/email-sent.webp';
import { IFormStatus, TextField } from '@root/shared/form';
import { useTranslation } from 'react-i18next';
import { Alert } from '@root/shared/ui/alert';
import { Button } from '@root/shared/ui/button';
import Link from '@root/shared/ui/common/static-locales-link';
import { Image } from '@root/shared/ui/image';
import { ButtonLink } from '@root/shared/ui/link';
import { Text, Title } from '@root/shared/ui/typography';
import { Formik, FormikProps } from 'formik';
import { FC, useCallback, useEffect, useRef } from 'react';
import { ForgotPasswordDto } from '../dtos';
import { useForgotPassword } from '../hooks';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { useGhostLogin } from '../hooks/use-ghost-login';

const FormComponent: FC<FormikProps<ForgotPasswordDto>> = ({ isSubmitting, handleSubmit, status: rawStatus, setValues, isValid }) => {
  const status: IFormStatus | undefined = rawStatus;
  const { t } = useTranslation('forgot-password');
  const captchaRef = useRef<any>(null);
  const captchaKey = import.meta.env.VITE_CAPTCHA_SITE_KEY;

  const { hideActions } = useGhostLogin();

  const onCaptchaSuccess = useCallback(
    async (token) => {
      setValues((prevState) => ({
        ...prevState,
        captchaToken: token,
      }));
    },
    [setValues],
  );

  useEffect(() => {
    if (captchaRef.current && status?.type === 'error') {
      captchaRef.current?.resetCaptcha();
    }
  }, [captchaRef, status]);

  if (status?.type === 'success') {
    return (
      <div className='w-full'>
        <Image quality={100} height={97} width={94} src={emailSentImage} wrapperClassName='mb-6' alt='E-Email sent illustration' />
        <Title className='mb-8' level={5} bold>
          {t('success.title')}
        </Title>
        <Text className='mb-8 lg:w-96' size='lg'>
          {t('success.description')}
        </Text>
        <Link href='/sign-in' passHref>
          <ButtonLink size='large' variant='info' outlined>
            {t('success.submit')}
          </ButtonLink>
        </Link>
      </div>
    );
  }

  return (
    <div className='w-full'>
      <div className='mb-4'>
        <Title level={5} bold className='mb-2'>
          {t('title')}
        </Title>
        <Text>{t('description')}</Text>
      </div>
      <form onSubmit={handleSubmit}>
        {status?.type === 'error' && (
          <Alert className='mb-4' variant='danger'>
            {status.message}
          </Alert>
        )}
        <TextField name='email' label={t('fields.email.label')} placeholder={t('fields.email.placeholder') as string} />
        <div className='w-full mt-3'>
          <HCaptcha ref={captchaRef} sitekey={captchaKey} size='normal' theme='dark' onVerify={onCaptchaSuccess} />
        </div>
        <div className='flex mt-4'>
          <Link href='/sign-in' passHref>
            <ButtonLink size='large' variant='info' tint='300' outlined className='flex-1 mr-2 text-center'>
              {t('back')}
            </ButtonLink>
          </Link>
          <Button size='large' loading={isSubmitting} disabled={!isValid || hideActions} type='submit' className='flex-1 ml-2'>
            {t('submit')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export const ForgotPassword = () => {
  const { initialValues, onSubmit, validationSchema } = useForgotPassword();

  return (
    <Formik<ForgotPasswordDto> initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} validateOnMount validateOnChange component={FormComponent} />
  );
};
