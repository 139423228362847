import HCaptcha from '@hcaptcha/react-hcaptcha';

import { Formik, FormikProps } from 'formik';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import emailSentImage from '@root/assets/images/email-sent-2.webp';
import { SignUpDto } from '@root/shared-files/modules/auth/dtos';
import { useSignInWithGoogle, useSignUp } from '@root/shared-files/modules/auth/hooks';
import { countriesSelector } from '@root/modules/countries/store';
import { bottomLinks } from '@root/shared-files/modules/subscriptions/constants/bottom-links';
import { IFormStatus, PasswordField, SelectField, TextField } from '@root/shared/form';
import { AppleIcon } from '@root/shared/icons/apple-icon';
import { GoogleIcon } from '@root/shared/icons/google-icon';
import { Alert } from '@root/shared/ui/alert';
import { Button } from '@root/shared/ui/button';
import Link from '@root/shared/ui/common/static-locales-link';
import { Image } from '@root/shared/ui/image';
import { ButtonLink } from '@root/shared/ui/link';
import { Text, Title } from '@root/shared/ui/typography';
import { SignInVericationModal } from '../components/two-fa/sign-in-verification-modal';
import { TwoFAResponse } from '../types/two-fa';

const FormComponent: FC<FormikProps<SignUpDto>> = ({ status: rawStatus, isSubmitting, handleSubmit, isValid, errors, touched, setValues }) => {
  const [twoFaResponse, setTwoFaResponse] = useState<TwoFAResponse | null>(null);
  const captchaRef = useRef<any>(null);
  const { t } = useTranslation('sign-up');
  const googleSignIn = useSignInWithGoogle(setTwoFaResponse);
  const countries = useSelector(countriesSelector.main);
  const status: IFormStatus | undefined = rawStatus;
  const hasError = status?.type === 'error' || (!!touched.captchaToken && !!errors.captchaToken);
  const error = status?.message || 'Captcha not passed';
  const captchaKey = import.meta.env.VITE_CAPTCHA_SITE_KEY;

  const countryOptions = useMemo(
    () =>
      countries.data.map((country) => ({
        value: country.code,
        label: country.name,
      })),
    [countries],
  );

  const onCaptchaSuccess = useCallback(
    async (token) => {
      setValues((prevState) => ({
        ...prevState,
        captchaToken: token,
      }));
    },
    [setValues],
  );

  useEffect(() => {
    if (captchaRef.current && status?.type === 'error') {
      captchaRef.current?.resetCaptcha();
    }
  }, [captchaRef, status]);

  if (status?.type === 'success') {
    return (
      <div className='w-full'>
        <Image quality={100} height={97} width={94} src={emailSentImage} wrapperClassName='mb-6' alt='E-Email sent illustration' />
        <Title className='mb-8' level={5} bold>
          {t('success.title')}
        </Title>
        <Text className='mb-8 lg:w-96' size='lg'>
          {t('success.description')}
        </Text>
        <Link href='/sign-in' passHref>
          <ButtonLink variant='info' tint='300' outlined>
            {t('success.submit')}
          </ButtonLink>
        </Link>
      </div>
    );
  }

  return (
    <div className='w-full'>
      <div className='flex justify-between items-center mb-4'>
        <Title level={5} bold>
          {t('title')}
        </Title>
        <Link href='/sign-in'>
          <a className='text-primary-400 text-xl font-bold hover:underline hover:text-gray-100'>{t('login')}</a>
        </Link>
      </div>
      <div className='flex mb-4'>
        <Button prefix={<AppleIcon />} variant='light' className='flex-1 mr-2' outlined disabled>
          Apple ID
        </Button>

        <Button
          loading={googleSignIn.isLoading}
          disabled={!googleSignIn.isReady}
          onClick={googleSignIn.signIn}
          prefix={<GoogleIcon />}
          variant='light'
          className='flex-1 ml-2'
          outlined
        >
          Google
        </Button>
      </div>
      <form onSubmit={handleSubmit}>
        {hasError && (
          <Alert className='mb-4' variant='danger'>
            {error}
          </Alert>
        )}
        <div className='w-full flex flex-col gap-y-5'>
          <TextField name='fullName' label={t('fields.fullName.label')} placeholder={t('fields.fullName.placeholder') as string} />
          <SelectField name='countryCode' label={t('fields.country.label')} placeholder={t('fields.country.placeholder') as string} options={countryOptions} />
          <TextField name='email' label={t('fields.email.label')} placeholder={t('fields.email.placeholder') as string} />
          <PasswordField name='password' autoComplete='new-password' label={t('fields.password.label')} placeholder={t('fields.password.placeholder') as string} />
          <PasswordField name='confirmPassword' label={t('fields.confirmPassword.label')} placeholder={t('fields.confirmPassword.placeholder') as string} />
          {/* <TextField name='accessCode' label={t('fields.accessCode.label')} placeholder={t('fields.accessCode.placeholder')} autoComplete='off' /> */}
        </div>
        <div className='w-full my-5'>
          <HCaptcha ref={captchaRef} sitekey={captchaKey} size='normal' theme='dark' onVerify={onCaptchaSuccess} />
        </div>
        <Button size='large' loading={isSubmitting} disabled={!isValid} type='submit' className='w-full'>
          {t('submit')}
        </Button>
        <div className='px-16 mt-4'>
          <Text size='sm' className='text-center'>
            <Trans
              i18nKey='sign-up:terms'
              components={[
                <a
                  key='0'
                  className='text-primary-400 cursor-pointer hover:underline hover:text-gray-100'
                  href={bottomLinks.termsOfUse}
                  rel='noreferrer noopener'
                  target='_blank'
                />,
                <a
                  key='1'
                  className='text-primary-400 cursor-pointer hover:underline hover:text-gray-100'
                  href={bottomLinks.privacyPolicy}
                  rel='noreferrer noopener'
                  target='_blank'
                />,
                <a
                  key='2'
                  className='text-primary-400 cursor-pointer hover:underline hover:text-gray-100'
                  href={bottomLinks.refundPolicy}
                  rel='noreferrer noopener'
                  target='_blank'
                />,
                <a
                  key='3'
                  className='text-primary-400 cursor-pointer hover:underline hover:text-gray-100'
                  href={bottomLinks.acceptableUsePolicy}
                  rel='noreferrer noopener'
                  target='_blank'
                />,
                <a
                  key='4'
                  className='text-primary-400 cursor-pointer hover:underline hover:text-gray-100'
                  href={bottomLinks.cookiesPolicy}
                  rel='noreferrer noopener'
                  target='_blank'
                />,
                <a
                  key='5'
                  className='text-primary-400 cursor-pointer hover:underline hover:text-gray-100'
                  href={bottomLinks.riskWarning}
                  rel='noreferrer noopener'
                  target='_blank'
                />,
                <a
                  key='6'
                  className='text-primary-400 cursor-pointer hover:underline hover:text-gray-100'
                  href={bottomLinks.disclaimers}
                  rel='noreferrer noopener'
                  target='_blank'
                />,
              ]}
            />
          </Text>
        </div>
      </form>
      {/* <AccessCodeModal isOpen={googleSignIn.isOpen} onClose={googleSignIn.onClose} onCodeSubmit={googleSignIn.onCodeSubmit} /> */}
      {twoFaResponse && <SignInVericationModal isOpen twoFaResponse={twoFaResponse} onCancel={() => setTwoFaResponse(null)} />}
    </div>
  );
};

export const SignUp = () => {
  const { initialValues, onSubmit, validationSchema } = useSignUp();

  return <Formik<SignUpDto> initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} isInitialValid={false} component={FormComponent} />;
};
