import { IFormStatus } from '@root/shared/form';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SignInDto } from '../dtos';
import { signInService } from '../services';
import { authSlice } from '../store';
import { createSignInValidation } from '../validations';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IAuth } from '../types';
import { TwoFAResponse } from '../types/two-fa';
import { deleteCookie, setCookie } from '@root/shared/utils/cookies';

export const useSignIn = () => {
  const [twoFaResponse, setTwoFaResponse] = useState<TwoFAResponse | null>(null);
  const dispatch = useDispatch();
  const { t } = useTranslation('auth-validation');
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const initialValues = useMemo<SignInDto>(
    () => ({
      email: '',
      password: '',
      captchaToken: '',
    }),
    [],
  );

  const schema = useMemo(() => createSignInValidation(t), []);

  const onSubmit = useCallback(
    async (values: SignInDto, helpers: FormikHelpers<SignInDto>) => {
      helpers.setStatus(undefined);

      const result = await signInService({
        ...values,
        email: values.email.trim().toLowerCase(),
        password: values.password.trim(),
      });

      if (result.status === 200) {
        if (result.payload.isTwoFactorEnabled) {
          setTwoFaResponse(result.payload.twoFactorResponse as TwoFAResponse);
          if (result.payload.twoFactorResponse?.accessToken) {
            setCookie(
              'twoFaAccessToken',
              result.payload.twoFactorResponse.accessToken,
              Date.now() + 24 * 60 * 60 * 1000 * 200,
            );
            localStorage.setItem('twoFaAccessToken', result.payload.twoFactorResponse.accessToken);
          }
        } else {
          localStorage.removeItem('twoFaAccessToken');
          deleteCookie('twoFaAccessToken');
          const session = result.payload.session as IAuth;
          dispatch(authSlice.actions.signedIn(result.payload.session as IAuth));
          helpers.setStatus({ type: 'success', message: null } as IFormStatus);

          const next = decodeURIComponent(searchParams.get('next') || '');
          if (session.userSubscriptionInfo.roles?.length) {
            if (next && next !== '/') {
              navigate(next);
            } else {
              navigate('/');
            }
          } else {
            navigate(`/subscriptions`);
          }
        }
      } else {
        helpers.setStatus({ type: 'error', message: result.payload } as IFormStatus);
      }
    },
    [searchParams, dispatch, navigate],
  );

  return { initialValues, onSubmit, validationSchema: schema, twoFaResponse, setTwoFaResponse };
};
