export const INSTAGRAM_LINK = 'https://www.instagram.com/sagemaster_/';
export const YOUTUBE_LINK = 'https://www.youtube.com/channel/UC7zOZH8XXXzCk4eFjwHJeew';
export const TELEGRAM_LINK = 'https://t.me/sagemasterofficial';
export const KNOWLEDGEBASE_LINK = 'https://sagemaster.notion.site/Knowledge-base-4ae6122fc4ba4d0a8c41c28e532cada8';

export const helpLinks = {
  submitFeature: 'http://voice.sagemaster.io/',
  roadmap: 'https://voice.sagemaster.io/roadmap',
  knowbase: KNOWLEDGEBASE_LINK,
  link: 'https://www.notion.so/sagemaster/Knowledge-base-4ae6122fc4ba4d0a8c41c28e532cada8',
  telegram: 'https://t.me/sagemasterofficial ',
  youtube: 'https://youtube.com/@sagemaster2801',
};
