import { Subscription as SubscriptionRoles } from '@3lgn/shared/dist/constants/roles';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { authSelector } from '@root/shared-files/modules/auth/store';
import { CrownIcon } from '@root/shared/icons/crown-icon';
import { GiftIcon } from '@root/shared/icons/gift-icon';
import { LightBulbIcon } from '@root/shared/icons/light-bulb';

export const useSubscriptionTitles = () => {
  const { t } = useTranslation('common');
  const user = useSelector(authSelector.user);
  const subscriptionInfo = useSelector(authSelector.getSubscriptionInfo);

  const title = useMemo(() => user?.fullName as string, [user]);

  const { subTitle, icon, className } = useMemo(() => {
    const roles = subscriptionInfo?.roles || [];
    if (roles.includes(SubscriptionRoles.Pro) || roles.includes(SubscriptionRoles.FxPro)) {
      return {
        subTitle: t('userInfo.pro'),
        icon: CrownIcon,
        className: 'text-purple-300 font-medium',
      };
    }

    if (roles.includes(SubscriptionRoles.Advanced) || roles.includes(SubscriptionRoles.FxAdvanced)) {
      return {
        subTitle: t('userInfo.advance'),
        icon: CrownIcon,
        className: 'text-success-400 font-medium',
      };
    }

    if (roles.includes(SubscriptionRoles.Basic) || roles.includes(SubscriptionRoles.FxBasic)) {
      return {
        subTitle: t('userInfo.basic'),
        icon: CrownIcon,
        className: 'text-primary-400 font-medium',
      };
    }

    if (roles.includes(SubscriptionRoles.Trial)) {
      return {
        subTitle: t('userInfo.trial'),
        icon: GiftIcon,
        className: 'text-primary-100 font-medium',
      };
    }

    if (roles.includes(SubscriptionRoles.CryptoStarter)) {
      return {
        subTitle: t('userInfo.lite'),
        icon: LightBulbIcon,
        className: 'text-gray-400 font-medium',
      };
    }

    return {
      subTitle: t('userInfo.starter'),
      icon: null,
      className: 'text-grayscale-500',
    };
  }, [subscriptionInfo]);

  return {
    title,
    subTitle,
    icon,
    className,
  };
};
