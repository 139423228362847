import { useSelector } from 'react-redux';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { Navigate, useSearchParams } from 'react-router-dom';
import React from 'react';

const ProtectedAuthRoute = ({ children }) => {
  const { user } = useSelector(authSelector.main);
  const authed = !!user;

  const [queryParams] = useSearchParams();
  const ghostToken = queryParams?.get('ghostToken');

  if (authed && !ghostToken) {
    return <Navigate to='/' replace />;
  }

  return children;
};

export default ProtectedAuthRoute;
