import { AxiosError } from 'axios';
import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { TwoFAAuthType } from '../types/two-fa';
import { TwoFaErrorResponse } from '../types/two-fa-field-error';

export type UpdatePasswordServiceResponse =
  | IHttpResponse<200, string>
  | IHttpResponse<400, string>
  | IHttpResponse<418, TwoFaErrorResponse>;

type UpdatePasswordDto = {
  password: string;
  oldPassword: string;
  twoFactorCode?: {
    type: TwoFAAuthType;
    code: string;
  };
};

export const updatePassword = async (data: UpdatePasswordDto): Promise<UpdatePasswordServiceResponse> => {
  try {
    const response = await fetcher.patch('/auth/profile/password', data);

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      if (typeof error.response.data.message === 'object') {
        return {
          status: 418,
          payload: error.response.data.message,
        };
      }

      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
