import { useCallback, useEffect, useMemo, useState } from 'react';
import { sendCode } from '../services/two-fa/send-code.service';
import { connectEmail } from '../services/two-fa/connect-email.service';
import { sendCodeSignIn } from '../services/two-fa/send-code-sign-in.service';
import { useInterval } from '@chakra-ui/hooks';
import { notify } from '@root/shared/utils/notification';

const RESEND_PERIOD = 2 * 60;

export const useResendCode = (formName: string, email = '', isSignIn = false) => {
  const [resendLoading, setResendLoading] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);

  const restTime = useMemo(() => {
    const time = RESEND_PERIOD - timer;
    const mins = Math.floor(time / 60);
    const seconds = time % 60;
    return `0${mins}:${seconds < 10 ? `0${seconds}` : seconds}`;
  }, [timer]);

  const key = `${formName}.lastCodeResend`;

  const sendEmailCode = useCallback(async () => {
    if (isSignIn) {
      return await sendCodeSignIn();
    }

    return email ? await connectEmail(email) : await sendCode();
  }, [email, isSignIn]);

  const resendCode = useCallback(async () => {
    setResendLoading(true);
    const response = await sendEmailCode();
    if (response.status === 200) {
      localStorage.setItem(key, new Date().toISOString());
    } else {
      notify({
        type: 'danger',
        title: response.payload,
      });
    }
    setResendLoading(false);
  }, [key, sendEmailCode]);

  const checkTimer = useCallback(() => {
    const lastRecent = localStorage.getItem(key);
    if (lastRecent) {
      const diff = Math.floor((new Date().getTime() - new Date(lastRecent).getTime()) / 1000);
      if (diff >= RESEND_PERIOD) {
        localStorage.removeItem(key);
        setTimer(0);
      } else {
        setTimer(diff);
      }
    } else {
      setTimer(0);
    }
  }, [key]);

  useEffect(() => {
    checkTimer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(() => {
    checkTimer();
  }, 1000);

  return {
    restTime,
    resendLoading,
    ableToResend: !timer,
    sendEmailCode,
    resendCode,
  };
};
