import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type IUserSettingsState = {
  feedbackOpened: boolean;
  menuOpened: boolean;
};

export namespace UserSettingsActions {
  export type SetFeedbackModal = PayloadAction<boolean>;
  export type SetMenuOpened = PayloadAction<boolean>;
}

export type ExchangesCaseReducer = {
  setFeedbackModal: CaseReducer<IUserSettingsState, UserSettingsActions.SetFeedbackModal>;
  setMenuOpened: CaseReducer<IUserSettingsState, UserSettingsActions.SetMenuOpened>;
};

const initialState: IUserSettingsState = {
  feedbackOpened: false,
  menuOpened: false,
};

export const userSettingsSlice = createSlice<IUserSettingsState, ExchangesCaseReducer, 'user-settings'>({
  name: 'user-settings',
  initialState,
  reducers: {
    setFeedbackModal: (state, action) => {
      state.feedbackOpened = action.payload;
    },
    setMenuOpened: (state, action) => {
      state.menuOpened = action.payload;
    },
  },
});
