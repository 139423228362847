import Link, { LinkProps } from '@root/shared/ui/common/static-locales-link';
import React, { Children, FC, PropsWithChildren, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const ActiveLink: FC<PropsWithChildren<LinkProps> & { activeClassName?: string; exact?: boolean }> = ({ children, exact, ...props }) => {
  const location = useLocation();

  const child: any = Children.only(children);
  const href = props.href;

  const isMatching = useMemo(() => {
    return exact ? location.pathname === href || location.pathname === href.slice(0, href.length - 1) : location.pathname.startsWith(href);
  }, [href, exact, location.pathname]);

  return (
    <Link {...props}>
      {React.cloneElement(child, {
        isActive: isMatching,
      })}
    </Link>
  );
};
