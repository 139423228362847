export enum TwoFAAuthType {
  Authenticator = 'authenticator',
  Email = 'email',
  Backup = 'backup',
}

export type TwoFAMethodData = {
  id: string;
  userId: string;
  type: TwoFAAuthType;
  secret: string;
  isActivated: boolean;
  isEnabled: boolean;
  isDefault: boolean;
  email: string;
  createdAt: string;
  updatedAt: string;
};

export type EnterCodeWithBackupForm = {
  type: TwoFAAuthType;
  code: string;
  useBackupCode: boolean;
  backupCode: string;
};

export type TwoFAResponse = {
  accessToken: string;
  activeMethods: {
    type: TwoFAAuthType;
    isDefault: boolean;
    email?: string;
  }[];
};

export enum ResetTwoFaFormSteps {
  WarningStep = 'warningStep',
  AccountInfo = 'accountInfo',
  ActivityQuestions = 'activityQuestions',
  SecurityVerification = 'SecurityVerification',
  SupportRequest = 'supportRequest',
  Success = 'success',
  SupportSuccess = 'supportSuccess',
}

export type ResetTwoFaForm = {
  id: string;
  step: ResetTwoFaFormSteps;
  accountInfo: {
    registrationEmail: string;
    fullName: string;
    country: string;
    reason: string;
    reasonComment: string;
  };
  activityQuestions: {
    hasCryptoActivities: boolean;
    orders: {
      id: string;
      average: number;
      datetime: string;
      exchangeType: string;
      pair: string;
      amount: string;
      closeTime: number | null;
    }[];
    hasForexActivities: boolean;
    tickets: string[];
  };
  supportRequest: {
    step: number;
    contactEmail: string;
    comment: string;
    identificationDocument: string;
    identificationDocumentUrl: string;
    registrationDate: Date | null;
  };
  confirmationCode: string;
};

export type ResetTwoRequestInfo = {
  userId: string;
  registrationEmail: string;
  fullName: string;
  country: string;
  reason: string;
  reasonComment: string;
  hasCryptoActivities: boolean;
  initialCryptoOrders: [
    {
      id: string;
      externalId: string;
      userId: string;
      closeTime: number | null;
      datetime: string;
      average: number;
      exchange: string;
      symbol: string;
    },
  ];
  userEnteredCryptoOrders: [
    {
      id: string;
      amount: number;
    },
  ];
  hasForexActivities: boolean;
  userEnteredForexTickets: string[];
  id: string;
  status: string;
  createdAt: string;
  updatedAt: string;
};
