import { useChatStore } from '@root/modules/ai-chat/store';
import { useEffect } from 'react';

export function useHotKey() {
  const chatStore = useChatStore();

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.altKey || e.ctrlKey) {
        if (e.key === 'ArrowUp') {
          chatStore.nextSession(-1);
        } else if (e.key === 'ArrowDown') {
          chatStore.nextSession(1);
        }
      }
    };

    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  });
}
