import clsx from 'clsx';
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export interface FieldHintProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  variant?: 'default' | 'danger' | 'success';
}

export const FieldHint: FC<FieldHintProps> = ({ className, variant = 'default', ...props }) => {
  return (
    <div
      className={clsx(
        'block text-right text-sm',
        {
          'text-success-500': variant === 'success',
          'text-danger-500': variant === 'danger',
        },
        className,
      )}
      {...props}
    />
  );
};
