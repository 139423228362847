import { StoreKey } from '../constant';
import { createPersistStore } from '../utils/store';

const DEFAULT_ACCESS_STATE = {
  token: '',
  accessCode: '',
  needCode: true,
  hideUserApiKey: false,
  hideBalanceQuery: false,
  disableGPT4: false,
};

export const useAccessStore = createPersistStore(
  { ...DEFAULT_ACCESS_STATE },

  (set, get) => ({
    updateCode(code: string) {
      set(() => ({ accessCode: code?.trim() }));
    },

    fetch() {
      // if (fetchState > 0 || getClientConfig()?.buildMode === "export") return;
      // fetchState = 1;
      // fetch("/api/config", {
      //   method: "post",
      //   body: null,
      //   headers: {
      //     ...getHeaders(),
      //   },
      // })
      //   .then((res) => res.json())
      //   .then((res: DangerConfig) => {
      //     console.log("[Config] got config from server", res);
      //     set(() => ({ ...res }));
      //
      //     if (res.disableGPT4) {
      //       DEFAULT_MODELS.forEach(
      //         (m: any) => (m.available = !m.name.startsWith("gpt-4")),
      //       );
      //     }
      //   })
      //   .catch(() => {
      //     console.error("[Config] failed to fetch config");
      //   })
      //   .finally(() => {
      //     fetchState = 2;
      //   });
    },
  }),
  {
    name: StoreKey.Access,
    version: 1,
  },
);
