import { useCallback, useMemo } from 'react';
import { TwoFAAuthType } from '../types/two-fa';
import { verifyByAuthenticator } from '../services/two-fa/verify-by-authenticator.service';
import { notify } from '@root/shared/utils/notification';
import { queryClient } from '@root/infra/query';
import { useTranslation } from 'react-i18next';
import { connectTwoFaValidation } from '../validations/two-fa.validation';
import { verifyByEmail } from '../services/two-fa/verify-by-email.service';
import { handleTwoFaErrors } from '../helpers/handle-two-fa-errors';
import { FormikHelpers } from 'formik';

export type ConnectTwoFaForm = {
  type: TwoFAAuthType;
  code: string;
  email: string;
};

type Props = {
  type: TwoFAAuthType;
  setBackupCodes: (codes: string[]) => void;
  closeModal: () => void;
};

export const useConnectTwoFaForm = ({ type, setBackupCodes, closeModal }: Props) => {
  const { t } = useTranslation('two-fa');

  const initialValues = useMemo<ConnectTwoFaForm>(() => {
    return {
      type,
      code: '',
      email: '',
    };
  }, [type]);

  const schema = useMemo(() => connectTwoFaValidation(t), [t]);

  const onSubmit = useCallback(
    async (values: ConnectTwoFaForm, helpers: FormikHelpers<ConnectTwoFaForm>) => {
      const { type, code } = values;

      if (type === TwoFAAuthType.Authenticator) {
        const response = await verifyByAuthenticator(code);
        if (response.status === 200) {
          setBackupCodes(response.payload.backupCodes);
          queryClient.invalidateQueries(['two-fa-methods']);
          closeModal();
        } else {
          if (response.status === 418) {
            handleTwoFaErrors(response, values as any, helpers as any);
          } else {
            notify({
              type: 'danger',
              title: response.payload,
            });
          }
        }
      } else {
        const response = await verifyByEmail(code);
        if (response.status === 200) {
          setBackupCodes(response.payload.backupCodes);
          queryClient.invalidateQueries(['two-fa-methods']);
          closeModal();
        } else {
          if (response.status === 418) {
            handleTwoFaErrors(response, values as any, helpers as any);
          } else {
            notify({
              type: 'danger',
              title: response.payload,
            });
          }
        }
      }
    },
    [closeModal, setBackupCodes],
  );

  return {
    initialValues,
    schema,
    onSubmit,
  };
};
