import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useCheckSubscription = () => {
  const navigate = useNavigate();

  const checkSubscription = useCallback(
    (roles: string[], route = '') => {
      if (roles?.length) {
        navigate(route);
      } else {
        navigate(`/subscriptions`);
      }
    },
    [navigate],
  );

  return { checkSubscription };
};
