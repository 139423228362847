import { AuthLayout } from '@root/shared-files/modules/auth/components';
import { SignIn } from '@root/shared-files/modules/auth/containers';
import { useStartGhostLogin } from '../hooks/use-start-ghost-login';

const SignInPage = () => {
  useStartGhostLogin();

  return (
    <AuthLayout>
      <SignIn />
    </AuthLayout>
  );
};

export default SignInPage;
