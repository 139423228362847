import { fetcher } from '@root/infra/fetcher';
import { IHttpResponse } from '@root/shared/utils/http-service';
import { AxiosError } from 'axios';

export type GetPaymentUrl = IHttpResponse<200, { paymentUrl: string }> | IHttpResponse<400, string>;

export const getPaymentUrl = async (subscriptionId: string): Promise<GetPaymentUrl> => {
  try {
    const response = await fetcher.get(`/misc/subscriptions/${subscriptionId}/payment-url`);

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error?.response?.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
