import { Layout } from '@root/infra/layout/containers';
import { Settings } from '@root/modules/user-settings/containers/settings';

export const SettingsPage = () => {
  return (
    <Layout>
      <Settings />
    </Layout>
  );
};
