import React, { FC } from 'react';
import { CheckSquareIcon } from '@root/shared/icons/check-square';
import { ErrorSquareIcon } from '@root/shared/icons/error-square';
import { QuestionSquareInfo } from '@root/shared/icons/question-square-info';
import { ToastProps } from '@root/shared/utils/notification';
import clsx from 'clsx';
import { ToastWrapper } from './toast.ui';

type Props = {
  toast: ToastProps;
};

export const ToastCmp: FC<Props> = ({ toast }) => {
  if (!toast) {
    return null;
  }

  return (
    <ToastWrapper type={toast.type}>
      <div className='flex items-center no-dark'>
        <div className='w-10 h-10 mr-4 flex-shrink-0'>
          {toast.type === 'info' ? <QuestionSquareInfo /> : null}
          {toast.type === 'danger' ? <ErrorSquareIcon /> : null}
          {toast.type === 'success' ? <CheckSquareIcon /> : null}
        </div>
        <div className='text-gray-100 flex flex-col justify-center'>
          <div className={clsx('font-bold text-ellipsis overflow-hidden', { 'mb-2': !!toast.text })}>{toast.title}</div>
          {toast.text ? typeof toast.text === 'object' ? <div dangerouslySetInnerHTML={{ __html: (toast.text as any).content }} /> : <div>{toast.text}</div> : null}
        </div>
      </div>
    </ToastWrapper>
  );
};
