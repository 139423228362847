import Decimal from 'decimal.js';
import { SubscriptionProductType } from '../types/subscription-product-type';
import { SubscriptionProduct, SubscriptionProductOption } from '../types/subscription-products';

const productsStartWith = ['TRIAL_', 'BASIC_', 'ADVANCED_', 'PRO_'];
const optionsTypes = {
  [SubscriptionProductType.Basic]: 'BASIC_',
  [SubscriptionProductType.Advanced]: 'ADVANCED_',
  [SubscriptionProductType.Pro]: 'PRO_',
  [SubscriptionProductType.Trial]: 'TRIAL_',
};

const getProductType = (options): SubscriptionProductType => {
  if (options[`${productsStartWith[1]}NAME`]) {
    return SubscriptionProductType.Basic;
  }
  if (options[`${productsStartWith[2]}NAME`]) {
    return SubscriptionProductType.Advanced;
  }
  if (options[`${productsStartWith[3]}NAME`]) {
    return SubscriptionProductType.Pro;
  }
  return SubscriptionProductType.Trial;
};

const getOptions = (options): SubscriptionProductOption => {
  const productType = getProductType(options);
  const startWith = optionsTypes[productType];

  return {
    activeSmartTrades: options[`${startWith}ACTIVE_SMART_TRADES`],
    dcaAI: options[`${startWith}DCA_AI`],
    demoAccounts: options[`${startWith}DEMO_ACCOUNTS`],
    gridAi: options[`${startWith}GRID_AI`],
    maxExchanges: options[`${startWith}MAX_EXCHANGES`],
    maxProviders: options[`${startWith}MAX_PROVIDERS`],
    name: options[`${startWith}NAME`],
    portfolioAccess: options[`${startWith}PORTFOLIO_ACCESS`],
    priceMonth: new Decimal(options[`${startWith}PRICE_MONTH`] || 0).div(100).toNumber(),
    priceYear: new Decimal(options[`${startWith}PRICE_YEAR`] || 0).div(100).toNumber(),
    productId: options[`${startWith}PRODUCT_ID`],
    smartAi: options[`${startWith}SMART_AI`],
  };
};

export class SubscriptionProductsMapper {
  public static toDomain(data): SubscriptionProduct {
    return {
      createdAt: data.createdAt,
      description: data.description || null,
      id: data.id,
      moduleId: data.moduleId,
      name: data.name,
      options: getOptions(data.options),
      plans: data.plans,
      updatedAt: data.updatedAt,
      isTrialUsed: data.isTrialUsed,
      type: getProductType(data.options),
    };
  }
}
