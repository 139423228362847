import { FormikHelpers } from 'formik';
import { EnterCodeWithBackupForm } from '../types/two-fa';
import { IHttpResponse } from '@root/shared/utils/http-service';
import { TwoFaErrorResponse } from '../types/two-fa-field-error';

type ErrorResponse = IHttpResponse<418, TwoFaErrorResponse>;

export const handleTwoFaErrors = (
  response: ErrorResponse,
  values: EnterCodeWithBackupForm,
  helpers: FormikHelpers<EnterCodeWithBackupForm>,
) => {
  const twaError = response.payload.find((e) => e.property === 'twoFactorCode');
  if (twaError) {
    if (values.useBackupCode) {
      helpers.setStatus({
        apiErrors: {
          backupCode: twaError.errors?.[0],
        },
      });
    } else {
      helpers.setStatus({
        apiErrors: {
          code: twaError.errors?.[0],
        },
      });
    }
  }
};
