import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';
import { HeaderElements as Header } from './header.elements';
import { SidebarElements as Sidebar } from './sidebar.elements';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import clsx from 'clsx';

const SafeArea = styled.div<{ isGhostLogin; extraHeaderHeight; extraMenuWidth?; isDesktop; menuOpened }>`
  padding-left: ${({ isDesktop, extraMenuWidth, menuOpened }) => isDesktop && `${(extraMenuWidth || 0) + menuOpened ? 200 : 64}px`};
  padding-top: ${({ extraHeaderHeight }) => (extraHeaderHeight ? `${74 + extraHeaderHeight || 0}px` : `74px`)};

  nav {
    top: ${({ extraHeaderHeight }) => `${extraHeaderHeight || 0}px`};
  }
`;

const Content: FC<PropsWithChildren> = ({ children }) => {
  const { isGhostLogin } = useGhostLogin();

  return <main className={clsx({ 'mt-[60px]': isGhostLogin })}>{children}</main>;
};

export const LayoutElements = { SafeArea, Content, Sidebar, Header };
