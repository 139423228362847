import { TwoFAAuthType, TwoFAMethodData } from '../types/two-fa';

export const getActiveMethod = (twoMethods: TwoFAMethodData[]) => {
  const activeMethods = twoMethods?.filter((method) => method.isEnabled && method.isActivated);
  const method = activeMethods?.find((method) => method.isDefault) || activeMethods?.[0];
  const email = activeMethods?.find((method) => method.type === TwoFAAuthType.Email)?.email;

  return {
    method,
    activeMethods,
    email,
  };
};
