import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useGetTwoFaMethods } from '../../hooks/use-get-two-fa-methods';
import { useLocalStorage } from '@root/shared/hooks/useLocalStorage';
import { Modal } from '@root/shared/ui/modal';
import { Button } from '@root/shared/ui/button';
import { Title, Text } from '@root/shared/ui/typography';
import { Image } from '@root/shared/ui/image';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { authSelector } from '../../store';

import { ENABLE_TWO_FA_MODAL_SHOWN } from '@root/shared-files/modules/shared/constants/local-storage-keys';
import securedProfileImg from '@root/assets/images/secured-profile.webp';
import { ShieldIcon } from '@root/shared/icons/shield-icon';

export const CheckTwoFaBadge: FC = () => {
    const { t } = useTranslation('two-fa');
    const user = useSelector(authSelector.user);
    const { data: methods, isLoading } = useGetTwoFaMethods();
    const hasActiveMethods = useMemo(() => !!methods?.filter((method) => method.isActivated && method.isEnabled)?.length, [methods]);
    const navigate = useNavigate();

    if (isLoading || hasActiveMethods || !user) {
        return null;
    }

    return (
        <div className='flex items-center gap-0.5 p-0.5 cursor-pointer rounded border border-primary-400 text-primary-400 hover:text-gray-100 hover:border-gray-100' onClick={() => navigate('/settings')}>
            <ShieldIcon width={12} height={12} />
            <Text size='xs' className='font-medium whitespace-nowrap'>
                {t('enableTwoFa.enableTwoFa')}
            </Text>
        </div>
    );
};


export const CheckTwoFaEnabledModal: FC = () => {
    const { t } = useTranslation('two-fa');
    const [modalShown, setModalShown] = useLocalStorage<boolean>(ENABLE_TWO_FA_MODAL_SHOWN, false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const user = useSelector(authSelector.user);
    const { data: methods, isLoading } = useGetTwoFaMethods();
    const navigate = useNavigate();

    const checkTwoFaEnabled = useCallback(() => {
        if (!isLoading && !methods?.length && !modalShown && !!user) {
            setIsOpen(true);
            setModalShown(true);
        }
    }, [user, isLoading, methods, modalShown, setModalShown]);

    const onCancel = useCallback(() => {
        setIsOpen(false);
        setModalShown(true);
    }, [setModalShown]);

    const onOk = useCallback(() => {
        setIsOpen(false);
        setModalShown(true);
        navigate('/settings');
    }, [navigate, setModalShown]);

    useEffect(() => {
        checkTwoFaEnabled();
    }, [checkTwoFaEnabled]);

    if (!isOpen) {
        return null;
    }

    return (
        <Modal isOpen footer={null} onCancel={onCancel} disabledOutSideClick>
            <div className='flex flex-col items-center gap-8'>
                <Image src={securedProfileImg} width={100} height={100} alt='secured profile' />
                <div>
                    <Title level={5} className='font-bold text-center'>
                        {t('enableTwoFa.title')}
                    </Title>
                    <Text size='sm' className='text-center font-semibold mt-2'>
                        {t('enableTwoFa.description')}
                    </Text>
                    <Text size='sm' className='text-center mt-4'>
                        {t('enableTwoFa.alwaysText')}
                    </Text>
                </div>
                <div className='w-full flex justify-between items-center'>
                    <Button onClick={onCancel} outlined>
                        {t('enableTwoFa.later')}
                    </Button>
                    <Button onClick={onOk}>
                        {t('enableTwoFa.enableTwoFaNow')}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

