import { FC, Fragment, memo, useMemo, useState } from 'react';
import { Modal, ModalProps } from '@root/shared/ui/modal';
import { useTranslation } from 'react-i18next';
import { Button } from '@root/shared/ui/button/button';
import { Image } from '@root/shared/ui/image';
import { Title, Text } from '@root/shared/ui/typography';
import { Formik, FormikProps } from 'formik';
import { TwoFAAuthType, TwoFAMethodData } from '../../types/two-fa';
import { useGetTwoFaMethods } from '../../hooks/use-get-two-fa-methods';
import { RemoveMethodForm, useRemoveMethodForm } from '../../hooks/use-remove-method-form';
import { EnterCodeContent } from './enter-code-content';
import { BackupCodeContent } from './backup-code-content';
import isEqual from 'lodash/isEqual';

import warningImg from '@root/assets/images/cases/warning.webp';

type Props = ModalProps & {
    removingId: string;
}

type RemoveMethodFormProps = FormikProps<RemoveMethodForm> & {
    method?: TwoFAMethodData;
    onCancel?: () => void;
};

const RemoveMethodFormComponent: FC<RemoveMethodFormProps> = memo(({ method, values, isSubmitting, onCancel, handleSubmit }) => {
    const { t } = useTranslation('two-fa');
    const [step, setStep] = useState<number>(1);

    return <Modal isOpen title={step === 1 ? '' : values.useBackupCode ? t('enterBackupCode.title') : t('removeModal.title')} footer={null} onCancel={onCancel} disabledOutSideClick>
        {step === 1 && (
            <div className='flex flex-col items-center gap-8'>
                <div className='w-16 h-16'>
                    <Image src={warningImg} width={220} height={220} objectFit='contain' alt='warning' unoptimized={true} />
                </div>
                <div>
                    <Title level={5} className='font-bold text-center mb-2'>
                        {t('removeModal.confirmation.title')}
                    </Title>
                    <Text size='base' className='text-center'>
                        {t('removeModal.confirmation.description')}
                    </Text>
                </div>
                <div className='w-full flex justify-between items-center'>
                    <Button onClick={onCancel} variant='info' outlined>
                        {t('removeModal.confirmation.actions.cancel')}
                    </Button>
                    <Button onClick={() => setStep(2)}>
                        {t('removeModal.confirmation.actions.removeFa')}
                    </Button>
                </div>
            </div>
        )}
        {step === 2 && (
            <form onSubmit={handleSubmit}>
                {
                    values.useBackupCode ? <BackupCodeContent type={method?.type as TwoFAAuthType} /> : <Fragment>
                        <EnterCodeContent type={method?.type as TwoFAAuthType} email={method?.email || ''} formName='RemoveMethodForm' />
                        <Button loading={isSubmitting} type='submit' className='w-full mt-6'>{t('removeModal.confirm')}</Button>
                    </Fragment>
                }
            </form>
        )}
    </Modal>;
}, (prevProps, nextProps) => isEqual(prevProps, nextProps));

RemoveMethodFormComponent.displayName = 'RemoveMethodFormComponent';

export const RemoveTwoFaMethodModal: FC<Props> = ({ onCancel = () => { }, removingId }) => {
    const { data: methods } = useGetTwoFaMethods();
    const method = useMemo(() => methods?.find((m) => m.id === removingId), [methods, removingId]);
    const { initialValues, schema, onSubmit } = useRemoveMethodForm(method, onCancel);

    return <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
        component={(props) => <RemoveMethodFormComponent {...props} method={method} onCancel={onCancel} />}
        enableReinitialize
    />
}





