import { QueryClient } from 'react-query';

const defaultQueryFn = async () => {
  const { data } = await Promise.resolve({ data: null });
  return data;
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      queryFn: defaultQueryFn,
    },
  },
});
