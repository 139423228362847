import { ForgotPasswordDto } from '@root/shared-files/modules/auth/dtos';
import { AuthModuleConfig } from '../module.config';
export class ForgotPasswordDtoMapper {
  public static toPersistence(dto: ForgotPasswordDto) {
    return {
      email: dto.email,
      redirectPath: '/reset-password',
      captchaToken: dto.captchaToken,
      sourceApp: AuthModuleConfig.sourceApp,
    };
  }
}
