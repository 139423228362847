import { baseIconProps } from '@root/shared/icons/base-icon-props';
import { FC, SVGProps } from 'react';


export const LikeIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
    return <svg xmlns="http://www.w3.org/2000/svg"
        {...baseIconProps}
        viewBox="0 0 50 50"
        {...props}
    >
        <path fill="currentColor" d="M 23 3 C 22.449219 3 22 3.449219 22 4 L 22 11.75 L 17 21.75 L 17 20 C 17 19.449219 16.550781 19 16 19 L 3 19 C 2.714844 19 2.441406 19.128906 2.25 19.34375 C 2.058594 19.558594 1.964844 19.839844 2 20.125 L 5 44.125 C 5.0625 44.625 5.496094 45 6 45 L 16 45 C 16.550781 45 17 44.554688 17 44 L 17 43 L 18.15625 43 C 18.570313 44.164063 19.695313 45 21 45 L 40 45 C 42.207031 45 44 43.207031 44 41 C 44 40.175781 43.738281 39.417969 43.3125 38.78125 C 44.871094 38.234375 46 36.742188 46 35 C 46 34.019531 45.648438 33.132813 45.0625 32.4375 C 46.222656 31.738281 47 30.453125 47 29 C 47 28.019531 46.648438 27.132813 46.0625 26.4375 C 47.222656 25.738281 48 24.453125 48 23 C 48 20.792969 46.207031 19 44 19 L 29.375 19 C 29.984375 17.070313 31 13.617188 31 12 C 31 8.234375 28.242188 3 25.53125 3 Z M 24 5 L 25.53125 5 C 26.449219 5 29 8.480469 29 12 C 29 13.566406 27.601563 18.082031 27.0625 19.6875 C 26.957031 19.992188 27 20.332031 27.1875 20.59375 C 27.375 20.855469 27.679688 21 28 21 L 44 21 C 45.101563 21 46 21.898438 46 23 C 46 24.101563 45.101563 25 44 25 L 43 25 C 42.445313 25 42 25.445313 42 26 C 42 26.554688 42.445313 27 43 27 C 44.101563 27 45 27.898438 45 29 C 45 30.101563 44.101563 31 43 31 L 42 31 C 41.445313 31 41 31.445313 41 32 C 41 32.554688 41.445313 33 42 33 C 43.101563 33 44 33.898438 44 35 C 44 36.101563 43.101563 37 42 37 L 40 37 C 39.445313 37 39 37.445313 39 38 C 39 38.554688 39.445313 39 40 39 C 41.101563 39 42 39.898438 42 41 C 42 42.101563 41.101563 43 40 43 L 21 43 C 20.449219 43 20 42.550781 20 42 C 20 41.445313 19.550781 41 19 41 L 17 41 L 17 24 L 17.5 24 C 17.878906 24 18.234375 23.777344 18.40625 23.4375 L 23.90625 12.4375 C 23.976563 12.300781 24 12.15625 24 12 Z M 4.125 21 L 15 21 L 15 43 L 6.875 43 Z M 11.5 38 C 10.671875 38 10 38.671875 10 39.5 C 10 40.328125 10.671875 41 11.5 41 C 12.328125 41 13 40.328125 13 39.5 C 13 38.671875 12.328125 38 11.5 38 Z" />
    </svg>
};

