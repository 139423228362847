import styled from 'styled-components';

export const TogglerWrapper = styled.div<{ active: boolean; disabled: boolean }>`
  position: relative;
  border: 2px solid ${({ theme, active }) => (active ? theme.primary[400] : theme.gray[200])};
  box-sizing: border-box;
  border-radius: 40px;
  width: 42px;
  height: 21px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

export const Toggle = styled.div<{ active: boolean; alwaysActive: boolean }>`
  width: 21px;
  height: 21px;
  background: ${({ active, alwaysActive }) => (active || alwaysActive ? 'rgba(80, 187, 255, 0.5)' : 'rgba(191, 191, 191, 0.5)')};
  border: 2px solid ${({ theme, active, alwaysActive }) => (active || alwaysActive ? theme.primary[400] : theme.gray[200])};
  box-sizing: border-box;
  border-radius: 40px;
  position: absolute;
  z-index: 2;
  top: -2px;
  left: ${({ active }) => (active ? '20px' : '-2px')};
  transition: 200ms;
`;
