import { AxiosError } from 'axios';
import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { ResetTwoFaForm, ResetTwoRequestInfo } from '../../types/two-fa';

export type CreateResetTwoFaRequestResponse =
  | IHttpResponse<200, ResetTwoRequestInfo>
  | IHttpResponse<400, string>
  | IHttpResponse<
      418,
      {
        property: string;
        errors: string[];
      }[]
    >;

type ResetTwoFaTradingActivites = {
  cryptoOrders?: {
    id: string;
    amount: number;
  }[];
  forexTickets?: number[];
};

export const updateResetTwoFaTradingActivities = async (
  id: string,
  data: ResetTwoFaTradingActivites,
): Promise<CreateResetTwoFaRequestResponse> => {
  try {
    const response = await fetcher.patch(`/auth/2fa/reset-requests/${id}/trading-activity`, data);

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      if (typeof error.response.data.message === 'string') {
        return {
          status: 400,
          payload: error.response.data.message,
        };
      } else {
        return {
          status: 418,
          payload: error.response.data.message,
        };
      }
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
