import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TradingMode } from '../types/trading-mode';

export type SettingsState = { isBalanceVisible: boolean; tradingMode: TradingMode, canViewBalance: boolean };

export namespace SettingsActions {
  export type SetBalanceVisibility = PayloadAction<boolean>;
  export type SetTradingMode = PayloadAction<TradingMode>;
}

export type SettingsCaseReducer = {
  setBalanceVisibility: CaseReducer<SettingsState, SettingsActions.SetBalanceVisibility>;
  setTradingMode: CaseReducer<SettingsState, SettingsActions.SetTradingMode>;
  setCanViewBalance: CaseReducer<SettingsState, PayloadAction<boolean>>;
};

const isSSR = typeof document === 'undefined';
const isPaperOn = !isSSR && localStorage.getItem('isPaperTradingOn') === 'true';

const initialState: SettingsState = {
  isBalanceVisible: true,
  tradingMode: isPaperOn ? TradingMode.PaperTrading : TradingMode.RealAccount,
  canViewBalance: true,
};

export const settingsSlice = createSlice<SettingsState, SettingsCaseReducer, 'settings'>({
  name: 'settings',
  initialState,
  reducers: {
    setBalanceVisibility: (state, action) => {
      state.isBalanceVisible = action.payload;
    },
    setTradingMode: (state, action) => {
      state.tradingMode = action.payload;
    },
    setCanViewBalance: (state, action) => {
      state.canViewBalance = action.payload;
    },
  },
});
