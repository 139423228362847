export const bottomLinks = {
  termsOfUse: '/legals/terms-of-service',
  privacyPolicy: '/legals/privacy-policy',
  refundPolicy:
    'https://clickwrapagreement.notion.site/Refund-and-Cancellation-Policy-f91984614b0c455f92207dc02c62461a',
  disclaimers: 'https://clickwrapagreement.notion.site/Disclaimers-37096404bef04097bb0174ea095e1c79',
  acceptableUsePolicy:
    'https://www.notion.so/clickwrapagreement/Acceptable-Use-Policy-b35953360b60409591289647b027e794',
  cookiesPolicy: 'https://www.notion.so/clickwrapagreement/Cookies-Policy-8cc96967fe344fb1a54d2126f9d85b20?pvs=4',
  riskWarning: 'https://www.notion.so/clickwrapagreement/Risk-Warning-e0c82c697f024b3e99e4d8fdde7cf026?pvs=4',
};
