import { LoadingIcon } from '@root/shared/icons/loading-icon';
import { ButtonExtraProps, createButtonClassName, StyledButton } from '@root/shared/ui/button';
import clsx from 'clsx';
import { AnchorHTMLAttributes, DetailedHTMLProps, forwardRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export interface ButtonLinkProps extends Omit<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, 'prefix'>, ButtonExtraProps {
  isLocalRoute?: boolean;
}

export const ButtonLink = forwardRef<HTMLAnchorElement, Omit<ButtonLinkProps, 'ref'>>(function ButtonLink(
  { className, outlined, prefix, suffix, size = 'base', variant = 'primary', link, loading, tint = '400', ...props },
  ref,
) {
  const navigate = useNavigate();

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (props.isLocalRoute) {
        e.preventDefault();
        navigate(props?.href as string);
      }

      if (props.onClick) {
        props.onClick(e);
      }
    },
    [props.isLocalRoute, props.href, props.onClick, navigate],
  );

  return (
    <StyledButton
      as='a'
      variant={variant}
      ref={ref}
      link={link}
      outlined={outlined}
      className={clsx(createButtonClassName({ link, outlined, variant, size, loading, tint }), className)}
      onClick={handleClick}
      {...props}
    >
      {!!prefix && <span className='mr-2 -mb-0.5 inline-block'>{prefix}</span>}
      {props.children}
      {!!suffix && <span className='ml-2 -mb-0.5 inline-block'>{suffix}</span>}
      {loading && (
        <span className='text-2xl text-primary-400 absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center text-opacity-100'>
          <LoadingIcon />
        </span>
      )}
    </StyledButton>
  );
});
