import { FC, SVGProps } from 'react';
import { baseIconProps } from './base-icon-props';

export const LogoutIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.8908 5.50043C13.1659 5.25582 13.5873 5.28061 13.8319 5.5558L15.6097 7.5558C15.8342 7.80839 15.8342 8.18902 15.6097 8.44161L13.8319 10.4416C13.5873 10.7168 13.1659 10.7416 12.8908 10.497C12.6156 10.2524 12.5908 9.83098 12.8354 9.5558L14.2195 7.9987L12.8354 6.44161C12.5908 6.16643 12.6156 5.74504 12.8908 5.50043Z'
        fill='#9A9A9A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.667 8.0013C15.667 8.36949 15.3685 8.66797 15.0003 8.66797L6.00033 8.66797C5.63214 8.66797 5.33366 8.36949 5.33366 8.0013C5.33366 7.63311 5.63214 7.33464 6.00033 7.33464L15.0003 7.33464C15.3685 7.33464 15.667 7.63311 15.667 8.0013Z'
        fill='#9A9A9A'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.667 1.33464C10.667 0.966446 10.3685 0.667969 10.0003 0.667969H0.666992C0.298801 0.667969 0.000325203 0.966446 0.000325203 1.33464V14.668C0.000325203 15.0362 0.298801 15.3346 0.666992 15.3346H10.0003C10.3685 15.3346 10.667 15.0362 10.667 14.668V12.668C10.667 12.2998 10.3685 12.0013 10.0003 12.0013C9.63214 12.0013 9.33366 12.2998 9.33366 12.668V14.0013H1.33366V2.0013H9.33366V3.33464C9.33366 3.70283 9.63214 4.0013 10.0003 4.0013C10.3685 4.0013 10.667 3.70283 10.667 3.33464V1.33464Z'
        fill='#9A9A9A'
      />
    </svg>
  );
};
