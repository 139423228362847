import { createSelector, Selector } from '@reduxjs/toolkit';
import { IAppState } from '@root/infra/store';

import { ICountriesState } from './countries.slice';

const getCounties = (state: IAppState) => state.countries;
const getSelf = (countries: ICountriesState) => countries;

export const countriesSelector = {
  main: createSelector<[Selector<IAppState, ICountriesState>], ICountriesState>([getCounties], getSelf),
};
