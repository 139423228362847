import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const AddIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg {...baseIconProps} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.4163 4.58325C10.8766 4.58325 11.2497 4.95635 11.2497 5.41659V13.7499C11.2497 14.2102 10.8766 14.5833 10.4163 14.5833C9.9561 14.5833 9.58301 14.2102 9.58301 13.7499V5.41659C9.58301 4.95635 9.9561 4.58325 10.4163 4.58325Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5.41699 9.58341C5.41699 9.12318 5.79009 8.75008 6.25033 8.75008H14.5837C15.0439 8.75008 15.417 9.12318 15.417 9.58341C15.417 10.0437 15.0439 10.4167 14.5837 10.4167H6.25033C5.79009 10.4167 5.41699 10.0437 5.41699 9.58341Z" fill="currentColor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10.4163 17.5C14.7886 17.5 18.333 13.9556 18.333 9.58333C18.333 5.21108 14.7886 1.66667 10.4163 1.66667C6.04409 1.66667 2.49967 5.21108 2.49967 9.58333C2.49967 13.9556 6.04409 17.5 10.4163 17.5ZM10.4163 19.1667C15.7091 19.1667 19.9997 14.8761 19.9997 9.58333C19.9997 4.2906 15.7091 0 10.4163 0C5.12361 0 0.833008 4.2906 0.833008 9.58333C0.833008 14.8761 5.12361 19.1667 10.4163 19.1667Z" fill="currentColor" />
        </svg>
    )
}

