import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Remote } from '@root/shared/utils/types';

import { ICountry } from '../types';

export type ICountriesState = Remote<ICountry[]>;

export namespace CountriesActions {
  export type FetchPending = PayloadAction;
  export type FetchFulfilled = PayloadAction<ICountry[]>;
  export type FetchRejected = PayloadAction<string>;
}

export type CountriesSliceReducer = {
  fetchPending: CaseReducer<ICountriesState, CountriesActions.FetchPending>;
  fetchFulfilled: CaseReducer<ICountriesState, CountriesActions.FetchFulfilled>;
  fetchRejected: CaseReducer<ICountriesState, CountriesActions.FetchRejected>;
};

export const countriesInitialState: ICountriesState = {
  isLoading: false,
  data: [],
  error: null,
};

export const countriesSlice = createSlice<ICountriesState, CountriesSliceReducer, 'countries'>({
  name: 'countries',
  initialState: countriesInitialState,
  reducers: {
    fetchPending: (state) => {
      state.isLoading = true;
    },
    fetchFulfilled: (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchRejected: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
