import styled from 'styled-components';

import clsx from 'clsx';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { FeedbackContent } from '@root/infra/layout/containers/feedback-tooltip';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { userSettingsSelector } from '@root/modules/user-settings/store/user-settings.selector';
import { userSettingsSlice } from '@root/modules/user-settings/store/user-settings.slice';
import { useOutsideClick } from '@root/shared/hooks/use-outside-click';
import { ArrowIcon } from '@root/shared/icons/arrow-icon';
import { ActiveLink } from '@root/shared/link/active-link';
import { Button } from '@root/shared/ui/button';
import { SvgSpriteIcon } from '@root/shared/ui/svg-sprite-icon';
import { Text } from '@root/shared/ui/typography';

import { LayoutElements } from '../components';

const { HelpItem, Help } = LayoutElements.Sidebar;

const { Wrapper, Group, Item } = LayoutElements.Sidebar;

const SidebarWrapper = styled(Wrapper)`
  font-size: 14px !important;
  box-shadow: 0px 1px 4px 0px rgba(12, 12, 12, 0.83);
`;

const MenuButtonWrapper = styled(Group) <{ expanded: boolean }>`
  width: ${({ expanded }) => (expanded ? '200px' : '64px')};
  z-index: 100;
  background: ${({ theme }) => theme.gray[800]};
`;

const staticMenu = {
    top: [
        {
            title: 'sideBar.dashboard',
            iconId: 'dashboard',
            url: '/',
        },
        {
            title: 'sideBar.subscriptions',
            iconId: 'subscriptions',
            url: '/subscriptions',
        },
    ],
};

export const Sidebar = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('common');
    const menuOpened = useSelector(userSettingsSelector.menuOpened);
    const [isHelpOpen, setHelpOpen] = useState<boolean>(false);
    const ref = useRef<HTMLDivElement>(null);

    const { isGhostLogin } = useGhostLogin();

    useOutsideClick(ref, () => setHelpOpen(false));

    const handleMenuClick = useCallback(() => {
        dispatch(userSettingsSlice.actions.setMenuOpened(!menuOpened));
    }, [dispatch, menuOpened]);

    return (
        <SidebarWrapper>
            <Group className='border-b-2 border-black mt-2'>
                <div className='w-full'>
                    {staticMenu.top.map((item, index) => (
                        <ActiveLink key={index} exact={index === 0} href={item.url}>
                            <Item key={item.title} title={t(item.title)} icon={<SvgSpriteIcon width={20} height={20} id={item.iconId} />} className={clsx({ 'pl-4': menuOpened, 'items-center': !menuOpened })} menuOpened={menuOpened} />
                        </ActiveLink>
                    ))}
                </div>
            </Group>
            <Group className='justify-center py-2 mb-16'>
                <Help
                    className={clsx('flex items-center w-full mx-2 !py-0', {
                        'flex-row-reverse justify-center gap-x-4': menuOpened,
                        'flex-col': !menuOpened,
                    })}
                >
                    <HelpItem className='py-0'>
                        <div className='relative' onClick={() => setHelpOpen((prev) => !prev)} ref={ref}>
                            <div
                                className='flex justify-center items-center gap-x-2 hover:text-primary-400 cursor-pointer'>
                                {menuOpened ? <Text size='sm'>{t('sideBar.help.menuText')}</Text> : null}
                                <SvgSpriteIcon width={20} height={20} id='feedback' />
                            </div>
                            {isHelpOpen && <FeedbackContent />}
                        </div>
                    </HelpItem>
                </Help>
            </Group>
            <MenuButtonWrapper
                className={clsx('py-4 border-t-2 border-black flex flex-col gap-2 fixed bottom-0 left-0 mt-[100%]', { 'left-[3px] bottom-[4px]': isGhostLogin })}
                expanded={menuOpened}
            >
                <div
                    className={clsx('flex justify-start items-center cursor-pointer', {
                        'gap-x-3 justify-start pl-5': menuOpened,
                        'justify-center': !menuOpened,
                    })}
                    onClick={handleMenuClick}
                >
                    <Button className='!w-8 !h-8 !px-0 m-0 flex justify-center items-center'>
                        <ArrowIcon
                            className={clsx('transform transition duration-200', {
                                '-rotate-90': menuOpened,
                                'rotate-90': !menuOpened,
                            })}
                            width={24}
                            height={16}
                        />
                    </Button>
                    {menuOpened && <Text>{t('sideBar.hideMenu')}</Text>}
                </div>
            </MenuButtonWrapper>
        </SidebarWrapper>
    );
};
