import { IAuthTokens } from '../types';

export class AuthTokensMapper {
  public static toDomain(item): IAuthTokens {
    return {
      accessToken: item.accessToken || item.access_token,
      refreshToken: item.refreshToken || item.refresh_token,
    };
  }
}
