import { useCallback, useState } from 'react';

export const useAccessCode = () => {
  const [googleResponse, setGoogleResponse] = useState<any>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [accessCode, setAccessCode] = useState<string>('');

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return {
    isOpen,
    accessCode,
    googleResponse,
    setGoogleResponse,
    setAccessCode,
    onClose,
    setIsOpen,
  };
};
