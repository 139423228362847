import * as yup from 'yup';

export const changePasswordValidation = (t) => {
  return yup.object().shape({
    oldPassword: yup
      .string()
      .required(t('changePasswordModal.oldPassword.required'))
      .label(t('changePasswordModal.oldPassword.label')),
    password: yup
      .string()
      .trim()
      .required(t('changePasswordModal.password.required'))
      .min(8, t('changePasswordModal.password.min', { minValue: 8 }))
      .max(20, t('changePasswordModal.password.max', { maxValue: 20 }))
      .matches(/[~!@#$%^&*_\-+=`|\\(){}\[\]:;"'<>,.?\/]+/g, t('changePasswordModal.password.containsSpecialSymbols'))
      .matches(/[A-Z]+/g, t('changePasswordModal.password.containsUppercase'))
      .matches(/[a-z]+/g, t('changePasswordModal.password.containsLowercase'))
      .matches(/[0-9]+/g, t('changePasswordModal.password.containsNumber'))
      .label(t('changePasswordModal.password.label')),
    confirmPassword: yup
      .string()
      .trim()
      .required(t('changePasswordModal.confirmPassword.required'))
      .test('confirmation-match', t('changePasswordModal.confirmPassword.mustMatch'), function (value) {
        return this.parent.password === value;
      }),
    type: yup.string().nullable(),
    code: yup.string().when(['type', 'useBackupCode'], {
      is: (type, useBackupCode) => !!type && !useBackupCode,
      then: yup.string().required(t('two-fa:validation.code.required')).label('two-fa:validation.code.label'),
    }),
    useBackupCode: yup.boolean(),
    backupCode: yup.string().when('useBackupCode', {
      is: true,
      then: yup
        .string()
        .required(t('two-fa:validation.backupCode.required'))
        .label(t('two-fa:validation.backupCode.label')),
    }),
  });
};
