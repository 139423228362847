import { CheckboxField as BaseCheckboxField, CheckboxFieldProps as BaseCheckboxFieldProps, Field, FieldHint } from '@root/shared/ui/form';
import { useField } from 'formik';
import { forwardRef } from 'react';

export interface CheckboxFieldProps extends BaseCheckboxFieldProps {
  name: string;
  hideError?: boolean;
  wrapperClassName?: string;
  type: 'checkbox' | 'radio'
}

export const CheckboxField = forwardRef<HTMLInputElement, CheckboxFieldProps>(function TextField({ hideError, wrapperClassName, type = 'checkbox', ...props }, ref) {
  const [inputProps, meta] = useField({ name: props.name, type });
  return (
    <Field className={wrapperClassName}>
      <BaseCheckboxField {...inputProps} {...props} ref={ref} type={type}/>
      {!hideError && <FieldHint variant='danger'>{!!meta.error && meta.touched && meta.error}</FieldHint>}
    </Field>
  );
});
