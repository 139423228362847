import { authSelector } from '@root/shared-files/modules/auth/store/auth.selector';
import { useEffect, useRef } from 'react';
// @ts-ignore
// import LiveChat from 'react-livechat';
import { useSelector } from 'react-redux';

const LIVECHAT_LICENSE = import.meta.env.VITE_LIVE_CHAT_LICENSE;

export const SageLiveChat = () => {
  const user = useSelector(authSelector.user);
  const authed = useSelector(authSelector.isAuthed);
  const liveChatRef = useRef<any>(null);

  useEffect(() => {
    liveChatRef?.current?.disable_sounds?.();
    liveChatRef?.current?.minimize_chat_window?.();
  }, []);

  // if (authed && user) {
  //   return (
  //     <LiveChat
  //       visitor={{
  //         name: user?.fullName,
  //         email: user?.email,
  //       }}
  //       onChatLoaded={(ref: any) => {
  //         liveChatRef.current = ref;
  //       }}
  //       license={Number(LIVECHAT_LICENSE)}
  //       params={[
  //         { name: 'Login', value: user.email },
  //         { name: 'Email Address', value: user.email },
  //         { name: 'Account_ID', value: user.id },
  //       ]}
  //     />
  //   );
  // }

  return null;
};
