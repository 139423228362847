import { IFormStatus } from '@root/shared/form';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { ForgotPasswordDto } from '../dtos';
import { forgotPasswordService } from '../services';
import { createForgotPasswordValidation } from '../validations';

export const useForgotPassword = () => {
  const { t } = useTranslation('auth-validation');

  const initialValues = useMemo<ForgotPasswordDto>(() => {
    return {
      email: '',
      captchaToken: '',
    };
  }, []);

  const schema = useMemo(() => createForgotPasswordValidation(t), []);

  const onSubmit = useCallback(async (values: ForgotPasswordDto, helpers: FormikHelpers<ForgotPasswordDto>) => {
    helpers.setStatus(undefined);

    const result = await forgotPasswordService({ ...values, email: values.email.trim().toLowerCase() });

    if (result.status === 200) {
      helpers.setStatus({ type: 'success', message: null } as IFormStatus);
    } else {
      helpers.setStatus({ type: 'error', message: result.payload } as IFormStatus);
    }
  }, []);

  return { initialValues, onSubmit, validationSchema: schema };
};
