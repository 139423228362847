import { SvgSpriteIcon } from '@root/shared/ui/svg-sprite-icon';
import { externalRedirect } from '@root/shared/utils/helpers/external-redirect';
import { useTranslation } from 'react-i18next';
import { FC, useCallback, useState } from 'react';
import { LayoutElements } from './layout.elements';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { userSettingsSelector } from '@root/modules/user-settings/store/user-settings.selector';
import clsx from 'clsx';
import { GenieGptTinyIcon } from '@root/shared/icons/genie-gpt-tiny';

const MainLinkWrapper = styled.div<{ isOpen: boolean }>`
  width: ${({ isOpen }) => (isOpen ? '200px' : '64px')};
`;

const { HeaderLink, NestedHeaderLink } = LayoutElements.Header;

const MainLink: FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const { t } = useTranslation('common');
  const menuOpened = useSelector(userSettingsSelector.menuOpened);

  return (
    <MainLinkWrapper className='relative flex items-center' isOpen={menuOpened}>
      <HeaderLink
        icon={<GenieGptTinyIcon width={20} height={20} />}
        isOpen={isOpen}
        title={menuOpened ? t('sideBar.masterGpt') : ''}
        className={clsx('w-full flex', { '!flex-row !gap-x-4': menuOpened, 'gap-y-2': !menuOpened })}
      />
    </MainLinkWrapper>
  );
};

export const HeaderLinksDropdown = () => {
  const { t } = useTranslation('common');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const menuOpened = useSelector(userSettingsSelector.menuOpened);

  const redirectToCryptoSite = useCallback(() => {
    externalRedirect(import.meta.env.VITE_CRYPTO_SITE);
  }, []);

  const redirectToFxSite = useCallback(() => {
    externalRedirect(import.meta.env.VITE_FX_SITE);
  }, []);

  return (
    <div className='relative bg-grayscale-800' onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <MainLink isOpen={isOpen} />

      {isOpen && (
        <div className='flex flex-col gap-2 absolute top-0 left-0 bg-grayscale-800 rounded z-101'>
          <MainLink isOpen={isOpen} />
          <div onClick={redirectToCryptoSite}>
            <NestedHeaderLink isActive={false} title={menuOpened ? t('sideBar.crypto') : ''} icon={<SvgSpriteIcon id='crypto' />} isOpen={isOpen} />
          </div>
          <div onClick={redirectToFxSite}>
            <NestedHeaderLink isActive={false} title={menuOpened ? t('sideBar.fx') : ''} icon={<SvgSpriteIcon id='fx' />} isOpen={isOpen} />
          </div>
        </div>
      )}
    </div>
  );
};
