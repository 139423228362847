import { createPortal } from 'react-dom';
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';

export const AbsolutePortal: FC<PropsWithChildren> = ({ children }) => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const isSSR = typeof document === 'undefined';
  const containerElementRef = useRef(isSSR ? null : document.createElement('div'));

  useEffect(() => {
    const containerElement = containerElementRef.current;
    const rootNode = document.querySelector('#absolute-portal-area');

    if (rootNode && containerElement) {
      rootNode.appendChild(containerElement);
    }

    return () => {
      if (containerElement) {
        rootNode?.removeChild(containerElement);
      }
    };
  }, []);

  useEffect(() => {
    if (!isSSR) {
      setIsReady(true);
    }
  }, [isSSR]);

  if (containerElementRef.current && isReady) {
    return createPortal(children, containerElementRef.current);
  } else {
    return null;
  }
};
