export const useShowConsoleMessage = () => {
  if (import.meta.env.DEV) {
    return;
  }

  const header = `
    color: #029CFF; 
    font-size: 56px; 
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    margin-left: 30%;
    margin-right: 35%;
`;

  const title = `
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
`;

  const text = `
    font-size: 12px;
    margin-bottom: 10px;
`;

  const textRed = `
    font-size: 12px;
    color: red;
    margin-bottom: 10px;
`;

  console.log(
    "%cSageMaster %cHold Up! %cIf someone told you to copy/paste something here you have an 11/10 chance you're being scammed. %cPasting anything in here could give attackers access to your SageMaster account. %cUnless you understand exactly what you are doing, close this window and stay safe.",
    header,
    title,
    text,
    textRed,
    text,
  );

  return;
};
