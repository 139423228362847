import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import { handleAxiosError } from '@root/shared/utils/helpers/handle-axios-error';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { Conversation } from '../types/chat';

export type GetConversationResponse = IHttpResponse<200, Conversation> | IHttpResponse<400, string>;

const VITE_CHAT_API_URL = import.meta.env.VITE_CHAT_API_URL;

export const getConversation = async (id: string): Promise<GetConversationResponse> => {
  try {
    const response = await fetcher.get(`${VITE_CHAT_API_URL}/master-gpt/conversations/${id}`);

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    return handleAxiosError(error);
  }
};
