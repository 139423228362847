import { IFormStatus } from '@root/shared/form';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { ResetPasswordDto } from '../dtos';
import { resetPasswordService } from '../services';
import { createResetPasswordValidation } from '../validations';
import { useSearchParams } from 'react-router-dom';

export const useResetPassword = () => {
  const [searchParams] = useSearchParams();
  const tokenParam = searchParams.get('token');
  const token = typeof tokenParam === 'string' ? tokenParam : undefined;

  const { t } = useTranslation('auth-validation');

  const initialValues = useMemo<ResetPasswordDto>(() => {
    return {
      password: '',
      confirmPassword: '',
      token: token || '',
    };
  }, [token]);

  const schema = useMemo(() => createResetPasswordValidation(t), []);

  const onSubmit = useCallback(async (values: ResetPasswordDto, helpers: FormikHelpers<ResetPasswordDto>) => {
    helpers.setStatus(undefined);

    const result = await resetPasswordService(values);

    if (result.status === 200) {
      helpers.setStatus({ type: 'success', message: null } as IFormStatus);
    } else {
      helpers.setStatus({ type: 'error', message: result.payload } as IFormStatus);
    }
  }, []);

  return { initialValues, onSubmit, validationSchema: schema };
};
