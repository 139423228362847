import { createGlobalStyle } from 'styled-components';
import { Theme } from './theme';

export const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
  .ReactModal__Body--open {
    overflow: hidden;
  }

  body {
    background-color: ${({ theme }) => theme.gray[900]};
    color: ${({ theme }) => theme.text.primary};
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;

    * {
      scrollbar-color: #1f2329 #2a2e34;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: #1f2329;
      }

      &::-webkit-scrollbar-corner {
        background-color: #1f2329;
      }

      &::-webkit-scrollbar-track-piece {
        background-color: #1f2329;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #2a2e34;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #2a2e34;
      }
    }
  }

  .react-datepicker-popper {
    z-index: 1000 !important;
  }
`;
