import { useCallback, useMemo } from 'react';
import { ResetTwoFaForm, ResetTwoFaFormSteps } from '../types/two-fa';
import { useTranslation } from 'react-i18next';
import { resetTwoFaFormValidation } from '../validations/two-fa.validation';
import { FormikHelpers } from 'formik';

export const useResetTwoFaForm = () => {
  const { t } = useTranslation('two-fa');

  const initialValues = useMemo<ResetTwoFaForm>(() => {
    return {
      id: '',
      step: ResetTwoFaFormSteps.WarningStep,
      accountInfo: {
        registrationEmail: '',
        fullName: '',
        country: '',
        reason: '',
        reasonComment: '',
      },
      activityQuestions: {
        hasCryptoActivities: true,
        orders: [],
        hasForexActivities: true,
        tickets: ['', '', ''],
      },
      supportRequest: {
        step: 1,
        contactEmail: '',
        comment: '',
        identificationDocument: '',
        identificationDocumentUrl: '',
        registrationDate: null,
      },
      confirmationCode: '',
    };
  }, []);

  const schema = useMemo(() => resetTwoFaFormValidation(t), [t]);

  const onSubmit = useCallback(async (values: ResetTwoFaForm, helpers: FormikHelpers<ResetTwoFaForm>) => {}, []);

  return {
    initialValues,
    schema,
    onSubmit,
  };
};
