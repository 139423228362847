import styled from 'styled-components';

import { FC } from 'react';
import { Trans } from 'react-i18next';

import config from '@root/config.json';
import { ExclamationPointIcon } from '@root/shared/icons/exclamation-point-icon';
import { Text } from '@root/shared/ui/typography';

const Wrapper = styled.div`
  background: ${({ theme }) => theme.gradient[4]};
  width: 100%;
  z-index: 10000;
  min-height: 60px;
`;

const textWithTranslation =
  "Important: Some features may be temporarily unavailable as we resolve ongoing issues. <br></br> We're working hard to restore full functionality soon.";
const { showIssueBanner, issueBannerText } = config;

export const IssueBanner: FC = () => {
  if (!showIssueBanner) {
    return null;
  }

  const textKey = textWithTranslation === issueBannerText ? 'common:issueText' : issueBannerText;

  return (
    <Wrapper className='flex flex-col justify-center items-center'>
      <Text className='font-normal text-center mx-auto px-1'>
        <Trans
          i18nKey={textKey}
          components={{ icon: <ExclamationPointIcon className='inline-block' height={20} width={20} />, br: <br />, b: <span className='uppercase font-bold' /> }}
        />
      </Text>
    </Wrapper>
  );
};
