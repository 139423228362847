import logo from '@root/assets/images/logo.webp';
import { CloseMenuIcon } from '@root/shared/icons/close-menu-icon';
import { MenuIcon } from '@root/shared/icons/menu-icon';
import { Image } from '@root/shared/ui/image';
import { useCallback, useState } from 'react';
import { LayoutElements } from '../components';
import { MobileHeaderMenu } from '../components/mobile-header-menu';

const { Wrapper, Logo, MenuToggle } = LayoutElements.Header;

export const MobileHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  return (
    <Wrapper isOpen={isOpen}>
      <div className='flex items-center flex-shrink-0 flex-grow-0'>
        <MenuToggle onClick={toggleMenu} className='font-base px-0 sm:ml-2'>
          {isOpen ? <CloseMenuIcon width={20} height={20} /> : <MenuIcon />}
        </MenuToggle>

        <Logo>
          <Image src={logo} height={29} width={174} alt='SageMaster Logo' unoptimized={true} />
        </Logo>
      </div>

      {isOpen && <MobileHeaderMenu toggleMenu={toggleMenu} />}
    </Wrapper>
  );
};
