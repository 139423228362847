export const copyToClipboard = (text) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';
  textArea.style.width = '0';
  textArea.style.height = '0';
  textArea.style.overflow = 'hidden';
  textArea.style.opacity = '0';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    navigator.clipboard.writeText(textArea.value);
  } catch (err) {
    console.log(err);
  }

  document.body.removeChild(textArea);
};
