import { AxiosError } from 'axios';
import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';

import { ResetPasswordDto } from '../dtos';

export type ResetPasswordServiceResponse = IHttpResponse<200, string> | IHttpResponse<400, string>;

export const resetPasswordService = async (payload: ResetPasswordDto): Promise<ResetPasswordServiceResponse> => {
  try {
    const response = await fetcher.post('/auth/reset-password', { password: payload.password, token: payload.token });

    return {
      status: 200,
      payload: response.data.message,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
