import { Layout } from '@root/infra/layout/containers';
import { Subscriptions } from '@root/modules/subscriptions/containers/subscriptions';

const SubscriptionsPage = () => {
  return (
    <Layout>
      <Subscriptions />
    </Layout>
  );
};

export default SubscriptionsPage;
