import { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

// import { NewChat } from './new-chat';
// import { MaskPage } from './mask';
import { Layout } from '@root/infra/layout/containers';

import { getClientConfig } from '../config/client';
import { Path, SlotID } from '../constant';
import { useAppConfig } from '../store/config';
import '../styles/globals.scss';
import { useMobileScreen } from '../utils';
import { Chat } from './chat';
import styles from './home.module.scss';
import { SideBar } from './sidebar';
import { useLogEvent } from '@root/shared/utils/hooks/use-log-event';
import { useGetCurrentSubscription } from '@root/modules/subscriptions/hooks/use-get-current-subscription';
import { useTranslation } from 'react-i18next';

function useHtmlLang() {
  const { i18n } = useTranslation('master-chat');

  useEffect(() => {
    const lang = i18n.language;
    const htmlLang = document.documentElement.lang;

    if (lang !== htmlLang) {
      document.documentElement.lang = lang;
    }
  }, [i18n.language]);
}

function Screen() {
  const config = useAppConfig();
  const location = useLocation();
  const isHome = location.pathname === Path.Home;
  const isMobileScreen = useMobileScreen();
  const shouldTightBorder = config.tightBorder && !isMobileScreen && !getClientConfig()?.isApp;

  const { i18n } = useTranslation('master-chat');

  return (
    <div className={styles.container + ` ${shouldTightBorder ? styles['tight-container'] : styles.container} ${i18n.language === 'ar' ? styles['rtl-screen'] : ''}`}>
      <SideBar className={isHome ? styles['sidebar-show'] : ''} />
      <div className={styles['window-content']} id={SlotID.AppBody}>
        <Routes>
          <Route index element={<Chat />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
      </div>
    </div>
  );
}

export function Home() {
  const { currentSubscription } = useGetCurrentSubscription();
  const { logEvent } = useLogEvent();

  useEffect(() => {
    if (currentSubscription?.id) {
      logEvent('screen_view', {
        screenName: 'Chat page',
        userSubscriptionInfo: currentSubscription?.name,
      });
    }
  }, [currentSubscription?.id, currentSubscription?.name, logEvent]);

  useHtmlLang();

  return (
    <Layout>
      <Screen />
    </Layout>
  );
}
