import React, { FC, forwardRef, ReactNode } from 'react';
import styled, { DefaultTheme, StyledComponentProps } from 'styled-components';

export const Switch = styled.div`
  display: flex;
`;

type TabButtonExtraProps = { active?: boolean };
type TabButtonProps = StyledComponentProps<'button', DefaultTheme, TabButtonExtraProps, any>;

const SwitchButton = styled.button<TabButtonExtraProps>`
  position: relative;
  padding: ${({ theme }) => `${theme.spacing(2)}px ${theme.spacing(7)}px`};
  color: ${({ theme }) => theme.gray['100']};
  transition: color 0.2s;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  border-color: ${({ theme, active }) => (active ? 'transparent' : theme.grayscale[500])};
  border-width: 1px;
  border-style: solid;
  z-index: 0;

  &:before {
    display: block;
    content: '';
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background: ${({ theme, active }) => active && theme.gradient['6']};
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: -1;
  }

  &:hover {
    color: ${({ theme, active }) => (active ? theme.gray[100] : theme.primary[400])};
    border-color: ${({ theme }) => theme.primary[400]};
  }

  &:disabled {
    color: ${({ theme }) => theme.gray[600]};
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;

    &:before {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    &:before {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

export type TabProps = TabButtonProps & { icon?: ReactNode };

export const SwitchItem: FC<TabProps> = forwardRef<HTMLButtonElement, TabProps>(function Tab({ icon, children, type = 'button', ...props }, ref) {
  return (
    <SwitchButton ref={ref} type={type} {...props}>
      {icon && <span className='mr-2 text-lg'>{icon}</span>}
      {children}
    </SwitchButton>
  );
});
