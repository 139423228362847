import { rgba } from 'polished';
import styled from 'styled-components';

export const SubscriptionDescription = styled.div<{ success?: boolean }>`
  padding-left: 12px;
  position: relative;
  text-align: center;

  &::before {
    position: absolute;
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    left: 0;
    top: calc(50% - 2px);
    background: ${({ theme, success }) => (success ? theme.success[400] : theme.primary[400])};
  }
`;

export const SubscriptionItem = styled.div<{ selected: boolean; color: string; tint: number }>`
  position: relative;
  padding: ${({ selected }) => (selected ? '20px' : '24px')};
  padding-top: ${({ selected }) => (selected ? '36px' : '40px')};
  border-width: ${({ selected }) => (selected ? '6px' : '2px')};
  border-style: solid;
  border-color: ${({ theme, selected, color, tint }) => (selected ? theme[color][tint] : theme.gray[800])};

  &:hover {
    border-color: ${({ theme, color, tint }) => theme[color][tint]};
    box-shadow: -4px -4px 20px ${({ theme, color, tint }) => rgba(theme[color][tint], 0.4)}, 4px 4px 20px ${({ theme, color, tint }) => rgba(theme[color][tint], 0.4)};
  }
`;

export const DiscountPrice = styled.div<{ color: string }>`
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: ${({ color }) => color};
  line-height: 0.8;
`;

export const Hightlight = styled.span`
  color: #24f0b3;
`;

export const Soon = styled.div`
  background: #ffdf9a;
  border-radius: 4px;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: ${({ theme }) => theme.grayscale[700]};
  padding: 0 2px;
`;

export const ActiveBadge = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;
