import * as yup from 'yup';

export const createFeedbackValidation = (t) =>
  yup.object().shape({
    email: yup.string().email(t('feedbackValidation.invalidEmail')).required(t('feedbackValidation.emailRequired')).label(t('feedbackValidation.email')),
    comments: yup
      .string()
      .required(t('feedbackValidation.feedbackRequired'))
      .min(10, t('feedbackValidation.minLength', { minValue: 10 }))
      .label(t('feedbackValidation.feedback')),
  });
