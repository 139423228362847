import styled from 'styled-components';

import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';

import { ErrorPage } from '@root/infra/layout/containers/error';
import ProtectedAuthRoute from '@root/infra/router/auth-pages-protector';
import ProtectedRoute from '@root/infra/router/rest-pages-protector';
import { useYupLocale } from '@root/infra/yup/hooks/use-yup-locale';
import { useGhostLogin } from '@root/shared-files/modules/auth/hooks/use-ghost-login';
import { authSelector } from '@root/shared-files/modules/auth/store';
import { LoadingScreen } from '@root/shared/ui/common';

import { GhostLoginWrapper } from '../layout/containers/ghost-login-wrapper';
import { Home } from '@root/modules/ai-chat/components/home';
import { SettingsPage } from '@root/modules/user-settings/pages/settings';
import SubscriptionsPage from '@root/modules/subscriptions/pages';
import { AuthRouter } from '@root/shared-files/modules/auth/router/auth.router';
import { CheckTwoFaEnabledModal } from '@root/shared-files/modules/auth/components/two-fa/check-two-fa-enabled';
import { ResetTwoFaModal } from '@root/shared-files/modules/auth/components/two-fa/reset-two-fa-modal';


const ContentWrapper = styled.div<{ isGhostLogin: boolean }>`
  ${({ isGhostLogin }) =>
    isGhostLogin
      ? `
    background: black;
    min-height: calc(100vh - 7px);
  `: ''}
`;

export const Root = () => {
  const { isGhostLogin } = useGhostLogin();
  const user = useSelector(authSelector.user);
  const isResetModalOpen = useSelector(authSelector.resetTwoFaModalOpen);

  return (
    <GhostLoginWrapper>
      <ContentWrapper isGhostLogin={isGhostLogin}>
        <Outlet />
        {!!user && <CheckTwoFaEnabledModal />}
        {isResetModalOpen && <ResetTwoFaModal />}
      </ContentWrapper>
    </GhostLoginWrapper>
  );
};

export const Router = () => {
  const { initialized } = useSelector(authSelector.main);

  useYupLocale();

  if (!initialized) {
    return <LoadingScreen />;
  }

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      children: [
        {
          path: '/',
          element: (
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          ),
          errorElement: <ErrorPage />,
        },
        {
          path: 'settings',
          element: (
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          ),
          errorElement: <ErrorPage />,
        },
        {
          path: 'subscriptions',
          element: (
            <ProtectedRoute checkSubs={false}>
              <SubscriptionsPage />
            </ProtectedRoute>
          ),
          errorElement: <ErrorPage />,
        },
        {
          path: '/*',
          element: (
            <ProtectedAuthRoute>
              <AuthRouter />
            </ProtectedAuthRoute>
          ),
          errorElement: <ErrorPage />,
        },
        {
          path: '*',
          element: <Navigate to='/' replace />,
          errorElement: <ErrorPage />,
        },
      ],
    },
  ]);

  return (
    <RouterProvider router={router} />
  );
};
