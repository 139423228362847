import { FC, useCallback, useState } from 'react';
import { Text } from '@root/shared/ui/typography';
import { Button } from '@root/shared/ui/button';
import { StepItem } from './enable-two-fa.ui';
import { useTranslation } from 'react-i18next';
import { TextField } from '@root/shared/form';

import appleImg from '@root/assets/images/apple.webp';
import googleImg from '@root/assets/images/google.webp';
import { connectAuthenticator } from '../../services/two-fa/connect-authenticator.service';
import { notify } from '@root/shared/utils/notification';

const GOOGLE_AUTH_APP_LINK = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&pli=1';
const APPLE_AUTH_APP_LINK = 'https://apps.apple.com/us/app/google-authenticator/id388497605';

export const EnableByApp: FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [qrUrl, setQrUrl] = useState<string>('');
    const [key, setKey] = useState<string>('');
    const [step, setStep] = useState<number>(1);
    const { t } = useTranslation('two-fa');

    const getTwoFaMethod = useCallback(async () => {
        setLoading(true);
        const response = await connectAuthenticator();
        if (response.status === 200) {
            setKey(response.payload.key);
            setQrUrl(response.payload.qrCode);
            setStep(2);
        } else {
            notify({
                type: 'danger',
                title: response.payload,
            });
        }
        setLoading(false);
    }, []);

    return <div className='w-full flex flex-col gap-y-4'>
        <StepItem isActive={step === 1} checked={step > 1} count={1} title={t('connectTwoFaModal.app.downloadApp.title')}>
            <div>
                <Text size='sm' className='font-semibold mb-4'>{t('connectTwoFaModal.app.downloadApp.description')}</Text>
                <div className='flex justify-start gap-x-2 mb-4'>
                    <a href={APPLE_AUTH_APP_LINK} target='_blank' rel='noreferrer'>
                        <img src={appleImg} alt='Apple' />
                    </a>
                    <a href={GOOGLE_AUTH_APP_LINK} target='_blank' rel='noreferrer'>
                        <img src={googleImg} alt='Google Play' />
                    </a>
                </div>
                <Button onClick={getTwoFaMethod} loading={loading}>
                    {t('connectTwoFaModal.next')}
                </Button>
            </div>
        </StepItem>
        <StepItem isActive={step === 2} checked={step > 2} count={2} title={t('connectTwoFaModal.app.qrCode.title')}>
            <div>
                <Text size='sm' className='font-semibold mb-4'>{t('connectTwoFaModal.app.qrCode.description')}</Text>
                {qrUrl && <img src={qrUrl} alt='QR Code' width='160' height='160' className='mb-4' />}
                {
                    !!key && <>
                        <Text size='sm' className='text-grayscale-300'>
                            {t('connectTwoFaModal.app.qrCode.manualText')}
                        </Text>
                        <Text className='text-grayscale-100 font-semibold mb-4'>
                            {key}
                        </Text>
                    </>
                }
                <Button onClick={() => setStep(1)} className='mr-4' outlined>
                    {t('connectTwoFaModal.prev')}
                </Button>
                <Button onClick={() => setStep(3)}>
                    {t('connectTwoFaModal.next')}
                </Button>
            </div>
        </StepItem>
        <StepItem isActive={step === 3} checked={step > 3} count={3} title={t('connectTwoFaModal.app.enterCode.title')}>
            <div>
                <Text size='sm' className='mb-4'>
                    {t('connectTwoFaModal.app.enterCode.description')}
                </Text>
                <TextField
                    name='code'
                    label={''}
                    placeholder={t('connectTwoFaModal.app.enterCode.placeholder')}
                />
                <div className='mt-4'>
                    <Button onClick={() => setStep(2)} className='mr-4' outlined>
                        {t('connectTwoFaModal.prev')}
                    </Button>
                    <Button type='submit'>
                        {t('connectTwoFaModal.activeTwoFa')}
                    </Button>
                </div>
            </div>
        </StepItem>
    </div>;
}

