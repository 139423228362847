import { useCallback, useMemo, useState } from 'react';
import { useGetTwoFaMethods } from './use-get-two-fa-methods';
import { TwoFAAuthType, TwoFAMethodData } from '../types/two-fa';
import { useSelector } from 'react-redux';
import { authSelector } from '../store';
import { connectEmail } from '../services/two-fa/connect-email.service';
import { notify } from '@root/shared/utils/notification';
import { makeMethodDefault } from '../services/two-fa/make-method-default.service';
import { useTranslation } from 'react-i18next';
import { queryClient } from '@root/infra/query';
import { sendCode } from '../services/two-fa/send-code.service';
import { enableMethod } from '../services/two-fa/enable-method.service';

export const useTwoFactorSettings = () => {
  const { t } = useTranslation('two-fa');
  const user = useSelector(authSelector.user);
  const { data: methods, isLoading } = useGetTwoFaMethods();
  const [backupCodes, setBackupCodes] = useState<string[]>([]);
  const [successOpen, setSuccessOpen] = useState<boolean>(false);
  const [disablingId, setDisablingId] = useState<string | null>(null);
  const [removingId, setRemovingId] = useState<string | null>(null);
  const [connectModalType, setConnectModalType] = useState<TwoFAAuthType | null>(null);
  const [changeDefaultLoading, setChangeDefaultLoading] = useState<boolean>(false);

  const availableMethods = useMemo(() => {
    const appMethod: Partial<TwoFAMethodData> = methods?.find((m) => m.type === TwoFAAuthType.Authenticator) || {};
    const emailMethod: Partial<TwoFAMethodData> = methods?.find((m) => m.type === TwoFAAuthType.Email) || {};

    return [
      {
        ...appMethod,
        section: 'app',
        type: TwoFAAuthType.Authenticator,
        enabled: appMethod?.isEnabled && appMethod.isActivated,
      },
      {
        ...emailMethod,
        section: 'email',
        type: TwoFAAuthType.Email,
        enabled: emailMethod?.isEnabled && emailMethod.isActivated,
      },
    ];
  }, [methods]);

  const connectMethod = useCallback(
    async (methodType: TwoFAAuthType) => {
      const method = methods?.find((m) => m.type === methodType);

      if (method?.id && method?.isActivated) {
        const response = await enableMethod(method.id);
        if (response.status === 200) {
          queryClient.invalidateQueries('two-fa-methods');
        } else {
          notify({
            type: 'danger',
            title: response.payload,
          });
        }
        return;
      }

      if (methodType === TwoFAAuthType.Email) {
        const email = methods?.find((m) => m.type === TwoFAAuthType.Email)?.email || user?.email || '';
        const response = await connectEmail(email);
        if (response.status === 200) {
          setConnectModalType(methodType);
        } else {
          notify({
            type: 'danger',
            title: response.payload,
          });
        }

        return;
      }
      setConnectModalType(methodType);
    },
    [methods, user?.email],
  );

  const disableTwoFaMethod = useCallback(
    async (disablingId: string) => {
      const method = methods?.find((m) => m.id === disablingId);
      if (method?.type === TwoFAAuthType.Email) {
        const response = await sendCode();
        if (response.status === 200) {
          setDisablingId(disablingId);
        } else {
          notify({
            type: 'danger',
            title: response.payload,
          });
        }
        return;
      }
      setDisablingId(disablingId);
    },
    [methods],
  );

  const handleRemoveClick = useCallback(
    async (removingId: string) => {
      setRemovingId(removingId);
      const method = methods?.find((m) => m.id === removingId);
      if (method?.type === TwoFAAuthType.Email) {
        const response = await sendCode();
        if (response.status !== 200) {
          notify({
            type: 'danger',
            title: response.payload,
          });
        }
      }
    },
    [methods],
  );

  const setDefaultMethod = useCallback(
    async (id: string) => {
      setChangeDefaultLoading(true);
      const response = await makeMethodDefault(id);
      if (response.status === 200) {
        notify({
          type: 'success',
          title: t('twoFactorAuth.defaultMethodChangeSuccess'),
        });
        queryClient.invalidateQueries('two-fa-methods');
      } else {
        notify({
          type: 'danger',
          title: response.payload,
        });
      }
      setChangeDefaultLoading(false);
    },
    [t],
  );

  const closeConnectModal = useCallback(() => {
    setConnectModalType(null);
  }, []);

  const closeBackupModal = useCallback(() => {
    setBackupCodes([]);
    setSuccessOpen(true);
  }, []);

  const state = {
    methods,
    isLoading,
    connectModalType,
    availableMethods,
    changeDefaultLoading,
    removingId,
    disablingId,
    backupCodes,
    successOpen,
  };

  const handlers = {
    setConnectModalType,
    connectMethod,
    closeConnectModal,
    disableTwoFaMethod,
    setDefaultMethod,
    setChangeDefaultLoading,
    setRemovingId,
    setDisablingId,
    handleRemoveClick,
    setBackupCodes,
    setSuccessOpen,
    closeBackupModal,
  };

  return [state, handlers] as [typeof state, typeof handlers];
};
