import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { verifyEmailChange } from '../services/verify-email-change.service';
import { notify } from '@root/shared/utils/notification';
import { authSlice } from '../store';

export const useVerifyEmailChange = () => {
  const [successOpen, setSuccessOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const tokenParam = searchParams.get('token');
  const token = typeof tokenParam === 'string' ? tokenParam : undefined;
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      if (token) {
        const response = await verifyEmailChange({ token });
        if (response.status === 200) {
          const user = response.payload;
          dispatch(authSlice.actions.updateUserInfo(user));
          setSuccessOpen(true);
        } else {
          notify({ type: 'danger', title: response.payload });
        }
        searchParams.delete('token');
        setSearchParams(searchParams);
      }
    })();
  }, [token, searchParams, dispatch, setSearchParams]);

  return {
    successOpen,
    setSuccessOpen,
  };
};
