import { GenieGptIcon } from '@root/shared/icons/genie-gpt-icon';
import { Title } from '@root/shared/ui/typography';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const EmptyChat: FC = () => {
    const { t } = useTranslation('master-chat');

    return <div className='w-full flex flex-col justify-center items-center mb-6'>
        <GenieGptIcon width={160} height={160} className='mb-5 hidden sm:block opacity-80' />
        <GenieGptIcon width={80} height={80} className='mb-5 block sm:hidden opacity-80' />
        <Title level={4} className='text-gray-100 !text-[26px]'>
            {t('Store.InitialMessageText')}
        </Title>
    </div>;
}

