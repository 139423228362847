import { IFormStatus } from '@root/shared/form';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { SignUpDto } from '../dtos';
import { signUpService } from '../services';
import { createSignUpValidation } from '../validations';

export const accessCode = 'WelcomeSage01';

export const useSignUp = () => {
  const { t } = useTranslation('auth-validation');

  const initialValues = useMemo<SignUpDto>(
    () => ({
      fullName: '',
      email: '',
      password: '',
      confirmPassword: '',
      countryCode: '',
      accessCode,
      captchaToken: '',
    }),
    [],
  );

  const schema = useMemo(() => createSignUpValidation(t), []);

  const onSubmit = useCallback(async (values: SignUpDto, helpers: FormikHelpers<SignUpDto>) => {
    helpers.setStatus(undefined);
    const trimmed = {
      fullName: values.fullName.trim(),
      email: values.email.trim().toLowerCase(),
      password: values.password.trim(),
      confirmPassword: values.confirmPassword.trim(),
      countryCode: values.countryCode.trim(),
      accessCode: values.accessCode.trim(),
    };

    const result = await signUpService({ ...values, ...trimmed });

    if (result.status === 200) {
      helpers.setStatus({ type: 'success', message: null } as IFormStatus);
    } else {
      helpers.setFieldValue('captcha', null);
      helpers.setStatus({ type: 'error', message: result.payload } as IFormStatus);
    }
  }, []);

  return { initialValues, onSubmit, validationSchema: schema };
};
