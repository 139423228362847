import { TwoFAAuthType, TwoFAResponse } from '../types/two-fa';

export const getDefaultMethod = (twoFaResponse: TwoFAResponse) => {
  const method = twoFaResponse.activeMethods.find((method) => method.isDefault) || twoFaResponse.activeMethods[0];
  const email = twoFaResponse.activeMethods.find((method) => method.type === TwoFAAuthType.Email)?.email;

  return {
    method,
    email,
  };
};
