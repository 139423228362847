import clsx from 'clsx';
import { FC, HTMLProps } from 'react';

export interface TextProps extends Omit<HTMLProps<HTMLParagraphElement>, 'size'> {
  size?: 'lg' | 'base' | 'sm' | 'xs' | 'xl' | '2xl';
  bold?: boolean;
  isExpanded?: boolean;
  active?: boolean;
}

export const Text: FC<TextProps> = ({ className, size = 'base', bold = false, ...props }) => {
  return (
    <p
      {...props}
      className={clsx(className, {
        'text-sm': size === 'sm',
        'text-xs': size === 'xs',
        'text-base': size === 'base',
        'text-lg': size === 'lg',
        'text-xl': size === 'xl',
        'text-2xl': size === '2xl',
        'font-bold': bold,
      })}
    />
  );
};
