import { FC, memo } from 'react';
import { Modal } from '@root/shared/ui/modal';
import { TwoFAAuthType } from '../../types/two-fa';
import { useTranslation } from 'react-i18next';
import { EnableByApp } from './enable-by-app';
import { EnableByEmail } from './enable-by-email';
import { Formik, FormikProps } from 'formik';
import { ConnectTwoFaForm, useConnectTwoFaForm } from '../../hooks/use-connect-turn-two-fa-modal';

type Props = {
    type: TwoFAAuthType;
    setBackupCodes: (codes: string[]) => void;
    closeModal: () => void;
}

const EnableTwoFaModalForm: FC<FormikProps<ConnectTwoFaForm> & Omit<Props, 'setBackupCodes'>> = memo(({ type, closeModal, handleSubmit }) => {
    return <form onSubmit={handleSubmit}>
        {type === TwoFAAuthType.Authenticator ? <EnableByApp /> : <EnableByEmail closeModal={closeModal} />}
    </form>;
}, (prevProps, nextProps) => prevProps.type === nextProps.type);

EnableTwoFaModalForm.displayName = 'EnableTwoFaModalForm';

export const EnableTwoFAModal: FC<Props> = memo(({ type, setBackupCodes, closeModal }) => {
    const { t } = useTranslation('two-fa');
    const { initialValues, schema, onSubmit } = useConnectTwoFaForm({ type, setBackupCodes, closeModal });

    return <Modal
        title={type === TwoFAAuthType.Authenticator ? t('connectTwoFaModal.app.title') : t('connectTwoFaModal.email.title')}
        footer={null}
        isOpen
        onCancel={closeModal}
        disabledOutSideClick
    >
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            enableReinitialize
            validationSchema={schema}
            component={(props) => <EnableTwoFaModalForm type={type} closeModal={closeModal} {...props} />}
        />
    </Modal>;
}, (prevProps, nextProps) => prevProps.type === nextProps.type);

