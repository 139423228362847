import styled from 'styled-components';
import { FC } from 'react';

const CSSVariables = styled.div<{ width: string; height: string; duration: string; color: string }>`
  --width: ${({ width }) => width};
  --height: ${({ height }) => height};
  --duration: ${({ duration }) => duration};
  --color: ${({ color }) => color};
`;

const StyledContainer = styled.div`
  width: var(--width);
  height: var(--height);
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;

  & > div {
    width: calc(var(--width) / 6);
    height: calc(var(--height) / 6);
    background-color: var(--color);
    border-radius: 50%;
    animation: animation-fade var(--duration) alternate ease-in-out infinite;
  }

  & > div:nth-of-type(2),
  & > div:nth-of-type(4) {
    animation-delay: calc(var(--duration) / 6);
  }

  & > div:nth-of-type(3),
  & > div:nth-of-type(5),
  & > div:nth-of-type(7) {
    animation-delay: calc(var(--duration) / 3);
  }

  & > div:nth-of-type(6),
  & > div:nth-of-type(8) {
    animation-delay: calc(var(--duration) / 2);
  }

  & > div:nth-of-type(9) {
    animation-delay: calc(var(--duration) * 2 / 3);
  }

  @keyframes animation-fade {
    to {
      opacity: 0.2;
    }
  }
`;

const LoadingIconDots: FC<{ className?: string }> = ({ className }) => {
  return (
    <CSSVariables width='1em' height='1em' duration='1.5s' color='currentColor' className={className}>
      <StyledContainer>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </StyledContainer>
    </CSSVariables>
  );
};

export default LoadingIconDots;
