import React, { FC } from 'react';
import LoadingIconDots from './loading-icon-dots';
import clsx from 'clsx';

type Props = {
  className?: string;
}

export const LoadingScreen: FC<Props> = ({ className = '' }) => {
  return (
    <div className={clsx('absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl text-primary-400', className)}>
      <LoadingIconDots />
    </div>
  );
};
