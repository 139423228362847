import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { AxiosError } from 'axios';
import { VerifyEmailDto } from '../dtos';
import { AuthTokensMapper, AuthUserMapper } from '../mappers';
import { IAuth } from '../types/auth';

export type VerifyEmailServiceResponse = IHttpResponse<200, IAuth> | IHttpResponse<400, string>;

export const verifyEmailService = async (payload: VerifyEmailDto): Promise<VerifyEmailServiceResponse> => {
  try {
    const response = await fetcher.post('/auth/verify-email', { token: payload.token });

    return {
      status: 200,
      payload: {
        tokens: AuthTokensMapper.toDomain(response.data),
        user: AuthUserMapper.toDomain(response.data.user),
        userSubscriptionInfo: AuthUserMapper.collectSubscriptionInfo(response.data),
      },
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
