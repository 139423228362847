import * as Sentry from '@sentry/browser';

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from '@root/App';
import '@root/infra/theme/styles/index.css';
import reportWebVitals from '@root/reportWebVitals';

import './i18n';

const SENTRY_DSN = import.meta.env.SENTRY_DSN || import.meta.env.VITE_SENTRY_DSN;
const SENTRY_ENVIRONMENT = import.meta.env.SENTRY_ENVIRONMENT || import.meta.env.VITE_SENTRY_ENVIRONMENT;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  environment: SENTRY_ENVIRONMENT,
  tracesSampleRate: import.meta.env.NODE_ENV === 'development' ? 0 : 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
