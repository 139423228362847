import * as yup from 'yup';

export const createResetPasswordValidation = (t) =>
  yup.object().shape({
    password: yup
      .string()
      .required(t('password.required'))
      .min(8, t('password.min', { minValue: 8 }))
      .max(20, t('password.max', { maxValue: 20 }))
      .matches(/[~!@#$%^&*_\-+=`|\\(){}\[\]:;"'<>,.?\/]+/g, t('password.containsSpecialSymbols'))
      .matches(/[A-Z]+/g, t('password.containsUppercase'))
      .matches(/[a-z]+/g, t('password.containsLowercase'))
      .matches(/[0-9]+/g, t('password.containsNumber'))
      .label(t('resetPassword.password.label')),
    confirmPassword: yup.string().test('confirmation-match', t('confirmPassword'), function (value) {
      return this.parent.password === value;
    }),
    token: yup.string().required(),
  });
