import React, { FC, SVGProps } from 'react';

export const HidePasswordIcon: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width='1em' height='1em' fill='currentColor' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' {...props}>
      <path
        d='M22.599941,18.4495354 C21.7087086,16.6843232 20.296345,15.1889293 18.5146076,14.1230303 L20.7019612,11.9356364 C21.0175167,11.6200808 21.0175167,11.1084444 20.7020016,10.7928485 C20.3864056,10.4772929 19.8748097,10.4772929 19.5591733,10.7928485 L16.9930723,13.3590303 C15.7836177,12.8584646 14.4854359,12.5573737 13.1514965,12.4707071 L13.1514965,8.80808081 C13.1514965,8.36177778 12.7897187,8 12.3434157,8 C11.8971127,8 11.5353349,8.36177778 11.5353349,8.80808081 L11.5353349,12.4706667 C10.2013955,12.5573333 8.90321369,12.8584242 7.69375915,13.3589899 L5.12765814,10.7928081 C4.81206218,10.4772121 4.30038541,10.4772121 3.98482985,10.7928081 C3.6693147,11.1083636 3.6693147,11.62 3.98487026,11.935596 L6.17222379,14.1229899 C4.39048642,15.1888889 2.97812278,16.6842828 2.08689046,18.4494949 C1.88575915,18.8479192 2.04567834,19.333899 2.44406218,19.5350707 C2.8423248,19.7362424 3.32850662,19.5763636 3.52959753,19.1779394 C5.02850662,16.2091717 8.36887026,14.0606061 12.3434561,14.0606061 C16.3210319,14.0606061 19.6596985,16.2117576 21.1572743,19.1779798 C21.3587692,19.5770505 21.8450319,19.7359596 22.2428097,19.5351111 C22.6412339,19.3339394 22.8011531,18.8479192 22.599941,18.4495354 Z'
        transform='translate(12.343441, 13.811005) scale(1, -1) translate(-12.343441, -13.811005) '
      />
    </svg>
  );
};
