import { useCallback, useMemo } from 'react';
import { notify } from '@root/shared/utils/notification';
import { queryClient } from '@root/infra/query';
import { useTranslation } from 'react-i18next';
import { twoFaCodeValidation } from '../validations/two-fa.validation';
import { deleteMethod } from '../services/two-fa/delete-method.service';
import { FormikHelpers } from 'formik';
import { EnterCodeWithBackupForm, TwoFAAuthType, TwoFAMethodData } from '../types/two-fa';
import { handleTwoFaErrors } from '../helpers/handle-two-fa-errors';

export type RemoveMethodForm = EnterCodeWithBackupForm;

export const useRemoveMethodForm = (method?: TwoFAMethodData, closeModal?: () => void) => {
  const { t } = useTranslation('two-fa');

  const schema = useMemo(() => twoFaCodeValidation(t), [t]);

  const initialValues = useMemo<RemoveMethodForm>(() => {
    return {
      code: '',
      useBackupCode: false,
      backupCode: '',
      type: method?.type || TwoFAAuthType.Authenticator,
    };
  }, [method?.type]);

  const onSubmit = useCallback(
    async (values: RemoveMethodForm, helpers: FormikHelpers<RemoveMethodForm>) => {
      helpers.setSubmitting(true);
      const data = values.useBackupCode
        ? {
            type: TwoFAAuthType.Backup,
            code: values.backupCode,
          }
        : {
            type: values.type,
            code: values.code,
          };
      const response = await deleteMethod(method?.id as string, data);
      if (response.status === 200) {
        queryClient.invalidateQueries(['two-fa-methods']);
        notify({
          type: 'success',
          title: t('removeModal.success'),
        });
        closeModal?.();
      } else {
        if (response.status === 418) {
          handleTwoFaErrors(response, values, helpers);
        } else {
          notify({
            type: 'danger',
            title: response.payload,
          });
        }
      }
      helpers.setSubmitting(false);
    },
    [closeModal, method?.id, t],
  );

  return {
    initialValues,
    schema,
    onSubmit,
  };
};
