import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Title, Text } from '@root/shared/ui/typography';
import { Toggler } from '@root/shared/ui/toggler/toggler';
import { useTwoFactorSettings } from '../hooks/use-two-factor-settings';
import { EnableTwoFAModal } from './two-fa/enable-two-fa-modal';
import { TwoFAAuthType } from '../types/two-fa';
import { CheckIcon } from '@root/shared/icons/check-icon';
import { Button } from '@root/shared/ui/button';
import { SkeletonLoader } from '@root/shared/ui/skeleton-loader/skeleton-loader';
import { RemoveTwoFaMethodModal } from './two-fa/remove-method-modal';
import { TurnOffModal } from './two-fa/turn-off-modal';
import { BackupCodesModal } from './two-fa/backup-codes-modal';
import { SetupSuccessModal } from './two-fa/setup-success-modal';
import { useGhostLogin } from '../hooks/use-ghost-login';

export const TwoFactorAuthSettings: FC = () => {
    const { t } = useTranslation('two-fa');
    const [{
        isLoading,
        changeDefaultLoading,
        availableMethods,
        connectModalType,
        removingId,
        disablingId,
        backupCodes,
        successOpen,
    }, {
        setBackupCodes,
        closeConnectModal,
        connectMethod,
        disableTwoFaMethod,
        setDefaultMethod,
        setRemovingId,
        setDisablingId,
        handleRemoveClick,
        closeBackupModal,
        setSuccessOpen,
    }] = useTwoFactorSettings();

    const { isGhostLogin } = useGhostLogin();

    return (
        <div className='w-full bg-gray-800 rounded py-4 md:py-6 px-4 md:px-10'>
            <Title level={7} className='mb-4'>
                {t('twoFactorAuth.title')}
            </Title>
            {
                isLoading ? <SkeletonLoader shapes={[
                    { circle: false, width: '100%', height: 130 },
                    { circle: false, width: '100%', height: 130 },
                ]} /> : <div className='overflow-x-auto'>
                    {availableMethods.map((method) => (
                        <div className='w-full grid grid-cols-12 gap-4 py-4 border-b border-grayscale-600 min-w-[420px]' key={method.type}>
                            <div className='col-span-8'>
                                <Title level={7} className='mb-2'>{t(`twoFactorAuth.options.${method.section}.title`)}</Title>
                                <Text size='sm' className='text-grayscale-400'>{t(`twoFactorAuth.options.${method.section}.description`)}</Text>
                                {
                                    method.id && method.isActivated && method.isDefault && <div className='w-full flex justify-start items-center gap-x-2 text-grayscale-400 mt-4 font-medium'>
                                        <CheckIcon /> <Text size='sm'>{t(`twoFactorAuth.defaultMethod`)}</Text>
                                    </div>
                                }
                                {
                                    !method.isDefault && method.id && method.isActivated && <Button
                                        loading={changeDefaultLoading}
                                        className='!px-4 !py-1 mt-4'
                                        onClick={() => setDefaultMethod(method.id as string)}
                                        disabled={isGhostLogin}
                                    >
                                        {t(`twoFactorAuth.useAsDefault`)}
                                    </Button>
                                }
                            </div>
                            <div className='col-span-2 flex justify-center items-center flex-shrink-0'>
                                <Toggler disabled={isGhostLogin} checked={method.enabled} onChange={(enabled) => {
                                    if (enabled) {
                                        connectMethod(method.type);
                                    } else {
                                        disableTwoFaMethod(method?.id as string);
                                    }
                                }} />
                            </div>
                            <div className='col-span-2 flex justify-center items-center'>
                                {method.id && method.isActivated && <Button
                                    className='!p-0 !text-danger-400 hover:!text-gray-100 font-semibold border-none'
                                    onClick={() => handleRemoveClick(method.id as string)}
                                    disabled={isGhostLogin}
                                    outlined
                                >{t('twoFactorAuth.remove')}</Button>}
                            </div>
                        </div>
                    ))}
                </div>
            }
            {removingId && <RemoveTwoFaMethodModal isOpen removingId={removingId} onCancel={() => setRemovingId(null)} />}
            {disablingId && <TurnOffModal isOpen methodId={disablingId} onCancel={() => setDisablingId(null)} />}
            {backupCodes.length > 0 && <BackupCodesModal isOpen backupCodes={backupCodes} onCancel={closeBackupModal} />}
            {successOpen && <SetupSuccessModal isOpen onCancel={() => setSuccessOpen(false)} />}
            {connectModalType === TwoFAAuthType.Authenticator && <EnableTwoFAModal
                type={TwoFAAuthType.Authenticator}
                setBackupCodes={setBackupCodes}
                closeModal={closeConnectModal} />}
            {connectModalType === TwoFAAuthType.Email && <EnableTwoFAModal
                type={TwoFAAuthType.Email}
                setBackupCodes={setBackupCodes}
                closeModal={closeConnectModal}
            />}
        </div>
    );
}

