export const setCookie = (name: string, value: string, exp: number) => {
  let expires = '';

  if (exp) {
    const date = new Date(exp);
    expires = '; expires=' + date.toUTCString();
  }

  if (import.meta.env.MODE === 'development') {
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
    return;
  }

  document.cookie = `${name}=${value};domain=${import.meta.env.VITE_SITE_DOMAIN};path=/;Secure;SameSite=Strict${expires}`;
};
