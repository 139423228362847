import clsx from 'clsx';
import { useField } from 'formik';
import { forwardRef, ReactNode } from 'react';
import { Text } from '@root/shared/ui/typography';
import { Field, FieldHint, Label, TextArea, TextAreaProps } from '@root/shared/ui/form';

export interface TextareaFieldProps extends TextAreaProps {
  label?: ReactNode;
  name: string;
  hideError?: boolean;
  wrapperClassName?: string;
}

export const TextareaField = forwardRef<HTMLTextAreaElement, TextareaFieldProps>(function TextField({ label, variant, hideError, wrapperClassName, ...props }, ref) {
  const [inputProps, meta] = useField(props.name);

  return (
    <Field className={clsx({ 'pointer-events-none': props.disabled }, wrapperClassName)}>
      {label && <Label>{label}</Label>}
      <TextArea variant={meta.touched && meta.error ? 'danger' : variant} {...inputProps} {...props} ref={ref} />
      {!hideError && <FieldHint variant='danger'>{meta.touched && meta.error}</FieldHint>}
      {props.maxLength && (
        <Text size='sm' className='text-right'>
          {inputProps.value.length} / {props.maxLength}
        </Text>
      )}
    </Field>
  );
});
