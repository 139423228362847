import { Emoji, EmojiStyle } from 'emoji-picker-react';

import { ReactComponent as BlackBotIcon } from '../icons/black-bot.svg';
import { ReactComponent as BotIcon } from '../icons/bot.svg';
import { ModelType } from '../store';

export function getEmojiUrl(unified: string, style: EmojiStyle) {
  return `https://cdn.staticfile.org/emoji-datasource-apple/14.0.0/img/${style}/64/${unified}.png`;
}

export function Avatar(props: { model?: ModelType; avatar?: string }) {
  if (props.model) {
    return <div className='no-dark'>{props.model?.startsWith('gpt-4') ? <BlackBotIcon className='user-avatar' /> : <BotIcon className='user-avatar' />}</div>;
  }

  return <div className='user-avatar'>{props.avatar && <EmojiAvatar avatar={props.avatar} />}</div>;
}

export function EmojiAvatar(props: { avatar: string; size?: number }) {
  return <Emoji unified={props.avatar} size={props.size ?? 18} getEmojiUrl={getEmojiUrl} />;
}
