import { FC, SVGProps } from 'react';

import { baseIconProps } from './base-icon-props';

export const GenieGptIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} width="140" height="140" viewBox="0 0 140 140" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M70.8058 135.358C53.7565 129.392 41.0771 118.511 41.0771 118.511H100.547C86.9051 130.089 70.8058 135.358 70.8058 135.358Z" fill="#D0FC81" />
      <path opacity="0.3" d="M88.654 126.935C92.53 124.63 96.634 121.83 100.548 118.524H42.1675C47.5255 121.108 61.5981 126.631 84.7401 126.948C86.1081 126.96 87.4127 126.96 88.654 126.935Z" fill="#A7DC49" />
      <path d="M126.887 21.9237C126.228 19.0021 123.371 17.7895 120.812 19.3452C113.644 23.7193 99.0451 33.1821 99.3016 39.0291C99.6607 47.1627 121.791 58.4691 127.891 53.0774C132.269 49.1836 128.72 30.1187 126.887 21.9237Z" fill="#E9EAEC" />
      <path d="M114.386 42.914C111.529 41.7015 109.53 40.2198 108.445 39.1125C107.869 38.5241 107.818 37.6078 108.323 36.9691C109.722 35.1962 112.933 32.0732 119.335 27.7865C120.316 27.1295 121.645 27.6937 121.853 28.8556C123.212 36.4501 123.201 40.9176 122.898 43.1556C122.784 43.9743 122.089 44.5738 121.266 44.5684C119.702 44.5239 117.243 44.1266 114.386 42.914Z" fill="#162802" />
      <path d="M18.6492 19.7165C16.0899 18.1609 13.2333 19.3734 12.5745 22.2951C10.7415 30.4901 7.74557 49.334 12.1297 53.2112C18.23 58.6029 40.831 46.7526 41.1902 38.619C41.4417 32.7603 25.8175 24.0907 18.6492 19.7165Z" fill="#E9EAEC" />
      <path d="M25.0768 43.285C22.2202 44.4975 19.7655 44.9065 18.2153 44.9178C17.392 44.9232 16.6972 44.3237 16.5886 43.5167C16.2853 41.2786 16.2692 36.7995 17.6327 29.2166C17.8413 28.0547 19.1705 27.4905 20.1512 28.1475C26.558 32.4459 29.7639 35.5573 31.1632 37.3301C31.6732 37.9805 31.6218 38.8968 31.046 39.4852C29.9161 40.584 27.9334 42.0724 25.0768 43.285Z" fill="#162802" />
      <path d="M102.143 119.056C83.0036 121.095 62.509 118.815 43.2557 118.575C31.2351 118.423 18.6571 113.356 11.9691 102.944C5.07844 92.2154 5.76244 69.0354 9.47376 57.1541C14.1478 42.2835 31.3617 36.1401 45.6117 36.0135C59.2157 35.8868 72.8196 35.3295 86.4363 35.6208C98.1149 35.8741 110.478 35.3928 120.332 42.6635C129.338 49.3134 132.353 59.6748 132.936 70.6061C133.772 86.3887 132.581 106.453 117.14 114.825C112.694 117.232 107.564 118.473 102.143 119.056Z" fill="url(#paint0_linear_24994_95707)" />
      <path d="M102.143 119.056C83.0036 121.095 62.509 118.815 43.2557 118.575C31.2351 118.423 18.6571 113.356 11.9691 102.944C5.07844 92.2154 5.76244 69.0354 9.47376 57.1541C14.1478 42.2835 31.3617 36.1401 45.6117 36.0135C59.2157 35.8868 72.8196 35.3295 86.4363 35.6208C98.1149 35.8741 110.478 35.3928 120.332 42.6635C129.338 49.3134 132.353 59.6748 132.936 70.6061C133.772 86.3887 132.581 106.453 117.14 114.825C112.694 117.232 107.564 118.473 102.143 119.056Z" fill="white" />
      <path d="M68.7005 34.761C68.7005 34.761 62.8892 18.4681 64.7903 17.1665C66.6913 15.8648 72.98 15.1736 72.98 15.1736C72.98 15.1736 75.5478 4.11419 76.62 4.66177C77.7011 5.20936 84.0709 21.9511 83.3231 23.0104C82.5753 24.0607 72.7638 25.3354 72.7638 25.3354C72.7638 25.3354 69.7636 35.5061 68.7005 34.761Z" fill="url(#paint1_linear_24994_95707)" />
      <path d="M19.8731 85.5783C18.9231 80.2836 19.2271 74.495 19.9238 69.745C21.7605 57.205 31.0324 50.7324 43.2051 50.213C62.3444 49.4024 81.6863 48.6424 100.648 52.0624C117.913 55.1784 119.623 70.087 116.494 85.1096C115.953 87.7215 115.082 90.2433 113.861 92.5065C112.619 94.8089 118.022 106.426 118.022 106.426C118.022 106.426 105.001 101.012 102.472 101.576C102.015 101.678 101.546 101.763 101.066 101.83C81.0277 104.591 59.2537 102.805 39.1011 101.576C28.5878 100.943 21.7985 96.3449 19.8731 85.5783Z" fill="url(#paint2_linear_24994_95707)" />
      <path d="M45.1956 77.7249C47.749 77.7249 49.8189 74.7589 49.8189 71.1002C49.8189 67.4415 47.749 64.4756 45.1956 64.4756C42.6422 64.4756 40.5723 67.4415 40.5723 71.1002C40.5723 74.7589 42.6422 77.7249 45.1956 77.7249Z" fill="#D0FC81" />
      <path d="M95.8103 77.7249C98.3637 77.7249 100.434 74.7589 100.434 71.1002C100.434 67.4415 98.3637 64.4756 95.8103 64.4756C93.2569 64.4756 91.187 67.4415 91.187 71.1002C91.187 74.7589 93.2569 77.7249 95.8103 77.7249Z" fill="#D0FC81" />
      <path d="M74.428 87.757C73.098 87.757 71.9833 87.0983 70.9827 86.5157C70.1467 86.0217 69.3613 85.553 68.6014 85.5783C67.93 85.591 67.2207 86.009 66.4733 86.4397C65.4727 87.0223 64.3327 87.6937 63.0027 87.491C61.85 87.3263 58.3667 86.807 58.3667 82.7537C58.3667 82.3357 58.7087 81.9937 59.1267 81.9937C59.5447 81.9937 59.8867 82.3357 59.8867 82.7537C59.8867 85.287 61.6094 85.743 63.2307 85.9837C64.0414 86.0977 64.852 85.629 65.7134 85.1223C66.6 84.603 67.4994 84.071 68.576 84.0457C69.7793 84.033 70.78 84.6157 71.768 85.1983C72.718 85.7557 73.6173 86.2877 74.5927 86.2243C75.6693 86.161 78.2153 85.6797 78.4813 82.6903C78.5193 82.2723 78.8867 81.9683 79.3047 81.9937C79.7227 82.0317 80.0393 82.399 80.0013 82.817C79.71 86.2243 77.0753 87.5923 74.694 87.7443C74.5927 87.7443 74.5167 87.757 74.428 87.757Z" fill="#D0FC81" />
      <path opacity="0.3" d="M39.9126 54.2417C40.6726 56.091 36.024 57.877 34.9853 58.3203C33.9466 58.7636 29.9947 61.1703 28.994 59.473C27.9807 57.763 32.3506 55.5716 33.478 55.0776C34.6053 54.5836 39.1526 52.3923 39.9126 54.2417Z" fill="white" />
      <path opacity="0.3" d="M48.0934 52.9621C48.2961 53.9881 46.2568 54.2541 45.5854 54.3175C44.9141 54.3808 42.9001 54.6341 42.6214 53.6335C42.3428 52.6201 44.4961 52.3288 45.1168 52.2655C45.7501 52.2022 47.9034 51.9362 48.0934 52.9621Z" fill="white" />
      <path opacity="0.3" d="M27.8781 60.4989C28.4987 61.3349 27.2067 62.7029 26.7634 63.1969C26.3201 63.7036 24.8508 65.0083 24.1794 64.2483C23.5081 63.4883 24.9774 62.0189 25.3954 61.5629C25.8134 61.1069 27.2574 59.663 27.8781 60.4989Z" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_24994_95707" x1="132.076" y1="88.0095" x2="-3.25712" y2="76.5698" gradientUnits="userSpaceOnUse">
          <stop stopColor="#CAE3DE" />
          <stop offset="0.2901" stopColor="#DEEDEA" />
          <stop offset="0.6878" stopColor="white" />
          <stop offset="0.7666" stopColor="#F9FCFB" />
          <stop offset="0.8765" stopColor="#E6F4F2" />
          <stop offset="1" stopColor="#CAE8E2" />
        </linearGradient>
        <linearGradient id="paint1_linear_24994_95707" x1="64.4082" y1="19.7346" x2="81.8501" y2="19.7346" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AAF7C1" />
          <stop offset="1" stopColor="#D0FC81" />
        </linearGradient>
        <linearGradient id="paint2_linear_24994_95707" x1="77.4123" y1="110.199" x2="55.8673" y2="28.9639" gradientUnits="userSpaceOnUse">
          <stop stopColor="#162802" />
          <stop offset="1" stopColor="#484946" />
        </linearGradient>
      </defs>
    </svg>
  );
};
