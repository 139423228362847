import { FC, Fragment, memo, useMemo } from 'react';
import { Modal, ModalProps } from '@root/shared/ui/modal';
import { useTranslation } from 'react-i18next';
import isEqual from 'lodash/isEqual';
import { Button } from '@root/shared/ui/button/button';
import { Text } from '@root/shared/ui/typography';
import { TwoFAAuthType, TwoFAMethodData } from '../../types/two-fa';
import { TurnOffMethodForm, useTurnOffMethodForm } from '../../hooks/use-turn-off-modal';
import { useGetTwoFaMethods } from '../../hooks/use-get-two-fa-methods';
import { Formik, FormikProps } from 'formik';
import { EnterCodeContent } from './enter-code-content';
import { BackupCodeContent } from './backup-code-content';

type Props = ModalProps & {
    methodId: string;
}

type TurnOffFormComponentFormProps = FormikProps<TurnOffMethodForm> & {
    method?: TwoFAMethodData;
    onCancel: () => void;
};

const TurnOffFormComponent: FC<TurnOffFormComponentFormProps> = memo(({ method, values, isSubmitting, handleSubmit, onCancel }) => {
    const { t } = useTranslation('two-fa');

    return <Modal isOpen title={values.useBackupCode ? t('enterBackupCode.title') : t('turnOffModal.title')} footer={null} onCancel={onCancel} disabledOutSideClick>
        <form onSubmit={handleSubmit}>
            {
                values.useBackupCode ? <BackupCodeContent type={method?.type as TwoFAAuthType} />
                    : <Fragment>
                        <Text size='base' className='mb-6'>
                            {t('turnOffModal.keepAdvise')}
                        </Text>
                        <EnterCodeContent
                            type={method?.type as TwoFAAuthType}
                            email={method?.email || ''}
                            formName='TurnOffForm'
                        />
                        <div className="w-full flex justify-between items-center gap-x-4 mt-6">
                            <Button onClick={onCancel} outlined>{t('turnOffModal.cancel')}</Button>
                            <Button loading={isSubmitting} type='submit'>{t('turnOffModal.turnOff')}</Button>
                        </div>
                    </Fragment>
            }
        </form></Modal>
}, (prevProps, nextProps) => isEqual(prevProps, nextProps));

TurnOffFormComponent.displayName = 'TurnOffFormComponent';

export const TurnOffModal: FC<Props> = ({ onCancel = () => { }, methodId }) => {
    const { data: methods } = useGetTwoFaMethods();
    const method = useMemo(() => methods?.find((m) => m.id === methodId), [methods, methodId]);
    const { initialValues, schema, onSubmit } = useTurnOffMethodForm(method, onCancel);

    return <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={onSubmit}
        component={(props) => <TurnOffFormComponent {...props} method={method} onCancel={onCancel} />}
        enableReinitialize
    />
}





