import financialWorldImage from '@root/assets/images/finance-world.webp';
import { useTranslation } from 'react-i18next';
import { Image } from '@root/shared/ui/image';
import { Title, Text } from '@root/shared/ui/typography';
import { FC, PropsWithChildren } from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';

export const InquiryFormWrapper = styled.div`
  padding: 6px 16px;
  border-radius: 6px;
  background: rgba(32, 32, 32, 0.65);
  box-shadow: 0px 10px 24px 0px rgba(12, 12, 12, 0.60);
  margin-top: 20px;
`;

export const INQUIRY_FORM_LINK = 'https://form.typeform.com/to/pE2iIcnY';

export const AuthLayout: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('common');

  return (
    <div>
      <Image
        wrapperClassName='-z-10 fixed top-0 left-0 right-0 bottom-0'
        src={financialWorldImage}
        alt='Authentication Background'
        layout='responsive'
        objectFit='cover'
        height='100vh'
        width='100vw'
        objectPosition='center'
      />
      <div className='lg:w-1/2 bg-gray-800 bg-opacity-95 p-4 lg:py-12 lg:px-16 xl:px-36 min-h-screen flex flex-col justify-between lg:justify-center items-center'>
        <InquiryFormWrapper className='lg:hidden'>
          <Text size='sm' className='text-primary-400 font-semibold'>
            <a href={INQUIRY_FORM_LINK} target='_blank' rel="noreferrer">{t('inquiryForm')}</a>
          </Text>
        </InquiryFormWrapper>
        <div className='w-full'>
          {children}
        </div>
        <div />
      </div>
      <div className='hidden fixed top-0 left-1/2 right-0 bottom-0 lg:flex lg:flex-col lg:justify-between lg:items-center'>
        <div className='flex flex-col justify-center items-center'>
          <InquiryFormWrapper className='hidden lg:block'>
            <Text size='sm' className='text-primary-400 font-semibold'>
              <a href={INQUIRY_FORM_LINK} target='_blank' rel="noreferrer">{t('inquiryForm')}</a>
            </Text>
          </InquiryFormWrapper>
          <p className='p-4'>
            <Trans i18nKey='auth:cantGetAccess' components={[<a key='0' href={`${import.meta.env.VITE_MAIN_DOMAIN}/contact-us`} target='_blank' rel='noreferrer' className='text-primary-400 font-bold hover:underline' />]} />
          </p>
        </div>
        {/*<Image height={88} width={417} src={logoImage} alt='3 Legions Logo' />*/}

        <Title bold>SageMaster</Title>

        <p className='p-4'>{t('copyright', { year: new Date().getFullYear() })}</p>
      </div>
    </div>
  );
};
