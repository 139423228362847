import { AxiosError } from 'axios';
import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { TwoFAAuthType } from '../types/two-fa';
import { AuthModuleConfig } from '../module.config';
import { TwoFaErrorResponse } from '../types/two-fa-field-error';

export type RequestEmailChangeServiceResponse =
  | IHttpResponse<200, string>
  | IHttpResponse<400, string>
  | IHttpResponse<418, TwoFaErrorResponse>;

type RequestEmailChangeDto = {
  email: string;
  twoFactorCode: {
    type: TwoFAAuthType;
    code: string;
  };
};

export const requestEmailChange = async (data: RequestEmailChangeDto): Promise<RequestEmailChangeServiceResponse> => {
  try {
    const response = await fetcher.post('/auth/profile/user-email-change-requests', {
      ...data,
      sourceApp: AuthModuleConfig.sourceApp,
      redirectPath: '/settings',
    });

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      if (typeof error.response.data.message === 'object') {
        return {
          status: 418,
          payload: error.response.data.message,
        };
      }

      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
