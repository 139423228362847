import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';
import clsx from 'clsx';
import { ColorVariant } from '@root/infra/theme';

export interface AlertProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  variant: ColorVariant;
}

export const Alert: FC<AlertProps> = ({ variant, className, ...props }) => {
  return <div className={clsx(`p-4 rounded-xl bg-${variant}-500 bg-opacity-50 text-white`, className)} {...props} />;
};
