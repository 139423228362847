import { fetcher } from '@root/infra/fetcher';
import { IHttpResponse } from '@root/shared/utils/http-service';
import { AxiosError } from 'axios';

export type ReactivateSubscriptionResponse = IHttpResponse<200, any> | IHttpResponse<400, string>;

export const reactivateSubscription = async (subscriptionId: string): Promise<ReactivateSubscriptionResponse> => {
  try {
    const response = await fetcher.post(`/misc/subscriptions/${subscriptionId}/reactivate`);

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error?.response?.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
