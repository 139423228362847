import { FC } from 'react';

export const ErrorSquareIcon: FC = () => {
  return (
    <svg data-system='sagemaster' viewBox='0 0 40 40' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <defs>
        <linearGradient x1='100.229675%' y1='49.9999853%' x2='-0.229589451%' y2='49.9999853%' id='linearGradient-178787878'>
          <stop stopColor='#FF8C32' offset='0%' />
          <stop stopColor='#FF5466' offset='100%' />
        </linearGradient>
      </defs>
      <g id='15-11' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='GUI---5.-Cards_Logo_Icons' transform='translate(-960.000000, -941.000000)'>
          <g id='error' transform='translate(936.000000, 916.000000)'>
            <g transform='translate(24.000000, 25.000000)'>
              <path
                d='M28.8888889,40 L11.1111111,40 C4.97461389,40 0,35.0253861 0,28.8888889 L0,11.1111111 C0,4.97461389 4.97461389,0 11.1111111,0 L28.8888889,0 C35.0253861,0 40,4.97461389 40,11.1111111 L40,28.8888889 C40,35.0253861 35.0253861,40 28.8888889,40 Z'
                id='Path'
                fill='#FFFFFF'
              />
              <path
                d='M17.1612782,12.6710548 L17.4627417,12.9372583 L20,15.475 L22.5372583,12.9372583 C23.786936,11.6875806 25.813064,11.6875806 27.0627417,12.9372583 C28.2162904,14.090807 28.3050249,15.9059464 27.3289452,17.1612782 L27.0627417,17.4627417 L24.525,20 L27.0627417,22.5372583 C28.3124194,23.786936 28.3124194,25.813064 27.0627417,27.0627417 C25.909193,28.2162904 24.0940536,28.3050249 22.8387218,27.3289452 L22.5372583,27.0627417 L20,24.525 L17.4627417,27.0627417 C16.213064,28.3124194 14.186936,28.3124194 12.9372583,27.0627417 C11.7837096,25.909193 11.6949751,24.0940536 12.6710548,22.8387218 L12.9372583,22.5372583 L15.475,20 L12.9372583,17.4627417 C11.6875806,16.213064 11.6875806,14.186936 12.9372583,12.9372583 C14.090807,11.7837096 15.9059464,11.6949751 17.1612782,12.6710548 Z'
                id='Combined-Shape'
                fill='url(#linearGradient-178787878)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
