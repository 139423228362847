import { nanoid } from 'nanoid';
import { ChatMessage, DEFAULT_TOPIC } from './chat';
import { ModelConfig, useAppConfig } from './config';

export type Mask = {
  id: string;
  createdAt: number;
  avatar: string;
  name: string;
  hideContext?: boolean;
  context: ChatMessage[];
  syncGlobalConfig?: boolean;
  modelConfig: ModelConfig;
  lang: string;
  builtin: boolean;
};

export const DEFAULT_MASK_STATE = {
  masks: {} as Record<string, Mask>,
};

export type MaskState = typeof DEFAULT_MASK_STATE;

export const DEFAULT_MASK_AVATAR = '1f916';
export const DEFAULT_USER_AVATAR = '1f60e';
export const createEmptyMask = (lang: string) =>
  ({
    id: nanoid(),
    avatar: DEFAULT_MASK_AVATAR,
    name: DEFAULT_TOPIC,
    context: [],
    syncGlobalConfig: true, // use global config as default
    modelConfig: { ...useAppConfig.getState().modelConfig },
    lang,
    builtin: false,
    createdAt: Date.now(),
  }) as Mask;
