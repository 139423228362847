import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider } from 'styled-components';

import React, { useEffect, useState } from 'react';
// import 'glider-js/glider.min.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'react-multi-carousel/lib/styles.css';
import 'react-toastify/dist/ReactToastify.css';

import { useShowConsoleMessage } from '@root/infra/layout/hooks/use-show-console-message';
import { SageLiveChat } from '@root/infra/live-chat';
import { QueryProvider } from '@root/infra/query';
import { Router } from '@root/infra/router';
import { StoreProvider } from '@root/infra/store';
import { GlobalStyles, theme } from '@root/infra/theme';
import { ToastContainer } from '@root/shared/ui/toast/toast.ui';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAilFy-AyibHJGXuCOsgAasJWQkPgUssvw",
  authDomain: "mastergpt-3c2ac.firebaseapp.com",
  projectId: "mastergpt-3c2ac",
  storageBucket: "mastergpt-3c2ac.appspot.com",
  messagingSenderId: "602384074347",
  appId: "1:602384074347:web:0c996b840213c20046532e",
  measurementId: "G-35F3TT91S2"
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

function App() {
  useShowConsoleMessage();
  const [openedInIframe, setOpenedInIframe] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== undefined && window.top != window.self) {
      setOpenedInIframe(true);
    }
  }, [setOpenedInIframe]);

  if (openedInIframe) {
    return <h3 className='w-full text-center pt-5'> Someone is trying to steal your data. Please check the site address.</h3>;
  }

  const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID || '';

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <QueryProvider>
        <StoreProvider>
          <ThemeProvider theme={theme}>
            <SkeletonTheme baseColor='#464646' highlightColor='#8E8E8E'>
              <Router />
              <ToastContainer position='top-right' autoClose={5000} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
              <SageLiveChat />
              <GlobalStyles />
              <div id='absolute-portal-area' />
            </SkeletonTheme>
          </ThemeProvider>
        </StoreProvider>
      </QueryProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
