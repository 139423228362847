import { fetcher } from '@root/infra/fetcher';
import { IHttpResponse } from '@root/shared/utils/http-service';
import { AxiosError } from 'axios';
import { SubscriptionProductsMapper } from '../mappers/subscription-products.mapper';
import { SubscriptionProduct } from '../types/subscription-products';

export type GetSubscriptionProducts = IHttpResponse<200, SubscriptionProduct[]> | IHttpResponse<400, string>;

export const getSubscriptionProducts = async (): Promise<GetSubscriptionProducts> => {
  try {
    const response = await fetcher.get(`/misc/subscriptions/platform/products`);

    return {
      status: 200,
      payload: response.data.map((item) => SubscriptionProductsMapper.toDomain(item)),
    };
  } catch (e) {
    const error = e as AxiosError;

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error?.response?.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
