import { FC, SVGProps } from 'react';
import { baseIconProps } from './base-icon-props';

export const CalendarIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg {...baseIconProps} viewBox='0 0 16 17' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.666992 4.63151C0.666992 3.15875 1.8609 1.96484 3.33366 1.96484H12.667C14.1398 1.96484 15.3337 3.15875 15.3337 4.63151V13.2982C15.3337 14.7709 14.1398 15.9648 12.667 15.9648H3.33366C1.8609 15.9648 0.666992 14.7709 0.666992 13.2982V4.63151ZM3.33366 3.29818C2.59728 3.29818 2.00033 3.89513 2.00033 4.63151V13.2982C2.00033 14.0346 2.59728 14.6315 3.33366 14.6315H12.667C13.4034 14.6315 14.0003 14.0346 14.0003 13.2982V4.63151C14.0003 3.89513 13.4034 3.29818 12.667 3.29818H3.33366Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.66667 0.630859C5.03486 0.630859 5.33333 0.929336 5.33333 1.29753V2.63086C5.33333 2.99905 5.03486 3.29753 4.66667 3.29753C4.29848 3.29753 4 2.99905 4 2.63086V1.29753C4 0.929336 4.29848 0.630859 4.66667 0.630859Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.3337 0.630859C11.7018 0.630859 12.0003 0.929336 12.0003 1.29753V2.63086C12.0003 2.99905 11.7018 3.29753 11.3337 3.29753C10.9655 3.29753 10.667 2.99905 10.667 2.63086V1.29753C10.667 0.929336 10.9655 0.630859 11.3337 0.630859Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0.666992 5.96354C0.666992 5.59535 0.965469 5.29688 1.33366 5.29688H14.667C15.0352 5.29688 15.3337 5.59535 15.3337 5.96354C15.3337 6.33173 15.0352 6.63021 14.667 6.63021H1.33366C0.965469 6.63021 0.666992 6.33173 0.666992 5.96354Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.33301 9.29753C4.33301 8.92934 4.63148 8.63086 4.99967 8.63086H6.33301C6.7012 8.63086 6.99967 8.92934 6.99967 9.29753C6.99967 9.66572 6.7012 9.96419 6.33301 9.96419H4.99967C4.63148 9.96419 4.33301 9.66572 4.33301 9.29753Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 9.29753C9 8.92934 9.29848 8.63086 9.66667 8.63086H11C11.3682 8.63086 11.6667 8.92934 11.6667 9.29753C11.6667 9.66572 11.3682 9.96419 11 9.96419H9.66667C9.29848 9.96419 9 9.66572 9 9.29753Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.33301 11.9635C4.33301 11.5954 4.63148 11.2969 4.99967 11.2969H6.33301C6.7012 11.2969 6.99967 11.5954 6.99967 11.9635C6.99967 12.3317 6.7012 12.6302 6.33301 12.6302H4.99967C4.63148 12.6302 4.33301 12.3317 4.33301 11.9635Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 11.9635C9 11.5954 9.29848 11.2969 9.66667 11.2969H11C11.3682 11.2969 11.6667 11.5954 11.6667 11.9635C11.6667 12.3317 11.3682 12.6302 11 12.6302H9.66667C9.29848 12.6302 9 12.3317 9 11.9635Z'
      />
    </svg>
  );
};
