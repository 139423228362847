import { AxiosError } from 'axios';

import { fetcher } from '@root/infra/fetcher';
import type { IHttpResponse } from '@root/shared/utils/http-service';
import { TwoFAMethodData } from '../../types/two-fa';

export type MakeMethodDefaultResponse = IHttpResponse<200, TwoFAMethodData> | IHttpResponse<400, string>;

export const makeMethodDefault = async (methodId: string): Promise<MakeMethodDefaultResponse> => {
  try {
    const response = await fetcher.post(`/auth/2fa/methods/${methodId}/make-default`);

    return {
      status: 200,
      payload: response.data,
    };
  } catch (e) {
    const error = e as AxiosError;
    console.error(e);

    if (error.isAxiosError && error.response) {
      return {
        status: 400,
        payload: error.response.data.message,
      };
    }

    return {
      status: 400,
      payload: 'Bad request',
    };
  }
};
