import { authSelector } from '@root/shared-files/modules/auth/store';
import { useTranslation } from 'react-i18next';
import { notify } from '@root/shared/utils/notification';
// import * as Sentry from '@sentry/nextjs';
import { FormikHelpers } from 'formik';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SentryFeedbackDto } from '../dtos/sentry-feedback.dto';
import { leaveSentryFeedback } from '../services/leave-sentry-feedback.service';
import { userSettingsSlice } from '../store/user-settings.slice';
import { createFeedbackValidation } from '../validations/feedback.validation';
import { Logger } from '@root/shared/utils/logger';

export const USERS_FEEDBACK = 'USERS_FEEDBACK';

export const useLeaveFeedback = () => {
  const { t } = useTranslation('user-settings');
  const user = useSelector(authSelector.user);
  const dispatch = useDispatch();

  const initialValues = useMemo<SentryFeedbackDto>(
    () => ({
      event_id: '',
      name: user?.fullName || '',
      email: user?.email || '',
      comments: '',
    }),
    [user],
  );

  const onSubmit = useCallback(
    async (values: SentryFeedbackDto, helpers: FormikHelpers<SentryFeedbackDto>) => {
      helpers.setSubmitting(true);
      const eventId = Logger.captureMessage(`${USERS_FEEDBACK}: ${values.comments}`);
      const response = await leaveSentryFeedback({ ...values, event_id: eventId });
      if (response.status === 200) {
        notify({ type: 'success', title: t('notifications.feedbackSent') });
        helpers.resetForm();
        dispatch(userSettingsSlice.actions.setFeedbackModal(false));
      } else {
        notify({ type: 'danger', title: response?.payload });
      }
      helpers.setSubmitting(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const schema = useMemo(() => createFeedbackValidation(t), []);

  return {
    initialValues,
    onSubmit,
    validationSchema: schema,
  };
};
