import React, { FC, SVGProps } from 'react';

export const UncheckedIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg width='1em' height='1em' viewBox='0 0 18 15' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlnsXlink='http://www.w3.org/1999/xlink' {...props}>
      <g id='GUI' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='GUI---10.-Student-&amp;-Public-Pages---Elements' transform='translate(-189.000000, -1853.000000)' stroke='#373D47'>
          <g id='Toggle' transform='translate(189.611111, 1853.000000)'>
            <g id='Border' transform='translate(0.000000, 1.000000)'>
              <rect id='Rectangle-3' x='0.5' y='0.5' width='13' height='13' rx='4' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
