import { combineReducers } from '@reduxjs/toolkit';
import { userAgentSlice } from '@root/infra/user-agent';

import { settingsSlice } from '@root/modules/app-settings/store/settings.slice';
import { authSlice } from '@root/shared-files/modules/auth/store/auth.slice';

import { subscriptionsSlice } from '@root/modules/subscriptions/store/subscriptions.slice';
import { userSettingsSlice } from '@root/modules/user-settings/store/user-settings.slice';
import { countriesSlice } from '@root/modules/countries/store/countries.slice';
import { maintenanceSlice } from '@root/shared-files/modules/maintenance/store/maintenance.slice';

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  userAgent: userAgentSlice.reducer,
  userSettings: userSettingsSlice.reducer,
  subscriptions: subscriptionsSlice.reducer,
  settings: settingsSlice.reducer,
  countries: countriesSlice.reducer,
  maintenance: maintenanceSlice.reducer
});

export type IAppState = ReturnType<typeof rootReducer>;
