import { FC } from 'react';
import { Button } from '@root/shared/ui/button';
import { Modal, ModalProps } from '@root/shared/ui/modal';
import { Title, Text } from '@root/shared/ui/typography';
import { Image } from '@root/shared/ui/image';
import { useTranslation } from 'react-i18next';

import globalPasswordImage from '@root/assets/images/global-password.webp';

type Props = ModalProps;

export const SetupSuccessModal: FC<Props> = ({ onCancel }) => {
    const { t } = useTranslation('two-fa');

    return <Modal isOpen footer={null} onCancel={onCancel}>
        <div className='w-full flex flex-col items-center'>
            <Image quality={100} height={94} width={127} src={globalPasswordImage} wrapperClassName='mb-6' alt='Password image' />
            <Title className='text-center mb-4' level={5} bold>
                {t('successModal.title')}
            </Title>
            <Text size='sm' className='text-center mb-8'>
                {t('successModal.description')}
            </Text>
            <Button outlined onClick={onCancel}>
                {t('successModal.continue')}
            </Button>
        </div>
    </Modal>;
}



