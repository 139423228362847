import { FC } from 'react';
import Skeleton, { SkeletonProps } from 'react-loading-skeleton';

type ItemProps = SkeletonProps;
interface Props {
  shapes: Array<ItemProps>;
}

export const SkeletonLoader: FC<Props> = ({ shapes }) => {
  return (
    <>
      {shapes.map((shapeProps, i) => (
        <Skeleton {...shapeProps} key={i} />
      ))}
    </>
  );
};
