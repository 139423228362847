import clsx from 'clsx';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import { DetailedHTMLProps, FC, HTMLAttributes } from 'react';

export interface FieldErrorProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  variant?: 'default' | 'danger' | 'success';
  name: string;
  showIfTouched?: boolean;
  fillEmpty?: boolean;
  errorClassName?: string;
}

export const FieldWrapper: FC<FieldErrorProps> = ({ className, errorClassName = '', variant = 'default', name, showIfTouched = false, fillEmpty = true, children, ...props }) => {
  const { getFieldMeta } = useFormikContext();
  const { touched, error } = getFieldMeta(name);

  const { status } = useFormikContext();
  const apiError = get(status?.apiErrors, name);
  const errorText = error || apiError;

  return (
    <div className={className}>
      {children}
      {((showIfTouched && touched) || !showIfTouched) && typeof errorText === 'string' && !!errorText.length ? (
        <div
          className={clsx(
            'block text-right text-sm mt-1',
            {
              'text-success-500': variant === 'success',
              'text-danger-500': variant === 'danger',
            },
            errorClassName,
          )}
          {...props}
        >
          {errorText}
        </div>
      ) : (
        fillEmpty && <div className='mb-5' />
      )}
    </div>
  );
};
