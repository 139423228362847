import globalPasswordImage from '@root/assets/images/global-password.webp';
import { IFormStatus, PasswordField } from '@root/shared/form';
import { useTranslation } from 'react-i18next';
import { Alert } from '@root/shared/ui/alert';
import { Button } from '@root/shared/ui/button';
import Link from '@root/shared/ui/common/static-locales-link';
import { Image } from '@root/shared/ui/image';
import { ButtonLink } from '@root/shared/ui/link';
import { Title } from '@root/shared/ui/typography';
import { Formik, FormikProps } from 'formik';
import { FC } from 'react';
import { ResetPasswordDto } from '../dtos';
import { useResetPassword } from '../hooks';
import { useGhostLogin } from '../hooks/use-ghost-login';

const FormComponent: FC<FormikProps<ResetPasswordDto>> = ({ handleSubmit, status: rawStatus }) => {
  const { t } = useTranslation('reset-password');
  const status: IFormStatus | undefined = rawStatus;

  const { hideActions } = useGhostLogin();

  if (status?.type === 'success') {
    return (
      <div className='w-full'>
        <Image quality={100} height={94} width={127} src={globalPasswordImage} wrapperClassName='mb-6' alt='E-Email sent illustration' />
        <Title className='mb-8' level={5} bold>
          {t('success.title')}
        </Title>
        <Link href='/sign-in' passHref>
          <ButtonLink size='large' outlined>
            {t('success.submit')}
          </ButtonLink>
        </Link>
      </div>
    );
  }

  return (
    <div className='w-full'>
      <div className='mb-4'>
        <Title level={5} bold className='mb-2'>
          {t('title')}
        </Title>
      </div>
      <form onSubmit={handleSubmit}>
        {status?.type === 'error' && (
          <Alert className='mb-4' variant='danger'>
            {status.message}
          </Alert>
        )}
        <div className="mb-4">
          <PasswordField name='password' autoComplete='new-password' label={t('fields.password.label')} placeholder={t('fields.password.placeholder') as string} />
        </div>
        <PasswordField
          name='confirmPassword'
          autoComplete='new-password'
          label={t('fields.confirmPassword.label')}
          placeholder={t('fields.confirmPassword.placeholder') as string}
        />
        <Button type='submit' className='w-full mt-8' disabled={hideActions}>
          {t('submit')}
        </Button>
      </form>
    </div>
  );
};

export const ResetPassword = () => {
  const { initialValues, onSubmit, validationSchema } = useResetPassword();

  return <Formik<ResetPasswordDto> initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema} component={FormComponent} />;
};
