import { FC, Fragment, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { Formik, FormikProps } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { Title, Text } from '@root/shared/ui/typography';
import styled from 'styled-components';
import { Button } from '@root/shared/ui/button';
import { CheckboxField, SelectField, TextField, TextareaField } from '@root/shared/form';
import { Modal } from '@root/shared/ui/modal';
import { Image } from '@root/shared/ui/image';
import { StepItem } from './enable-two-fa.ui';
import { countriesSelector } from '@root/modules/countries/store';
import { FieldWrapper } from '@root/shared/ui/form/field/field-wrapper';
import { OrdersTable } from './reset-two-fa-modal.ui';
import { authSlice } from '../../store';
import { ResetTwoFaForm, ResetTwoFaFormSteps } from '../../types/two-fa';
import { useDispatch, useSelector } from 'react-redux';
import { ExchangeType } from '@3lgn/shared/dist/constants/ccxt';
import { ArrowIcon } from '@root/shared/icons/arrow-icon';
import { DateInput, Label } from '@root/shared/ui/form';
import { InfoTooltip } from '@root/shared/ui/info-tooltip';
import { GlittersIcon } from '@root/shared/icons/glitters-icon';
import { FileField } from './file-field';
import { useResetTwoFaForm } from '../../hooks/use-reset-two-fa-form';
import { useResetTwoFaSteps } from '../../hooks/use-reset-two-fa-steps';

import warningImg from '@root/assets/images/cases/warning.webp';
import successImg from '@root/assets/images/cases/success.webp';
import govIdImg from '@root/assets/images/gov-id.webp';


const DateFieldWrapper = styled.div`
    .react-datepicker-wrapper {
        width: 100%;
    }
`;

const SUPPORT_EMAIL = 'support@sagemaster.io';

const BulletItem = styled.li`
    padding-left: 16px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 8px;
        width: 6px;
        height: 6px;
        background-color: ${({ theme }) => theme.grayscale[500]};
        border-radius: 50%;
    }

    &::marker {
        display: none;
    }
`;

type FormProps = FormikProps<ResetTwoFaForm>

export const resetTowFaReasonOptions = ['EmailSuspended', 'PasswordForgotten', 'AuthenticatorLost', 'PhoneLostStolen', 'Other'];
export const noEmailAccess = [resetTowFaReasonOptions[0], resetTowFaReasonOptions[1]];

const exchangeNames = {
    [ExchangeType.Bibox]: "Bibox",
    [ExchangeType.Binance]: "Binance",
    [ExchangeType.BinanceUS]: "Binance US",
    [ExchangeType.Bitfinex]: "Bitfinex",
    [ExchangeType.Bitmart]: "Bitmart",
    [ExchangeType.Bitstamp]: "Bitstamp",
    [ExchangeType.Bittrex]: "Bittrex",
    [ExchangeType.CoinbasePro]: "Coinbase Pro",
    [ExchangeType.Gemini]: "Gemini",
    [ExchangeType.HitBTC]: "HitBTC",
    [ExchangeType.HuobiGlobal]: "Huobi Global",
    [ExchangeType.Kraken]: "Kraken",
    [ExchangeType.Kucoin]: "Kucoin",
    [ExchangeType.Okex]: "OKx",
    [ExchangeType.Okx]: "OKx",
    [ExchangeType.PaperTrading]: "Paper Trading",
    [ExchangeType.Poloniex]: "Poloniex",
    [ExchangeType.Gate]: "Gate.io",
    [ExchangeType.Bybit]: "Bybit",
    [ExchangeType.Coinbase]: "Coinbase",
    [ExchangeType.Bitget]: "Bitget",
}

const ResetTwoFaModalForm: FC<FormProps> = ({ values, isSubmitting, setFieldValue, handleSubmit }) => {
    const countries = useSelector(countriesSelector.main);
    const { t } = useTranslation('two-fa', { keyPrefix: 'resetTwoFaModal' });
    const dispatch = useDispatch();
    const {
        ableToResend,
        restTime,
        resendLoading,
        resendCode,
        createResetRequest,
        updateRequestReset,
        verifyResetRequest,
        handleSupportBackClick,
        onSupportNextClick,
        onGovIdFileChange,
        onGovIdFileRemove,
    } = useResetTwoFaSteps();

    const countryOptions = useMemo(
        () =>
            countries.data.map((country) => ({
                value: country.code,
                label: country.name,
            })),
        [countries],
    );

    const reasonOptions = useMemo(() => {
        return Array.from({ length: 5 }, (_, index) => ({ value: resetTowFaReasonOptions[index], label: t(`accountInfo.fields.selectReason.options.${index}`) }));
    }, [t]);

    const onCancel = useCallback(() => {
        dispatch(authSlice.actions.setResetTwoFaModalOpen(false));
    }, [dispatch]);

    const modalHeader = useMemo(() => {
        if (values.step === ResetTwoFaFormSteps.WarningStep) {
            return <div className='relative -mt-3'>
                <Image src={warningImg} height={48} width={48} alt='warning' />
            </div>;
        }

        if (values.step === ResetTwoFaFormSteps.SecurityVerification) {
            return <Title level={6} className='font-bold -mt-2'>{t('securityVerification.title')}</Title>;
        }

        if (values.step === ResetTwoFaFormSteps.SupportRequest) {
            return <div className='flex justify-start items-center gap-x-2 cursor-pointer' onClick={handleSupportBackClick}>
                <ArrowIcon className='transform -rotate-90' />
                <Text size='base'>
                    {t('supportRequest.back')}
                </Text>
            </div>
        }

        if ([ResetTwoFaFormSteps.Success, ResetTwoFaFormSteps.SupportSuccess].includes(values.step)) {
            return null;
        }

        return <Title level={6} className='font-bold -mt-2'>{t('title')}</Title>;
    }, [values.step, handleSupportBackClick]);

    return <Modal
        isOpen
        className={clsx({
            '!max-w-5xl': [ResetTwoFaFormSteps.WarningStep, ResetTwoFaFormSteps.AccountInfo, ResetTwoFaFormSteps.ActivityQuestions].includes(values.step),
            '!max-w-3xl': [ResetTwoFaFormSteps.SupportRequest].includes(values.step),
        })}
        header={modalHeader}
        footer={null}
        onCancel={onCancel}
        disabledOutSideClick
    >
        <form className='w-full -mt-4' onSubmit={handleSubmit}>
            {[ResetTwoFaFormSteps.WarningStep].includes(values.step) && <div className='w-full'>
                <Title level={6} className='font-bold' bold>{t('title')}</Title>
                <Text size='base' className='mb-2'>{t('warningStep.description')}</Text>
                <Text size='base' className='mb-2'>{t('warningStep.resetInformation.title')}</Text>
                <ul className='mb-3'>
                    <BulletItem className='mb-1'>
                        <Text size='sm' className='text-gray-100 font-semibold'>{t('warningStep.resetInformation.steps.0.title')}</Text>
                        <Text size='sm' className='text-grayscale-500'>{t('warningStep.resetInformation.steps.0.description')}</Text>
                    </BulletItem>
                    <BulletItem>
                        <Text size='sm' className='text-gray-100 font-semibold'>{t('warningStep.resetInformation.steps.1.title')}</Text>
                        <Text size='sm' className='text-grayscale-500'>{t('warningStep.resetInformation.steps.1.description')}</Text>
                    </BulletItem>
                </ul>
                <div className='bg-secondary-200 bg-opacity-10 text-secondary-200 rounded px-4 py-2.5 mb-2.5'>
                    <Text size='sm'>
                        <Trans i18nKey='two-fa:resetTwoFaModal.warningStep.warning' components={{ br: <br /> }} />
                    </Text>
                </div>
                <Text size='sm'>
                    <Trans i18nKey='two-fa:resetTwoFaModal.warningStep.contactSupportTeam' components={{ a: <a className='underline' href={`mailto:${SUPPORT_EMAIL}`} target='_blank' rel='noreferrer' /> }} />
                </Text>
                <div className='w-full flex justify-between items-center gap-x-4 mt-4'>
                    <Button onClick={onCancel} outlined>{t('cancel')}</Button>
                    <Button onClick={() => setFieldValue('step', ResetTwoFaFormSteps.AccountInfo)}>{t('continue')}</Button>
                </div>
            </div>}
            {
                [ResetTwoFaFormSteps.AccountInfo, ResetTwoFaFormSteps.ActivityQuestions].includes(values.step) && <div className='w-full flex flex-col gap-4'>
                    <StepItem
                        count={1}
                        title={t('accountInfo.title')}
                        isActive={values.step === ResetTwoFaFormSteps.AccountInfo}
                        checked={values.step !== ResetTwoFaFormSteps.AccountInfo}
                    >
                        <div className='w-full flex flex-col gap-2'>
                            <TextField
                                label={t('accountInfo.fields.email.label')}
                                name='accountInfo.registrationEmail'
                                placeholder={t('accountInfo.fields.email.placeholder')}
                            />
                            <TextField
                                label={t('accountInfo.fields.fullName.label')}
                                name='accountInfo.fullName'
                                placeholder={t('accountInfo.fields.fullName.placeholder')}
                            />
                            <SelectField
                                label={t('accountInfo.fields.country.label')}
                                name='accountInfo.country'
                                placeholder={t('accountInfo.fields.country.placeholder')}
                                options={countryOptions}
                            />
                            <FieldWrapper name='accountInfo.reason' variant='danger' showIfTouched={true} fillEmpty={false}>
                                <Text size='base' className='mb-2 font-semibold'>{t('accountInfo.fields.selectReason.label')}</Text>
                                {
                                    reasonOptions.map((option, index) => (
                                        <CheckboxField
                                            name='accountInfo.reason'
                                            className='mb-4'
                                            key={index}
                                            label={option.label}
                                            value={option.value}
                                            onChange={() => setFieldValue('accountInfo.reason', option.value)}
                                            checked={values.accountInfo.reason === option.value}
                                            type='radio'
                                            hideError
                                        />
                                    ))
                                }
                            </FieldWrapper>
                            {
                                values.accountInfo.reason === resetTowFaReasonOptions[4] && <div className='w-full'>
                                    <TextareaField
                                        rows={3}
                                        name='accountInfo.reasonComment'
                                        placeholder={t('accountInfo.fields.selectReason.otherPlaceholder')}
                                    />
                                </div>
                            }
                        </div>
                        <div className='w-full flex'>
                            <Button onClick={createResetRequest} loading={isSubmitting}>
                                {t('accountInfo.nextStep')}
                            </Button>
                        </div>
                    </StepItem>
                    <StepItem
                        count={2}
                        title={t('activityQuestions.title')}
                        isActive={values.step === ResetTwoFaFormSteps.ActivityQuestions}
                        checked={false}
                    >
                        {
                            values.activityQuestions.hasCryptoActivities && <div className='overflow-x-auto pb-4'>
                                <Text size='base' className='font-semibold mb-4'>{t('activityQuestions.crypto.title')}</Text>
                                <OrdersTable
                                    exchangeNames={exchangeNames}
                                    orders={values.activityQuestions.orders}
                                />
                            </div>
                        }
                        {
                            values.activityQuestions.hasForexActivities && <div className='overflow-x-auto pb-4'>
                                <Text size='base' className='font-semibold mb-4'>{t('activityQuestions.forex.title')}</Text>
                                <div className='grid grid-cols-2 gap-x-10 gap-y-4'>
                                    {
                                        values.activityQuestions.tickets.map((_, index) => (
                                            <TextField
                                                name={`activityQuestions.tickets[${index}]`}
                                                placeholder={t('activityQuestions.forex.ticket.placeholder')}
                                                key={index}
                                                size='small'
                                            />
                                        ))
                                    }
                                </div>
                            </div>
                        }
                        {!(values.activityQuestions.hasCryptoActivities || values.activityQuestions.hasForexActivities) && <Text size='base' className='text-danger-500 mb-4'>{t('activityQuestions.noExchangesFound')}</Text>}
                        <Text size='base' className='text-primary-400 cursor-pointer mb-4' onClick={() => setFieldValue('step', ResetTwoFaFormSteps.SupportRequest)}>{t('requestAssistance')}</Text>
                        <div className='w-full flex justify-start items-center gap-4'>
                            <Button onClick={() => setFieldValue('step', ResetTwoFaFormSteps.AccountInfo)} outlined>
                                {t('activityQuestions.previous')}
                            </Button>
                            <Button
                                type='button'
                                loading={isSubmitting}
                                disabled={!(values.activityQuestions.hasCryptoActivities || values.activityQuestions.hasForexActivities)}
                                onClick={updateRequestReset}
                            >
                                {t('activityQuestions.submitTwoFaReset')}
                            </Button>
                        </div>
                    </StepItem>
                </div>
            }
            {
                [ResetTwoFaFormSteps.SecurityVerification].includes(values.step) && <div className='w-full'>
                    {
                        noEmailAccess.includes(values.accountInfo.reason) ? <div className='bg-secondary-200 bg-opacity-10 text-secondary-200 rounded px-4 py-3 mb-4'>
                            <Text size='sm'>
                                <Trans i18nKey='two-fa:resetTwoFaModal.securityVerification.noAccessToEmail' components={{ br: <br /> }} />
                            </Text>
                        </div> : <Fragment>
                            <Text size='base'>
                                {t('securityVerification.enterCode', { email: values.accountInfo.registrationEmail })}
                            </Text>
                            <div className='my-4'>
                                <TextField
                                    name='confirmationCode'
                                    placeholder={t('securityVerification.confirmationCode.placeholder')}
                                    suffix={
                                        ableToResend ? <Button loading={resendLoading} className='!border-none !py-0 !px-0' outlined onClick={resendCode}>
                                            <Text className='text-primary-400 font-medium'>
                                                {t('securityVerification.confirmationCode.resendCode')}
                                            </Text>
                                        </Button> : <Text className='font-bold'>
                                            {restTime}
                                        </Text>
                                    }
                                    suffixClassName='!z-10'
                                />
                            </div>
                        </Fragment>
                    }
                    <Text
                        size='base'
                        className={clsx('text-primary-400 cursor-pointer mb-4 pt-2', {
                            'border-t border-gray-700': !noEmailAccess.includes(values.accountInfo.reason)
                        })}
                        onClick={() => setFieldValue('step', ResetTwoFaFormSteps.SupportRequest)}
                    >
                        {t('requestAssistance')}
                    </Text>
                    {
                        !noEmailAccess.includes(values.accountInfo.reason) && <Button
                            type='button'
                            className='w-full'
                            loading={isSubmitting}
                            onClick={verifyResetRequest}
                        >
                            {t('securityVerification.confirm')}
                        </Button>
                    }
                </div>
            }
            {
                [ResetTwoFaFormSteps.SupportRequest].includes(values.step) && <div className='w-full'>
                    <Title level={6}>{t('requestSupport.title')}</Title>
                    <div className='flex flex-col gap-4 mt-4'>

                        <div className='w-full flex flex-col gap-2'>
                            <TextField
                                label={t('requestSupport.accountInfo.contactEmail.label')}
                                name='supportRequest.contactEmail'
                                placeholder={t('requestSupport.accountInfo.contactEmail.placeholder')}
                            />
                            <DateFieldWrapper>
                                <FieldWrapper name='supportRequest.registrationDate' variant='danger' showIfTouched={true} fillEmpty={false}>
                                    <Label>{t('requestSupport.accountInfo.signUpDate.label')}</Label>
                                    <DateInput
                                        clasName='w-full'
                                        placeholder={t('requestSupport.accountInfo.signUpDate.placeholder')}
                                        value={values.supportRequest.registrationDate}
                                        onChange={(e) => setFieldValue('supportRequest.registrationDate', e)}
                                    />
                                </FieldWrapper>
                            </DateFieldWrapper>
                            <TextareaField
                                rows={3}
                                label={t('requestSupport.accountInfo.comment.label')}
                                name='supportRequest.comment'
                                placeholder={t('requestSupport.accountInfo.comment.placeholder')}
                            />
                        </div>
                        <div className='w-full flex justify-end mt-4'>
                            <Button onClick={() => onSupportNextClick(1)} loading={isSubmitting}>
                                {t('requestSupport.govIdStep.submit')}
                            </Button>
                        </div>
                        {/* <StepItem
                            count={1}
                            title={t('requestSupport.accountInfo.title')}
                            isActive={values.step === ResetTwoFaFormSteps.SupportRequest && values.supportRequest.step === 1}
                            checked={values.step === ResetTwoFaFormSteps.SupportRequest && values.supportRequest.step > 1}
                        >
                        </StepItem> */}
                        {/* <StepItem
                            count={2}
                            title={
                                <div className='flex-grow flex justify-between items-center gap-2'>
                                    <Title level={7} className='font-semibold'>
                                        {t('requestSupport.govIdStep.title')}
                                    </Title>
                                    {values.supportRequest.step === 2 && <InfoTooltip content={
                                        <ul>
                                            <li>
                                                <BulletItem>
                                                    <Text size='sm' className='text-gray-100 font-medium'>{t('requestSupport.govIdStep.tips.steps.0')}</Text>
                                                </BulletItem>
                                                <BulletItem>
                                                    <Text size='sm' className='text-gray-100 font-medium'>{t('requestSupport.govIdStep.tips.steps.1')}</Text>
                                                </BulletItem>
                                                <BulletItem>
                                                    <Text size='sm' className='text-gray-100 font-medium'>{t('requestSupport.govIdStep.tips.steps.2')}</Text>
                                                </BulletItem>
                                                <BulletItem>
                                                    <Text size='sm' className='text-gray-100 font-medium'>{t('requestSupport.govIdStep.tips.steps.3')}</Text>
                                                </BulletItem>
                                            </li>
                                        </ul>
                                    } positions={['bottom', 'right', 'top', 'left']}>
                                        <div className="flex justify-end items-center gap-2 text-primary-400 hover:text-gray-100">
                                            <GlittersIcon />
                                            <Text size='sm'>{t('requestSupport.govIdStep.tips.title')}</Text>
                                        </div>
                                    </InfoTooltip>}

                                </div>
                            }
                            isActive={values.step === ResetTwoFaFormSteps.SupportRequest && values.supportRequest.step === 2}
                            checked={false}
                        >
                            <div className='w-full flex flex-col gap-2'>
                                <Text size='sm' className='font-medium'>
                                    {t('requestSupport.govIdStep.description')} <br />
                                </Text>
                                <Text size='sm' className='font-medium'>
                                    {t('requestSupport.govIdStep.instructionTitle')}<br />
                                    {t('requestSupport.govIdStep.instructions.0')} <br />
                                    {t('requestSupport.govIdStep.instructions.1')} <br />
                                    {t('requestSupport.govIdStep.instructions.2')}
                                </Text>
                                <div className='flex justify-center'>
                                    <Image src={govIdImg} width={168} alt='gov-id' />
                                </div>
                                <FieldWrapper name='supportRequest.identificationDocumentUrl' variant='danger' showIfTouched={true} fillEmpty={false}>
                                    <FileField
                                        name='identificationDocument'
                                        value={values.supportRequest.identificationDocumentUrl}
                                        placeholder={t('requestSupport.govIdStep.dropFile')}
                                        onRemove={onGovIdFileRemove}
                                        onChange={onGovIdFileChange}
                                    />
                                </FieldWrapper>
                            </div>
                            <div className='w-full flex items-center gap-x-4 mt-4'>
                                <Button onClick={() => setFieldValue('supportRequest.step', 1)} outlined>
                                    {t('requestSupport.govIdStep.prev')}
                                </Button>
                                <Button onClick={() => onSupportNextClick(2)} loading={isSubmitting}>
                                    {t('requestSupport.govIdStep.submit')}
                                </Button>
                            </div>
                        </StepItem> */}
                    </div>
                </div>
            }
            {
                [ResetTwoFaFormSteps.Success, ResetTwoFaFormSteps.SupportSuccess].includes(values.step) && <div className='w-full'>
                    <div className='flex flex-col items-center gap-4'>
                        <div className='w-[100px] mt-8'>
                            <Image src={successImg} width={220} height={220} objectFit='contain' alt='success' unoptimized={true} />
                        </div>
                        {values.step === ResetTwoFaFormSteps.Success ? <Title level={5} className='font-bold text-center mb-8'>
                            {t('resetSuccess')}
                        </Title> : <Title level={5} className='font-bold text-center mb-4'>
                            {t('supportSuccess')}
                        </Title>}
                        {values.step === ResetTwoFaFormSteps.SupportSuccess && <Text size='base' className='text-center text-grayscale-400 mb-8'>
                            {t('supportSuccessDescription')}
                        </Text>}
                        <Button onClick={onCancel}>{t('close')}</Button>
                    </div>
                </div>
            }
        </form>
    </Modal >;
};

export const ResetTwoFaModal: FC = () => {
    const { initialValues, schema, onSubmit } = useResetTwoFaForm();

    return <Formik
        initialValues={initialValues}
        validationSchema={schema}
        component={ResetTwoFaModalForm}
        onSubmit={onSubmit}
        enableReinitialize
    />
}

