import * as yup from 'yup';
import { resetTowFaReasonOptions } from '../components/two-fa/reset-two-fa-modal';

const createCodeValidation = (t) => {
  return yup
    .string()
    .required(t('two-fa:validation.code.required'))
    .length(6, t('two-fa:validation.code.invalid'))
    .test('is-number', t('two-fa:validation.code.invalid'), (value) => /^\d+$/.test(value || ''))
    .label('two-fa:validation.code.label');
};

export const connectTwoFaValidation = (t) =>
  yup.object().shape({
    code: createCodeValidation(t),
    email: yup.string().email(t('two-fa:validation.email.invalid')).label('two-fa:validation.email.label'),
  });

export const twoFaCodeValidation = (t) => {
  return yup.object().shape({
    code: yup.string().when('useBackupCode', {
      is: false,
      then: createCodeValidation(t),
    }),
    useBackupCode: yup.boolean(),
    backupCode: yup.string().when('useBackupCode', {
      is: true,
      then: yup
        .string()
        .required(t('two-fa:validation.backupCode.required'))
        .length(16, t('two-fa:validation.backupCode.invalid'))
        .label('two-fa:validation.backupCode.label'),
    }),
  });
};

export const resetTwoFaFormValidation = (t) => {
  return yup.object().shape({
    accountInfo: yup.object().shape({
      registrationEmail: yup
        .string()
        .email(t('two-fa:validation.email.invalid'))
        .required(t('two-fa:validation.email.required'))
        .label('two-fa:validation.email.label'),
      fullName: yup
        .string()
        .required(t('two-fa:validation.fullName.required'))
        .label('two-fa:validation.fullName.label'),
      country: yup.string().required(t('two-fa:validation.country.required')).label('two-fa:validation.country.label'),
      reason: yup.string().required(t('two-fa:validation.reason.required')).label('two-fa:validation.reason.label'),
      reasonComment: yup
        .string()
        .when('reason', {
          is: resetTowFaReasonOptions[4],
          then: yup.string().required(t('two-fa:validation.reasonComment.required')),
        })
        .label('two-fa:validation.reasonComment.label'),
    }),
    activityQuestions: yup.object().shape({
      hasCryptoActivities: yup.boolean(),
      orders: yup.array().when('hasCryptoActivities', {
        is: true,
        then: yup.array().of(
          yup.object().shape({
            amount: yup
              .number()
              .typeError(t('two-fa:validation.orderAmount.typeError'))
              .required(t('two-fa:validation.orderAmount.required'))
              .label(t('two-fa.validation.orderAmount.label')),
          }),
        ),
      }),
      hasForexActivities: yup.boolean(),
      tickets: yup.array().when('hasForexActivities', {
        is: true,
        then: yup
          .array()
          .of(
            yup
              .number()
              .typeError(t('two-fa:validation.ticket.typeError'))
              .required(t('two-fa:validation.ticket.required'))
              .label(t('two-fa.validation.ticket.label')),
          ),
      }),
    }),
    supportRequest: yup.object().shape({
      contactEmail: yup
        .string()
        .email(t('two-fa:validation.email.invalid'))
        .required(t('two-fa:validation.email.required'))
        .label('two-fa:validation.email.label'),
      // identificationDocumentUrl: yup
      //   .string()
      //   .required(t('two-fa:validation.govIdImage.required'))
      //   .label('two-fa:validation.govIdImage.label'),
      registrationDate: yup
        .string()
        .nullable()
        .required(t('two-fa:validation.registrationDate.required'))
        .label('two-fa:validation.registrationDate.label'),
      comment: yup
        .string()
        .max(500, t('two-fa:validation.comment.max', { max: 500 }))
        .label('two-fa:validation.comment.label'),
    }),
    confirmationCode: yup.string().required(t('two-fa:validation.code.required')).label('two-fa:validation.code.label'),
  });
};
