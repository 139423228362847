import { useCallback, useMemo } from 'react';
import { EnterCodeWithBackupForm, TwoFAAuthType } from '../types/two-fa';
import { useGetTwoFaMethods } from './use-get-two-fa-methods';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
import { notify } from '@root/shared/utils/notification';
import { requestEmailChange } from '../services/request-email-change.service';
import { changeEmailValidation } from '../validations/change-email.validation';
import { getActiveMethod } from '../helpers/get-active-method';
import { handleTwoFaErrors } from '../helpers/handle-two-fa-errors';

export type ChangeEmailForm = EnterCodeWithBackupForm & {
  step: 1 | 2;
  email: string;
};

export const useChangeEmailForm = () => {
  const { data: methods } = useGetTwoFaMethods();
  const { t } = useTranslation('settings');

  const initialValues = useMemo<ChangeEmailForm>(() => {
    const { method } = getActiveMethod(methods || []);

    return {
      step: 1,
      email: '',
      type: method?.type as TwoFAAuthType,
      code: '',
      useBackupCode: false,
      backupCode: '',
    };
  }, [methods]);

  const schema = useMemo(() => changeEmailValidation(t), [t]);

  const onSubmit = useCallback(
    async (values: ChangeEmailForm, helpers: FormikHelpers<ChangeEmailForm>) => {
      const response = await requestEmailChange({
        email: values.email,
        twoFactorCode: values.useBackupCode
          ? {
              type: TwoFAAuthType.Backup,
              code: values.backupCode,
            }
          : {
              type: values.type,
              code: values.code,
            },
      });

      if (response.status === 200) {
        helpers.setStatus({ type: 'success', message: t('changeEmailModal.requestSuccess.title') });
      } else {
        if (response.status === 418) {
          const emailError = response.payload.find((e) => e.property === 'email');
          if (emailError) {
            helpers.setStatus({
              apiErrors: {
                email: emailError?.errors?.[0],
              },
            });
            helpers.setFieldValue('step', 1);
            return;
          }

          handleTwoFaErrors(response, values, helpers as any);
        } else {
          notify({
            type: 'danger',
            title: response.payload,
          });
        }
      }
    },
    [t],
  );

  return {
    initialValues,
    schema,
    onSubmit,
  };
};
